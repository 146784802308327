import React, { useContext } from "react";
import InstaLogo from "../../Assets/Images/insta-logo-header.svg";
import shoppingCart from "../../Assets/Images/shopping-cart.svg";
import shoppingCartWithData from "../../Assets/Images/cart.svg";
import "../../Assets/Styles/header.css";
import { AppContext } from "../../Context/AppContext";
import { useHistory } from "react-router";

export default function Header() {
  const history = useHistory();
  const { cartObj } = useContext(AppContext);
  return (
    <div className="header-container">
      <div className="col-8 logo-div" onClick={() => history.push("/")}>
        <img src={InstaLogo} alt="" style={{ cursor: 'pointer'}}></img>
      </div>
      <div
        className="col-2 cart-div"
        onClick={() => history.push("/cart/detail")}
      >
        {cartObj?.length > 0 ? (
          <>
            <img src={shoppingCartWithData} alt="" style={{ cursor: 'pointer'}}></img>
            <span className={cartObj?.length > 9 ? "double-digit-span" : "single-digit-span"}>{cartObj?.length}</span>
          </>
        ) : (
          <img src={shoppingCart} alt="" style={{ cursor: 'pointer'}}></img>
        )}
      </div>
    </div>
  );
}
