import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import CheckoutForm from './CheckoutForm';
import { STRIPE_KEY } from '../../Services';
import '../../Assets/Styles/payments.css'
import { useHistory } from 'react-router-dom'
import Loader from '../../Components/Loader';
import MainImage from "../../Assets/Images/instaCateLogo.png"
import { CONSTANTS } from '../../Helpers/Constants';
import PaymentFooter from './views/PaymentScreenFooter';

const stripePromise = loadStripe(STRIPE_KEY.toString());
export default function CollectPayment() {
    const history = useHistory();
    const order_details = localStorage.getItem('order') ? JSON.parse(localStorage.getItem('order')) : '';
    const stripeIntent = localStorage.getItem('intent') ? JSON.parse(localStorage.getItem('intent')) : null;
    const clientSecret = stripeIntent?.data?.stripeIntent?.client_secret;
    const appearnce = {
        theme: 'stripe'
    }
    if (order_details === '') history.push('/')
    const options = {
        clientSecret,
        appearnce
    }

    return (<>
        <div className='ic-wrapper'>
            <div className='payment_header'>
                <div className="main-heading">
                    <img src={MainImage} alt="Logo" className={"main-logo"} />
                    <h2 className="icon_heading">{CONSTANTS.EATINGS_FOR_MEETINGS}</h2>
                </div>
            </div>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm
                        stripeIntent={stripeIntent}
                    />
                </Elements>
            )}
            {/* <Loader /> */}
            <PaymentFooter />
        </div>
    </>)
} 