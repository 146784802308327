import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextBox from "../../../Components/TextBox";
import Checkbox from "../../../Components/Checkbox";
import { CONSTANTS } from "../../../Helpers/Constants";
import CartDetailsComponent from "../../../Components/CartDetails";

export default function BillingInfoScreen({
  billingInfo,
  setBillingInfo,
  deliveryInfo,
  setDeliveryInfo,
  cateringMode,
  submitBilling,
  setPhoneNoChange,
  setDeliveryPhoneNoChange
}) {
  return (
    <div>
      <div className="billing-main-comp">
        <div className="main-container">
          <h4>{CONSTANTS.BILLING_INFO}</h4>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${billingInfo.name}`}
                name={`${billingInfo.name}`}
                label={CONSTANTS.NAME_TXT}
                type={"text"}
                value={billingInfo.name}
                onChange={(e) =>
                  setBillingInfo({ ...billingInfo, name: e.target.value })
                }
                error={billingInfo.name_error?.length}
                errorMessage={billingInfo.name_error}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${billingInfo.email}`}
                name={`${billingInfo.email}`}
                label={CONSTANTS.EMAIL_TXT}
                type={"text"}
                value={billingInfo.email}
                onChange={(e) =>
                  setBillingInfo({ ...billingInfo, email: e.target.value })
                }
                error={billingInfo.email_error?.length}
                errorMessage={billingInfo.email_error}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${billingInfo.phoneNumber}`}
                name={`${billingInfo.phoneNumber}`}
                label={CONSTANTS.PHONE_TXT}
                type={"text"}
                value={billingInfo.phoneNumber}
                onChange={(e) =>
                  setBillingInfo({
                    ...billingInfo,
                    phoneNumber: e.target.value.replace(/[^\d+-]/g, ''),
                  })
                }
                on_blur={(e) =>
                  setPhoneNoChange(e, 'phoneNumber', 'phone_error')
                }
                error={billingInfo.phone_error?.length}
                errorMessage={billingInfo.phone_error}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${billingInfo.companyName}`}
                name={`${billingInfo.companyName}`}
                label={CONSTANTS.COMPANY_NAME_TXT}
                type={"text"}
                value={billingInfo.companyName}
                onChange={(e) =>
                  setBillingInfo({
                    ...billingInfo,
                    companyName: e.target.value,
                  })
                }
                error={billingInfo.company_error?.length}
                errorMessage={billingInfo.company_error}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${billingInfo.organizationNumber}`}
                name={`${billingInfo.organizationNumber}`}
                label={CONSTANTS.ORG_NO}
                type={"text"}
                value={billingInfo.organizationNumber}
                onChange={(e) =>
                  setBillingInfo({
                    ...billingInfo,
                    organizationNumber: e.target.value,
                  })
                }
                error={billingInfo.org_error?.length}
                errorMessage={billingInfo.org_error}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${billingInfo.streetAddress}`}
                name={`${billingInfo.streetAddress}`}
                label={CONSTANTS.STREET_TXT}
                type={"text"}
                value={billingInfo.streetAddress}
                onChange={(e) =>
                  setBillingInfo({
                    ...billingInfo,
                    streetAddress: e.target.value,
                  })
                }
                error={billingInfo.street_error?.length}
                errorMessage={billingInfo.street_error}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${billingInfo.postalCode}`}
                name={`${billingInfo.postalCode}`}
                label={CONSTANTS.POSTAL_CODE_TXT}
                type={"text"}
                value={billingInfo.postalCode}
                onChange={(e) =>
                  setBillingInfo({ ...billingInfo, postalCode: e.target.value })
                }
                error={billingInfo.postal_error?.length}
                errorMessage={billingInfo.postal_error}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${billingInfo.city}`}
                name={`${billingInfo.city}`}
                label={CONSTANTS.CITY_TXT}
                type={"text"}
                value={billingInfo.city}
                onChange={(e) =>
                  setBillingInfo({ ...billingInfo, city: e.target.value })
                }
                error={billingInfo.city_error?.length}
                errorMessage={billingInfo.city_error}
              />
            </Col>
          </Row>
        </div>
        <div className="main-container">
          <h4 className='captitalize'>{cateringMode === 'pickup' ? CONSTANTS.PICKUP_INFO_TXT : CONSTANTS.DELIVERY_INFO_TXT}</h4>
          <Col className="col-10">
            <Checkbox
              checked={deliveryInfo.useSameAsAbove}
              id={`${deliveryInfo.useSameAsAbove}`}
              name={`${deliveryInfo.useSameAsAbove}`}
              value={deliveryInfo.useSameAsAbove}
              onChange={(e) =>
                setDeliveryInfo({
                  ...deliveryInfo,
                  contactPersonName: '',
                  contactPersonEmail: '',
                  contactPersonPhone: '',
                  streetAddress: '',
                  postalCode: '',
                  useSameAsAbove: e.target.checked,
                })
              }
              label={CONSTANTS.USE_SAME_AS_ABOVE_TXT}
            />
          </Col>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${deliveryInfo.contactPersonName}`}
                name={`${deliveryInfo.contactPersonName}`}
                label={CONSTANTS.CONTACT_NAME_TXT}
                type={"text"}
                value={deliveryInfo.contactPersonName}
                disabled={deliveryInfo.useSameAsAbove}
                onChange={(e) =>
                  setDeliveryInfo({
                    ...deliveryInfo,
                    contactPersonName: e.target.value,
                  })
                }
                error={deliveryInfo.cp_name_error?.length}
                errorMessage={deliveryInfo.cp_name_error}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${deliveryInfo.contactPersonEmail}`}
                name={`${deliveryInfo.contactPersonEmail}`}
                label={CONSTANTS.CONTACT_EMAIL_TXT}
                type={"text"}
                value={deliveryInfo.contactPersonEmail}
                disabled={deliveryInfo.useSameAsAbove}
                onChange={(e) =>
                  setDeliveryInfo({
                    ...deliveryInfo,
                    contactPersonEmail: e.target.value,
                  })
                }
                error={deliveryInfo.cp_email_error?.length}
                errorMessage={deliveryInfo.cp_email_error}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${deliveryInfo.contactPersonPhone}`}
                name={`${deliveryInfo.contactPersonPhone}`}
                label={CONSTANTS.CONTACT_PHONE_TXT}
                type={"text"}
                value={deliveryInfo.contactPersonPhone}
                disabled={deliveryInfo.useSameAsAbove}
                onChange={(e) =>
                  setDeliveryInfo({
                    ...deliveryInfo,
                    contactPersonPhone: e.target.value.replace(/[^\d+-]/g, ''),
                  })
                }
                on_blur={(e) =>
                  setDeliveryPhoneNoChange(e, 'contactPersonPhone', 'cp_phone_error',)
                }
                error={deliveryInfo.cp_phone_error?.length}
                errorMessage={deliveryInfo.cp_phone_error}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${deliveryInfo.streetAddress}`}
                name={`${deliveryInfo.streetAddress}`}
                label={CONSTANTS.STREET_TXT}
                type={"text"}
                value={deliveryInfo.streetAddress}
                disabled={cateringMode === "pickup" || deliveryInfo.useSameAsAbove}
                onChange={(e) =>
                  setDeliveryInfo({
                    ...deliveryInfo,
                    streetAddress: e.target.value,
                  })
                }
                error={deliveryInfo.cp_street_error?.length}
                errorMessage={deliveryInfo.cp_street_error}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${deliveryInfo.area}`}
                name={`${deliveryInfo.area}`}
                label={CONSTANTS.AREA_TXT}
                type={"text"}
                value={deliveryInfo.area}
                disabled={true}
                onChange={(e) =>
                  setDeliveryInfo({ ...deliveryInfo, area: e.target.value })
                }
                error={deliveryInfo.cp_area_error?.length}
                errorMessage={deliveryInfo.cp_area_error}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${deliveryInfo.city}`}
                name={`${deliveryInfo.city}`}
                label={CONSTANTS.CITY_TXT}
                type={"text"}
                value={deliveryInfo.city[0] ? deliveryInfo.city[0].charAt(0).toUpperCase() + deliveryInfo.city[0]?.slice(1) : ''}
                disabled={true}
                onChange={(e) =>
                  setDeliveryInfo({ ...deliveryInfo, city: e.target.value })
                }
                error={deliveryInfo.cp_city_error?.length}
                errorMessage={deliveryInfo.cp_city_error}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${deliveryInfo.postalCode}`}
                name={`${deliveryInfo.postalCode}`}
                label={CONSTANTS.POSTAL_CODE_TXT}
                type={"text"}
                value={deliveryInfo.postalCode}
                disabled={cateringMode === "pickup" || deliveryInfo.useSameAsAbove}
                onChange={(e) =>
                  setDeliveryInfo({
                    ...deliveryInfo,
                    postalCode: e.target.value,
                  })
                }
                error={deliveryInfo.cp_postal_error?.length}
                errorMessage={deliveryInfo.cp_postal_error}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${deliveryInfo.doorCode}`}
                name={`${deliveryInfo.doorCode}`}
                label={CONSTANTS.DOOR_CODE_TXT}
                disabled={cateringMode === "pickup"}
                type={"text"}
                value={deliveryInfo.doorCode}
                onChange={(e) =>
                  setDeliveryInfo({ ...deliveryInfo, doorCode: e.target.value })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <TextBox
                id={`${deliveryInfo.numberOfGuests}`}
                name={`${deliveryInfo.numberOfGuests}`}
                label={CONSTANTS.NO_OF_GUEST}
                type={"number"}
                value={deliveryInfo.numberOfGuests}
                onChange={(e) =>
                  setDeliveryInfo({
                    ...deliveryInfo,
                    numberOfGuests: e.target.value,
                  })
                }
                error={deliveryInfo.cp_guests_error?.length}
                errorMessage={deliveryInfo.cp_guests_error}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="billing-info-input">
                <label>{CONSTANTS.ADDITION_INFO_TXT}</label>
                <textarea
                  id={`${deliveryInfo.additionalInformation}`}
                  name={`${deliveryInfo.additionalInformation}`}
                  className="additional-info billing-input-text-area"
                  type={"text"}
                  value={deliveryInfo.additionalInformation}
                  onChange={(e) =>
                    setDeliveryInfo({
                      ...deliveryInfo,
                      additionalInformation: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Checkbox
                checked={deliveryInfo.TermsAndConditions}
                id={`${deliveryInfo.TermsAndConditions}`}
                name={`${deliveryInfo.TermsAndConditions}`}
                value={deliveryInfo.TermsAndConditions}
                onChange={(e) =>
                  setDeliveryInfo({
                    ...deliveryInfo,
                    TermsAndConditions: e.target.checked,
                  })
                }
                label={CONSTANTS.TERM_AND_CONDITIONS_TXT}
                required
              />
            </Col>
            {deliveryInfo.terms_error?.length ? (
              <div className="error-message">{deliveryInfo.terms_error}</div>
            ) : (
              ""
            )}
          </Row>

          <Row>
            <Col className="ic-mt ic-mb-2 offset-md-4" xs={12} sm={12} md={4} lg={4}>
              <div>
                <button
                  onClick={() => submitBilling()}
                  className="filter-button"
                >
                  {CONSTANTS.PROCEED_PAYMENT_TXT}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <CartDetailsComponent mode="readonly" />
    </div>
  );
}
