import React, { useContext, useState, useEffect } from "react";
import { useHistory } from 'react-router';
import MainLayout from "./Views/MainLayout";
import RestaurantSlider from "./Views/RestaurantSlider";
import Footer from '../../Components/Footer';
import FilterDialog from "../../Components/FilterDialog";
import { VendersAPI } from "../../Services/ExploreVendors";
import { selectOptions } from '../../Helpers/Handlers';
import '../../Assets/Styles/landing_page.css';
import { AppContext } from "../../Context/AppContext";
import json from "../../Assets/JSON/landing-page.json";
import { useLocation } from "react-router";

export default function LandingPage() {
    const { carousalForm, 
            setCarousalForm, 
            date, 
            setDate, 
            guestsCount, 
            setGuestsCount, 
            location, 
            setLocation, 
            cartObj, 
            setOpenFilterDialog, 
            cateringMode, 
            preferences, 
            setPreferences,
            openFilterDialog } = useContext(AppContext);
    const history = useHistory();
    const [serviceMode, setServiceMode] = useState( cateringMode );
    const [vendorIndex, setVendorIndex] = useState(0);
    const [tags, setTags] = useState([]);
    const { pathname } = useLocation();

    useEffect(() => {
        fetchSystemConfig();
      }, []);

    const fetchSystemConfig = async () => {
        const _system_config = await VendersAPI.getSystemConfig();
        const optionsTags = selectOptions(_system_config?.data?.tags);
        setTags(optionsTags);
        // setPreferences(optionsTags)
      };
    
    const changeHandler = (field, value) => {
        setCarousalForm({
            ...carousalForm,
            [field]: value
        })
        localStorage.setItem(field, value)
    }
    const openModalHandler = (index) => {
        setOpenFilterDialog(true);
        setVendorIndex(index)
    }

    const fetchVendors = () => {
        localStorage.setItem('vendor', JSON.stringify(json.vendor));
        history.push('/vendor/packages');
    }

    return (
        <>
            <div className="ic-wrapper-lp">
            {openFilterDialog && (
                <FilterDialog
                    locations={json.locations}
                    participants={json.participant}
                    changeHandler={changeHandler}
                    fetchVendors={fetchVendors}
                    serviceMode={serviceMode}
                    setServiceMode={setServiceMode}
                    prefrences={preferences}
                    setPreferences={setPreferences}
                    tags={tags}
                    pathname={pathname}
                    vendorIndex={vendorIndex}
                />
                )}
                <MainLayout carousalForm={carousalForm} changeHandler={changeHandler}
                    date={date} setDate={setDate} guestsCount={guestsCount} 
                    setGuestsCount={setGuestsCount} location={location} setLocation={setLocation} cartObj={cartObj} history={history} />
                <RestaurantSlider openModalHandler={openModalHandler} />
                <Footer />
            </div>
        </>
    );
}
