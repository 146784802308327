import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Filters from "../../Components/Filters";
import { CONSTANTS } from "../../Helpers/Constants";
import ErrorDisplay from "../../Components/VendorError";
import PeopleIcon from "../../Assets/Images/people.svg";
import PriceIcon from "../../Assets/Images/price.svg";
import Rectangle from "../../Assets/Images/Rectangle.svg";
import packageItemsIcon from "../../Assets/Images/bullet_points.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FilterDialog from "../../Components/FilterDialog";
import json from "../../Assets/JSON/landing-page.json";
import Loader from "../../Components/Loader";
import CartModal from "../../Components/CartModal";

export default function PackagesList({ vendor, error, carousalForm, setCarousalForm,
  openFilterDialog, showCartModal,
  serviceMode, fetchVendors, changeHandler, loading,
  setServiceMode, addToCartEventHandler,
  selectedPackage, prefrences, setPreferences, tags, pathname }) {


  return (
    <>
    {loading && 
      <div className="loaderParent">
        <Loader />
      </div> }
      {showCartModal && <CartModal packageObj={selectedPackage} vendor={vendor}></CartModal>}
      {openFilterDialog && (
        <FilterDialog
          locations={json.locations}
          participants={json.participant}
          changeHandler={changeHandler}
          fetchVendors={fetchVendors}
          serviceMode={serviceMode}
          setServiceMode={setServiceMode}
          prefrences={prefrences}
          setPreferences={setPreferences}
          tags={tags}
          pathname={pathname}
        />
      )}
      <Header />
      <Filters />
      <div >
        { vendor ?
        <Row>
          <Col>
            <div className="vendor-main-package">
              <div className="img-container">
                <img src={vendor?.logoImageUrl} alt="" className="img-blur" aria-hidden="true" />
                <img src={vendor?.logoImageUrl} alt="Placeholder image" className="img-true" />
                <img src={Rectangle} className="svp-overlay" />
              </div>
              <div className="vendor-mp-overlay">
                <img
                  src={vendor?.logoImageUrl}
                  alt={"vendor?.logoImageUrl"}
                  className="vendor-mp-image"
                />
                <img src={Rectangle} className="svp-overlay" />
              </div>
              <div className="vendor-mp-description">
                <label className="vendor-mp-label-people">
                  <img src={PeopleIcon} />&nbsp;
                  {`${CONSTANTS.UPTO_TXT} ${vendor?.maximumOrderCapacity}`}
                </label>
                <label className="vendor-mp-label-price">
                  {`${vendor?.priceMin} ${CONSTANTS.KRONE_TXT} - ${vendor?.priceMax} ${CONSTANTS.KRONE_TXT}`}
                </label>
                <div className="vendor-mp-card-barrier">{vendor.name}</div>
                <div className="vendor-mp-details">
                  <p>{vendor?.description}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        : "" }
        {error != "" ? (
          <div className="packageListError">
            <ErrorDisplay error={error} />
          </div>
        ) : (
          <div className="selected-vendor-packages">
            <Row>
              {vendor.packages &&
                vendor.packages.map((item, i) => {
                  return (
                    <Row>
                      <Col md={6} lg={6}>
                        {i % 2 === 0 ?
                          <div className="svp-main" style={{ display: 'block', overflow: 'auto' }}>
                            <div className="svp-package" key={`div-package-${i}`}>
                              <img src={item?.imageUrl} className="svp-package-image" />
                              <div className="svp-package-info">
                                <h4>{item?.title}</h4>
                                <p>{item?.description}</p>
                              </div>
                            </div>
                            <div className="svp-package-item">
                              {item.packageItems &&
                                item.packageItems.map((element, i) => {
                                  return (
                                    <div className="svp-item" key={`div-packageItem-${i}`}>
                                      <Row>
                                        <Col xs={1} sm={1} md={1} lg={1}>
                                          <img src={packageItemsIcon} key={`img-packageItem-${i}`} alt="" />
                                        </Col>
                                        <Col xs={11} sm={11} md={11} lg={11}>
                                          <label>{element.title}</label>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="svpi-package-price">
                              <Row>
                                <Col xs={6} sm={6}>
                                  <h3>{`${CONSTANTS.MIN_ORDER_TXT}: ${item?.minimumOrderCapacity}`}</h3>
                                </Col>
                                <Col xs={6} sm={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                  <div className="svpi-pp-item">
                                    <h4>{`${item?.pricePerUnitWithFeeAmount} ${CONSTANTS.KRONE_TXT}`}</h4>
                                    <span>{CONSTANTS.EXKL_MOMS_TXT}</span>
                                  </div>
                                  <button className="svpi-vendor-menu-btn" onClick={() => addToCartEventHandler(item)}>
                                    {CONSTANTS.ADD_TO_CART_TXT}
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          : ''}
                      </Col>
                      {/* ------------------------------------------------------------------------------------- */}
                      <Col md={6} lg={6}>
                        {vendor.packages[i + 1] && (i + 1) % 2 === 1 ?
                          <div className="svp-main" style={{ display: 'block', overflow: 'auto' }}>
                            <div className="svp-package" key={`div-package-${i}`}>
                              <img src={vendor.packages[i + 1]?.imageUrl} className="svp-package-image" />
                              <div className="svp-package-info">
                                <h4>{vendor.packages[i + 1]?.title}</h4>
                                <p>{vendor.packages[i + 1]?.description}</p>
                              </div>
                            </div>
                            {vendor.packages.length >= i &&
                              <div className="svp-package-item">
                                {vendor.packages[i + 1].packageItems &&
                                  vendor.packages[i + 1].packageItems.map((element, i) => {
                                    return (
                                      <div className="svp-item" key={`div-packageItem-${i}`}>
                                        <Row>
                                          <Col xs={1} sm={1} md={1} lg={1}>
                                            <img src={packageItemsIcon} key={`img-packageItem-${i}`} alt="" />
                                          </Col>
                                          <Col xs={11} sm={11} md={11} lg={11}>
                                            <label>{element.title}</label>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })}
                              </div>
                            }
                            <div className="svpi-package-price">
                              <Row>
                                <Col xs={6} sm={6}>
                                  <h3>{`${CONSTANTS.MIN_ORDER_TXT}: ${vendor.packages[i + 1]?.minimumOrderCapacity}`}</h3>
                                </Col>
                                <Col xs={6} sm={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                  <div className="svpi-pp-item">
                                    <h4>{`${vendor.packages[i + 1]?.pricePerUnitWithFeeAmount} ${CONSTANTS.KRONE_TXT}`}</h4>
                                    <span>{CONSTANTS.EXKL_MOMS_TXT}</span>
                                  </div>
                                  <button className="svpi-vendor-menu-btn" onClick={() => addToCartEventHandler(vendor.packages[i + 1])}>
                                    {CONSTANTS.ADD_TO_CART_TXT}
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          : ''}
                      </Col>
                      {i % 2 ? <div className="main-row"></div> : ''}
                    </Row>
                  );
                })}
            </Row>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
