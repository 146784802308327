import React, { useContext, useEffect, useState } from "react";

import { getAuth, getIdToken, getIdTokenResult } from "firebase/auth";

import { useLocation } from "react-router";
import { AppContext } from "../../Context/AppContext";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Loader from "../../Components/Loader";
import LoginDialog from "../../Components/LoginDialog";
import BillingInfoScreen from "./Views/BillingInfoScreen";
import { getUserData, toIsoString } from "../../Helpers/Handlers";
import { OrdersAPI } from "../../Services/OrderProcessing";
import "../../Assets/Styles/billing.css";
import ErrorDisplay from "../../Components/VendorError";
import EmptCartImg from "../../Assets/Images/empty_cart.svg";
import CartErrorModal from "../../Components/CartErrorModal";
import { useHistory } from "react-router";
import { CONSTANTS } from "../../Helpers/Constants";

export default function BillingPage() {
  const auth = getAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [cartError, setCartError] = useState(null);

  const location = useLocation();
  const { pathname } = useLocation();
  const currentLocation = location.pathname;
  const cart_cateringMode = localStorage.getItem("cart_cateringMode") || "";
  const cart_supplement = localStorage.getItem("cart_supplement") ? JSON.parse(localStorage.getItem("cart_supplement")) : "";
  const cart_location = localStorage.getItem("cart_location") || "";
  const cart_date = localStorage.getItem("cart_date") || "";
  const vendor = localStorage.getItem("selected_vendor")
    ? JSON.parse(localStorage.getItem("selected_vendor"))
    : "";
  const configs = localStorage.getItem("configs")
    ? JSON.parse(localStorage.getItem("configs"))
    : "";
  const currentUser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const {
    billingInfo,
    setBillingInfo,
    deliveryInfo,
    setDeliveryInfo,
    openLoginDialog,
    setOpenLoginDialog,
    customerLogin,
    setCustomerLogin,
    loginStep,
    setLoginStep,
    currentLoginCustomer,
    setCurrentLoginCustomer,
    emailError,
    setEmailError,
    emailErrorMessage,
    setEmailErrorMessage,
    authError,
    setAuthError,
    authErrormessage,
    setAuthErrorMessage,
    cartObj,
  } = useContext(AppContext);
  const [error, setError] = useState("");

  useEffect(() => {
    setOpenLoginDialog(false);
  }, [pathname])

  useEffect(() => {
    if (currentLoginCustomer) {
      const customerID = currentLoginCustomer.userId;
      fetchCustomerDetails(customerID);
      setOpenLoginDialog(false);
      window.scrollTo(0, 0)
      history.push("#billing-main-comp"); //used to scroll for iphone browser
      document.body.scrollTop = 0;
    } else {
      setOpenLoginDialog(true);
    }
  }, [currentLoginCustomer]);

  useEffect(() => {
    if (cartObj?.length === 0) {
      setError({
        imageUrl: EmptCartImg,
        title: CONSTANTS.LOOKS_TXT,
        text: CONSTANTS.EMPTY_CART_TXT,
        additionalText: CONSTANTS.CHECK_MSG,
      });
    }
  }, [cartObj]);


  const fetchCustomerDetails = async (customerID) => {
    const customerData = await getUserData(customerID);
    if (customerData.name !== "") {
      setBillingInfo({
        ...billingInfo,
        name: billingInfo.name !== "" ? billingInfo.name : customerData.name,
        email: customerData.emailAddress,
        phoneNumber: customerData.contactNumber,
        companyName: customerData.company,
        organizationNumber: customerData.organizationNumber,
        streetAddress: customerData.address,
        postalCode: customerData?.postalCode,
        city: customerData.city,
      });
    } else {
      setBillingInfo({
        ...billingInfo,
        email: customerData.emailAddress,
      });
    }
    setDeliveryInfo({
      ...deliveryInfo,
      area: cart_cateringMode === "delivery" ? cart_location : "",
      city: cart_cateringMode === "delivery" ? vendor?.city : "",
    });
  };
  const setPhoneNoChange = (item, field, error_field) => {
    const value = item.target.value;
    let checkValue = value.replace(/\s/g, "").replace(/[^\d+-]/g, "");
    let regex = /^(\+?\d{1,2}\s?)(\d{7}|\d{9})$/;

    if (regex.test(checkValue) === false || value === "") {
      setBillingInfo({
        ...billingInfo,
        [field]: item.target.value,
        [error_field]: CONSTANTS.INVALID_PHONE_ERROR,
      });
      return false;
    } else {
      setBillingInfo({
        ...billingInfo,
        [field]: item.target.value,
        [error_field]: "",
      });
    }
  };
  const setDeliveryPhoneNoChange = (item, field, error_field) => {
    const value = item.target.value;
    let checkValue = value.replace(/\s/g, "").replace(/[^\d+-]/g, "");
    let regex = /^(\+?\d{1,2}\s?)(\d{7}|\d{9})$/;

    if (regex.test(checkValue) === false || value === "") {
      setDeliveryInfo({
        ...deliveryInfo,
        [field]: item.target.value,
        [error_field]: CONSTANTS.CONTACT_PHONE_INVALID_ERROR,
      });
      return false;
    } else {
      setDeliveryInfo({
        ...deliveryInfo,
        [field]: item.target.value,
        [error_field]: "",
      });
    }
  };

  const validateData = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let _billingInfo = { ...billingInfo };
    let _deliveryInfo = { ...deliveryInfo };

    if (_billingInfo.name === "") {
      _billingInfo["name_error"] = CONSTANTS.NAME_ERROR;
    } else {
      delete _billingInfo["name_error"];
    }

    if (_billingInfo.email === "") {
      _billingInfo["email_error"] = CONSTANTS.EMAIL_ERROR;
    } else if (!emailRegex.test(_billingInfo.email)) {
      _billingInfo["email_error"] = CONSTANTS.INVALID_EMAIL_ERROR;
    } else {
      delete _billingInfo["email_error"];
    }

    if (_billingInfo.phoneNumber === "") {
      _billingInfo["phone_error"] = CONSTANTS.PHONE_ERROR;
    } else {
      delete _billingInfo["phone_error"];
    }

    if (_billingInfo.companyName === "") {
      _billingInfo["company_error"] = CONSTANTS.COMPANY_ERROR;
    } else {
      delete _billingInfo["company_error"];
    }

    if (_billingInfo.organizationNumber === "") {
      _billingInfo["org_error"] = CONSTANTS.ORG_NO_ERROR;
    } else {
      delete _billingInfo["org_error"];
    }

    if (_billingInfo.streetAddress === "") {
      _billingInfo["street_error"] = CONSTANTS.STREET_ERROR;
    } else {
      delete _billingInfo["street_error"];
    }

    if (_billingInfo.postalCode === "") {
      _billingInfo["postal_error"] = CONSTANTS.POSTCODE_ERROR;
    } else {
      delete _billingInfo["postal_error"];
    }

    if (_billingInfo.city === "") {
      _billingInfo["city_error"] = CONSTANTS.CITY_ERROR;
    } else {
      delete _billingInfo["city_error"];
    }

    if (!_deliveryInfo.useSameAsAbove) {
      if (_deliveryInfo.contactPersonName === "") {
        _deliveryInfo["cp_name_error"] = CONSTANTS.CONTACT_NAME_ERROR;
      } else {
        delete _deliveryInfo["cp_name_error"];
      }

      if (_deliveryInfo.contactPersonEmail === "") {
        _deliveryInfo["cp_email_error"] = CONSTANTS.CONTACT_EMAIL_ERROR;
      } else if (!emailRegex.test(_deliveryInfo.contactPersonEmail)) {
        _deliveryInfo["cp_email_error"] = CONSTANTS.CONTACT_EMAIL_INVALID_ERROR;
      } else {
        delete _deliveryInfo["cp_email_error"];
      }

      if (_deliveryInfo.contactPersonPhone === "") {
        _deliveryInfo["cp_phone_error"] = CONSTANTS.CONTACT_PHONE_ERROR;
      } else {
        delete _deliveryInfo["cp_phone_error"];
      }

      if (_deliveryInfo.area === "" && cart_cateringMode === "delivery") {
        _deliveryInfo["cp_area_error"] = CONSTANTS.CONTACT_AREA_ERROR;
      } else {
        delete _deliveryInfo["cp_area_error"];
      }

      if (_deliveryInfo.streetAddress === "" && cart_cateringMode === "delivery") {
        _deliveryInfo["cp_street_error"] =
          CONSTANTS.CONTACT_STREET_ERROR;
      } else {
        delete _deliveryInfo["cp_street_error"];
      }

      if (_deliveryInfo.postalCode === "" && cart_cateringMode === "delivery") {
        _deliveryInfo["cp_postal_error"] =
          CONSTANTS.CONTACT_POSTCODE_ERROR;
      } else {
        delete _deliveryInfo["cp_postal_error"];
      }

      if (_deliveryInfo.city === "" && cart_cateringMode === "delivery") {
        _deliveryInfo["cp_city_error"] = CONSTANTS.CONTACT_CITY_ERROR;
      } else {
        delete _deliveryInfo["cp_city_error"];
      }
    } else {
      delete _deliveryInfo["cp_area_error"];
      delete _deliveryInfo["cp_street_error"];
      delete _deliveryInfo["cp_postal_error"];
      delete _deliveryInfo["cp_city_error"];
      delete _deliveryInfo["cp_name_error"];
      delete _deliveryInfo["cp_email_error"];
      delete _deliveryInfo["cp_phone_error"];
    }

    if (_deliveryInfo.numberOfGuests === "") {
      _deliveryInfo["cp_guests_error"] = CONSTANTS.GUESTS_ERROR;
    } else {
      delete _deliveryInfo["cp_guests_error"];
    }

    if (!_deliveryInfo.TermsAndConditions) {
      _deliveryInfo["terms_error"] = CONSTANTS.TERMS_ERROR;
    } else {
      delete _deliveryInfo["terms_error"];
    }

    setBillingInfo(_billingInfo);
    setDeliveryInfo(_deliveryInfo);
    return !(
      _billingInfo["name_error"] ||
      _billingInfo["email_error"] ||
      _billingInfo["phone_error"] ||
      _billingInfo["company_error"] ||
      _billingInfo["org_error"] ||
      _billingInfo["street_error"] ||
      _billingInfo["postal_error"] ||
      _billingInfo["city_error"] ||
      _deliveryInfo["cp_name_error"] ||
      _deliveryInfo["cp_email_error"] ||
      _deliveryInfo["cp_phone_error"] ||
      _deliveryInfo["cp_area_error"] ||
      _deliveryInfo["cp_street_error"] ||
      _deliveryInfo["cp_postal_error"] ||
      _deliveryInfo["cp_city_error"] ||
      _deliveryInfo["cp_guests_error"] ||
      _deliveryInfo["terms_error"]
    );
  };

  const getOrderValidate = async (token) => {
    const validateData = {
      customer_order_packages: cartObj?.map((item) => {
        return {
          packageId: `/vendor_packages/${item.packageId}`,
          orderQuantity: parseInt(item.quantity),
        };
      }),
      serviceArea: cart_location.toLowerCase(),
      serviceDate: toIsoString(new Date(cart_date)),
      serviceMode: cart_cateringMode,
      vendorId: `vendor_profiles/${cartObj?.[0].vendorId}`,
    };

    const response = await OrdersAPI.validateOrder(validateData, token)
      .then((response) => {
        if (response?.status === "error") {
          const errors = response?.data?.errors;
          setCartError({
            title: (errors && errors?.[0].title) || CONSTANTS.OOPS_TXT,
            body:
              (errors && errors?.[0].message) ||
              CONSTANTS.SOMETHING_WENT_WRONG_TXT,
          });
          setLoading(false);
          return false;
        } else if (response?.status === "success") {
          setCartError(null);
          return true;
        }
      })
      .catch((e) => {
        setCartError({
          title: CONSTANTS.OOPS_TXT,
          body: CONSTANTS.SOMETHING_WENT_WRONG_TXT,
        });
        setLoading(false);
        return false;
      });
    return response;
  };
  const submitBilling = async () => {
    if (validateData()) {
      setLoading(true);
      const instaFee = configs?.instacate_fee && parseFloat(configs.instacate_fee) / 100;
      const total_quantity = cartObj.reduce((total, cartItem) => {
        return total + parseInt(cartItem.quantity);
      }, 0);
      const sub_total = cartObj.reduce((total, cartItem) => {
        const totalPrice =
          cartItem.pricePerUnitWithFeeAmount * parseInt(cartItem.quantity);
        return total + totalPrice;
      }, 0);
      const subTotalWithoutInstaFee = cartObj.reduce((total, cartItem) => {
        const totalPrice =
          cartItem.pricePerUnit * parseInt(cartItem.quantity);
        return total + totalPrice;
      }, 0);
      const subTotalInstaFee = sub_total - subTotalWithoutInstaFee;
      const suppliment_charges = cart_supplement
        ? Math.round(sub_total * (vendor.supplementMarkUp / 100) * 100) / 100
        : 0;
      const supplimentChargesWithoutInstaFee = cart_supplement
        ? Math.round(subTotalWithoutInstaFee * (vendor.supplementMarkUp / 100) * 100) / 100
        : 0;
      const supplementChargesInstaFee = suppliment_charges - supplimentChargesWithoutInstaFee;
      const insta_charges = parseFloat(subTotalInstaFee) + parseFloat(supplementChargesInstaFee);
      const moms_charges =
        Math.round(
          (cart_supplement
            ? parseFloat(suppliment_charges) + parseFloat(sub_total)
            : parseFloat(sub_total)) *
          (parseInt(configs.moms) / 100) *
          100
        ) / 100;
      const grandTotal =
        Math.round((sub_total + suppliment_charges + moms_charges) * 100) / 100;
      let idToken = "";
      await auth.currentUser
        .getIdToken(true)
        .then(function (_idToken) {
          idToken = _idToken;
          setCurrentLoginCustomer({
            ...currentLoginCustomer,
            idToken: _idToken,
          });

          let objuser = { ...currentUser, idToken: _idToken };
          setUser(objuser);
          localStorage.setItem("user", JSON.stringify(objuser));
        })
        .catch(function (error) {
          // Handle error
        });
      const validateOrder = await getOrderValidate(idToken);
      if (validateOrder) {
        const customer_order_packages = cartObj?.map((item) => {
          return {
            allergyComments: item.allergies,
            orderedQuantity: item.quantity,
            packageId: item.packageId,
            pricePerUnit: item.pricePerUnit,
            customer_order_package_items: item.packageItems.map(
              (packageItem) => packageItem.packageItemId
            ),
          };
        });

        const order = {
          additionalInformation: deliveryInfo.additionalInformation,
          createdById: currentLoginCustomer.userId,
          createdOn: new Date(),
          customerCompany: billingInfo.companyName,
          customerContactNumber: deliveryInfo.useSameAsAbove ? billingInfo.phoneNumber : deliveryInfo.contactPersonPhone,
          customerContactPerson: deliveryInfo.useSameAsAbove
            ? billingInfo.name : deliveryInfo.contactPersonName,
          customerEmailAddress: deliveryInfo.useSameAsAbove ? billingInfo.email : deliveryInfo.contactPersonEmail,
          customerId: currentLoginCustomer?.userId,
          customerOrganizationNumber: billingInfo.organizationNumber,
          instacateFeeAmount: insta_charges,
          instacateFeePercent: configs.instacate_fee,
          maxActionBy: "",
          modifiedById: currentLoginCustomer?.userId,
          modifiedOn: new Date(),
          momsAmount: moms_charges,
          momsPercent: configs.moms,
          paymentMode: "live",
          packageTotalAmount: subTotalWithoutInstaFee,
          serviceAddressFloor: "",
          serviceArea: cart_cateringMode === "pickup"
            ? vendor?.vendorArea : deliveryInfo.area,
          serviceCity: cart_cateringMode === "pickup"
            ? vendor?.vendorCity
            : deliveryInfo.city,
          serviceDate: toIsoString(new Date(cart_date)),
          serviceDoorCode: deliveryInfo.doorCode,
          serviceMode: cart_cateringMode,
          servicePostalCode:
            cart_cateringMode === "pickup"
              ? vendor?.postalCode
              : deliveryInfo.useSameAsAbove
                ? billingInfo.postalCode
                : deliveryInfo.postalCode,
          serviceStreetAddress:
            cart_cateringMode === "pickup"
              ? vendor?.address
              : deliveryInfo.useSameAsAbove
                ? billingInfo.streetAddress
                : deliveryInfo.streetAddress,
          supplementAmount: supplimentChargesWithoutInstaFee,
          supplementPercent: vendor.supplementMarkUp,
          totalPackageQuantity: total_quantity,
          totalPayableAmount: grandTotal,
          totalPeople: deliveryInfo.numberOfGuests && parseInt(deliveryInfo.numberOfGuests),
          vendorId: vendor?.vendorId,
          vendorName: vendor?.name,
          customer_order_packages: customer_order_packages,
          customer_order_payments: [
            {
              billingAddressFloor: "",
              billingArea: billingInfo.area,
              billingCity: billingInfo.city,
              billingPostalCode: billingInfo.postalCode,
              billingStreetAddress: billingInfo.streetAddress,
              billingContactNumber: billingInfo.phoneNumber,
              billingCustomerName: billingInfo.name,
              billingEmailAddress: billingInfo.email,
            },
          ],
        };

        await OrdersAPI.createOrder(order, idToken)
          .then((response) => {
            if (response?.status === "success") {
              order.packageTotalAmount = sub_total;
              order.supplementAmount = suppliment_charges;
              localStorage.setItem("order", JSON.stringify(order));
              localStorage.setItem("intent", JSON.stringify(response));
              history.push("/payment");
              setLoading(false);
            } else {
              setCartError({
                title: CONSTANTS.OOPS_TXT,
                body:
                  CONSTANTS.SOMETHING_WENT_WRONG_TXT,
              });
              setLoading(false);
            }
          })
          .catch((e) => {
            setCartError({
              title: CONSTANTS.OOPS_TXT,
              body:
                CONSTANTS.SOMETHING_WENT_WRONG_TXT,
            });
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {cartError && (
        <CartErrorModal error={cartError} callback={() => setCartError(null)} />
      )}

      <div className="ic-wrapper">
        {loading &&
          <div className="loaderParent">
            <Loader />
          </div>}
        <Header />
        {error !== "" ? (
          <ErrorDisplay error={error} showRedirect={true} />
        ) : (
          <>
            {openLoginDialog && (
              <LoginDialog
                openLoginDialog={openLoginDialog}
                setOpenLoginDialog={setOpenLoginDialog}
                customerLogin={customerLogin}
                setCustomerLogin={setCustomerLogin}
                loginStep={loginStep}
                setLoginStep={setLoginStep}
                emailError={emailError}
                setEmailError={setEmailError}
                emailErrorMessage={emailErrorMessage}
                setEmailErrorMessage={setEmailErrorMessage}
                authError={authError}
                setAuthError={setAuthError}
                authErrormessage={authErrormessage}
                setAuthErrorMessage={setAuthErrorMessage}
                currentLocation={currentLocation}
              />
            )}
            <div id="billing-main-comp">
              <BillingInfoScreen
                billingInfo={billingInfo}
                setBillingInfo={setBillingInfo}
                deliveryInfo={deliveryInfo}
                setDeliveryInfo={setDeliveryInfo}
                cateringMode={cart_cateringMode}
                submitBilling={submitBilling}
                setPhoneNoChange={setPhoneNoChange}
                setDeliveryPhoneNoChange={setDeliveryPhoneNoChange}
              />
            </div>
          </>
        )}
        <Footer />
      </div>
    </>
  );
}
