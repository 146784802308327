import React from "react";
import LineIcon from '../../../Assets/Images/line_icon.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CONSTANTS } from "../../../Helpers/Constants";

export default function PrivacyPolicyScreen() {
    return (
        <div className="privacy-policy">
            <div className="pp-content">
                <h4>Din integritet är viktig för oss</h4>
                <p>Vi vill att du ska känna dig trygg med att vi hanterar dina personliga uppgifter på ett ansvarsfullt sätt, oavsett i vilket syfte du är i kontakt med oss. Syftet med vår integritetspolicy är att förtydliga hur vi som personuppgiftsansvarig hanterar och skyddar dina personuppgifter i kontakt med oss. I denna integritetspolicy hittar du därför övergripande information om hur vi hanterar personuppgifter enligt gällande lagstiftning och särskilt med hänvisning till Dataskyddsförordningen, General Data Protection Regulation (GDPR).</p>

                <p>Vad är då en personuppgift? Det är en upplysning som kan kopplas till en identifierad eller identifierbar fysisk person. Som personuppgiftsansvarig samlar vi nödvändig information om dig där det behövs för att du ska kunna ta del av våra tjänster. Det rör sig exempelvis om namn, personnummer, e-post och postadress. Du kan alltid kontakta oss för att få reda på exakt vad vi sparar för information.</p>
                <p>Informationen används för att vi ska kunna leverera våra tjänster och ta vårt ansvar, både som arbetsgivare och verksamhet. Det kan exempelvis handla om marknadsföring, fakturering, cookies på hemsidan och att följa våra rättsliga skyldigheter. All behandling av dina personuppgifter har laglig grund, vilket betyder att vi endast hanterar dina personuppgifter där vi har ett avtal, rättsliga förpliktelser, ett berättigat intresse eller ditt samtycke.</p>
                <p>Personuppgifterna sparas för ändamål kopplade till vår verksamhet och gallras genom anonymisering eller radering när vi inte längre har laglig grund att behandla dem. Du äger dina uppgifter och vi ser till att du kan få veta vad, hur länge och varför.</p>
                <p>Dina personuppgifter kan lämnas vidare av oss till myndigheter om det efterfrågas genom lag eller till en partner till oss som vi har ett personuppgiftsbiträdesavtal med. Partnern blir då ett personuppgiftsbiträde. Biträdet får bara hantera uppgifter enligt våra bestämmelser och ytterst är det vi på Instacate som ansvarar för dina uppgifter.</p>

                <h4>Vilken information samlar vi in?</h4>
                <p>Informationen som samlas in behandlas av Instacate AB ("Instacate"), 559337-2112, på Östergårdsvägen 10, 163 54 Stockholm. Instacate sparar informationen med hänvisning till GDPR. Vi samlar in personuppgifter för att kunna tillhandahålla tjänster och för att förbättra vår service. Integritetspolicyn gäller våra leverantörer, kunder och anställda.</p>
                <p>Du kan direkt eller indirekt komma att ge oss information om dig själv på olika sätt. Till exempel samlas viss personlig identifierbar information in via vår webbsida och våra digitala kanaler. Vilken information vi väljer att samla in om dig beror på hur och varför vår kontakt har uppstått. Om du använder någon av våra tjänster så är den information vi sparar ofta begränsad till ditt namn, företag, adress, e-post och telefonnummer. I vissa fall samlar vi in information inom särskilda kategorier av extra skyddsvärda personuppgifter. Det kan exempelvis handla om hälsorelaterad information som vi måste ha för att vi ska kunna ta hänsyn till allergi.</p>

                <h4>Hur och i vilket syfte samlar vi in personuppgifter?</h4>
                <p>Instacate behandlar personuppgifter för administrering och kommunikation med kunder, anställda och leverantörer. Syftet är att hantera bokningar, beställningar, anställningar och administrera verksamheten. Det rör sig om namn, adress, e-postadress, telefonnummer, bokningar, matpreferenser samt allergier. Ibland kan vi komma att behöva personnummer eftersom det är det enda sättet för oss att säkerställa identiteten.</p>
                <p>Instacate använder personuppgifter om namn, adress, e-postadress och i vissa fall bilder för att marknadsföra våra tjänster i exempelvis digitala kanaler samt genom utskick med erbjudanden och information. För att förbättra våra tjänster skickar vi ut förfrågningar om deltagande undersökningar. Då behövs personuppgifter såsom namn, e-postadress samt svarsresultat.</p>
                <p>För att hantera betalningar behandlar Instacate personuppgifter om kortnummer, fakturanummer, namn, e-postadress, telefonnummer och detaljer om beställningen.</p>
                <p>För att sköta administreringen och utbetalningen av löner och kontakt med anställda behöver vi behandla anställdas personuppgifter. Det rör sig om namn, personnummer, adress, e-postadress, telefonnummer, eventuella anhöriga, kontonummer, arbetsgivarintyg, lönespecifikationer, information om närvaro/frånvaro, kontrolluppgifter samt inkomstuppgifter.</p>
                <p>För att hantera eventuella rättsliga anspråk kan Instacate behöva behandla personuppgifter såsom namn, personnummer, adress, e-postadress, telefonnummer, kontonummer, händelseförlopp och platsinformation.</p>
                <p>Vi använder cookies och liknande spårningstekniker för att förbättra din upplevelse av våra tjänster. På så vis får vi information om exempelvis din webbläsartyp och ditt operativsystem, visade webbsidor, länkar som du klickat på, IP-adress samt hur du hanterar e-post från oss. Du kan ställa in din webbläsare för att exempelvis sluta acceptera nya cookies, få ett meddelande när du får en ny cookie, inaktivera befintliga cookies och för att dölja bilder som visas på hemsidan.</p>

                <h4>Hur hanterar vi personuppgifter och personnummer?</h4>
                <p>Vi grundar i huvudsak behandlingen av dina personuppgifter på intresseavvägning. Vi kan komma att dela information med leverantörer som vi har anlitat för att utföra tjänster för vår räkning, exempelvis för att hjälpa till med leveranser av beställningar, för att genomföra marknadsföringskampanjer eller göra utskick. Dessa leverantörer kan få tillgång till personligt identifierbar information om dig om det behövs för att utföra uppgifter åt oss, men leverantörer har inte vårt tillstånd att använda eller avslöja informationen såvida inte så krävs för att utföra tjänster för vår räkning eller för att tillgodose juridiska krav.</p>
                <p>I vissa fall kan vi dela med oss av uppgifter till andra mottagare, såsom myndigheter på grund av lagkrav. Personuppgifter kan komma att delas med potentiella köpare och säljare till hela eller delar av verksamheten. Om personuppgifter delas med utvalda tredje parter tillser vi att överföringen sker på ett sätt som är säkert och som bejakar din integritet.</p>
                <p>Personuppgifter lagras av oss under den tid det är nödvändigt för de ändamål som specificeras ovan. Det kan vara så länge som det är nödvändigt för Instacate att fullfölja våra avtal eller andra åtaganden. Det kan även vara så länge det krävs enligt lag eller myndighetsbeslut. Vi gallrar registrerade personuppgifter regelbundet och vidtar rimliga åtgärder med syfte att hålla behandlade personuppgifter aktuella, vilket betyder att vi raderar inaktuella, eventuellt felaktiga eller överflödiga personuppgifter.</p>
                <p>Instacate liksom våra leverantörer samt samarbetspartners behandlar som huvudregel dina personuppgifter inom EU/EES. I de fall personuppgifter behandlas utanför EU/EES säkerställer vi att våra leverantörer och samarbetspartners lever upp till samma säkerhets- och skyddsnivå som tillämpas inom EU/EES.</p>

                <h4>Vilka är dina rättigheter?</h4>
                <p>Du har rätt att få information om de uppgifter vi har om dig, hur vi behandlar den, vad som är ändamålet och under vilken tidsperiod. Om du så begär förser vi dig med en kopia av de personuppgifter som är under behandling (registerutdrag). För eventuella ytterligare kopior får vi som personuppgiftsansvarig ta ut en rimlig avgift baserad på de administrativa kostnaderna. Om du gör begäran i elektronisk form ska informationen tillhandahållas i ett elektroniskt format som är allmänt använt i det fall den registrerade inte begär något annat.</p>
                <p>Du har rätt att få felaktiga uppgifter om dig korrigerade och du har rätt att komplettera med relevanta uppgifter som eventuellt saknas. När dina personuppgifter korrigeras kommer vi att informera de vi delat uppgifterna med om rättelsen – förutom i de fall där detta är omöjligt eller innebär en alltför betungande insats. Du kan begära att få veta till vem/vilka vi informerat om rättelsen.</p>
                <p>Du har rätt att utan onödigt dröjsmål få dina uppgifter raderade under följande förutsättningar:</p>
                <p>Om uppgifterna inte längre behövs för de ändamål som de behandlas för.
                    Om behandlingen grundar sig enbart på ditt samtycke och du återkallar detta.
                    Om du motsätter dig behandling som sker efter en intresseavvägning och det inte finns berättigade skäl som väger tyngre än ditt intresse.
                    Om personuppgifterna har behandlats olagligt.
                    Om radering krävs för att uppfylla en rättslig skyldighet.</p>
                <p>Om dina uppgifter raderas kommer vi att informera om detta till de som vi har lämnat ut dina uppgifter till – förutom i de fall där detta är omöjligt eller innebär en alltför betungande insats. Du kan begära att få veta till vem/vilka vi informerat om raderingen.</p>
                <p>Du har rätt att begära en tillfällig begränsning av behandling av dina personuppgifter i följande situationer:</p>
                <p>När du anser att dina personuppgifter inte är korrekta och du därför har begärt rättelse hos oss. Du kan då begära att behandlingen av dina personuppgifter begränsas under en tid som ger oss möjlighet att kontrollera om personuppgifterna är korrekta.
                    När behandlingen är olaglig och du i stället för radering begär en begränsning av deras användning.
                    När vi inte längre behöver uppgifterna för ändamålen med behandlingen men du behöver dem för att kunna fastställa, göra gällande eller försvara rättsliga anspråk.
                    När du har invänt mot behandling i enlighet med artikel 21.1 (intresseavvägning) i väntan på kontroll av om våra berättigade skäl väger tyngre än dina berättigade skäl.</p>
                <p>Om behandlingen har begränsats kommer sådana personuppgifter, med undantag för lagring, endast behandlas med ditt samtycke eller för att fastställa, göra gällande eller försvara rättsliga anspråk eller för att skydda någon annan fysisk eller juridisk persons rättigheter. Om du har fått behandlingen begränsad kommer du att underrättas av oss innan begränsningen av behandlingen upphör.</p>
                <p>Du har rätt att få ut dina personuppgifter på ett strukturerat, allmänt använt och maskinläsbart format. Du har rätt att överföra dessa uppgifter till en annan personuppgiftsansvarig om behandlingen grundar sig på samtycke eller på ett avtal och behandlingen sker automatiserat.</p>
                <p>Du har rätt att i vissa fall göra invändningar mot behandling av dina personuppgifter. Rätten att invända gäller när personuppgifter behandlas för att utföra en uppgift av allmänt intresse, som ett led i myndighetsutövning eller efter en intresseavvägning. Om vi anser att sådan behandling ändå ska ske måste vi visa att det finns intressen som väger tyngre.</p>
                <p>Om du anser att dina personuppgifter behandlas i strid med gällande regelverk bör du snarast anmäla detta till oss på <a href="mailto:info@instacate.se">info@instacate.se</a>. Du kan även lämna in ett klagomål till Datainspektionen. Om du har lidit skada på grund av att dina personuppgifter har behandlats i strid med dataskyddsförordningen kan du ha rätt till skadestånd.</p>

                <h4>Frågor?</h4>
                <p>Varmt välkommen att kontakta oss på <a href="mailto:info@instacate.se">info@instacate.se</a> så återkommer till dig inom kort.</p>

                <h4>Kontaktuppgifter</h4>
                <p>Personuppgiftsansvarig är Instacate AB (org.nr. 559337-2112) med adress Östergårdsvägen 10, 163 54 Stockholm.</p>
            </div>
        </div>
    )
}
