import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../Context/AppContext";
import CartDetails from "./cartDetails";
import EmptCartImg from "../../Assets/Images/empty_cart.svg";
import { CONSTANTS } from "../../Helpers/Constants";
import { useLocation } from "react-router";

export default function cartDetail() {
  const {showSuplimentDialog, setSuplimentDialog, supplimentsAdded, setSuppliments, setOpenLoginDialog} = useContext(AppContext);
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);
  const { pathname } = useLocation();
  // const [showSuplimentDialog, setSuplimentDialog] = useState(false);

  //Below property examines if vendor has allowed supplements for these packages
  const [supplimentsAvailable, setSupplimentsAvailable] = useState(false);

  // const [supplimentsAdded, setSuppliments] = useState(localStorage.getItem('cart_supplement') ? JSON.parse(localStorage.getItem('cart_supplement')) : false);

  const { cartObj, setCartObj, showCartModal, setShowCartModal, error, setError, selectedPackage } = useContext(AppContext);
  const emptyCartConfirmationMsg = CONSTANTS.CLEAR_CART_CONFIRM_MSG;

  const removeSupplimentConfirmationMsg = supplimentsAdded ? CONSTANTS.EXCLUDE_SUPPLEMENT_CONFIRM_MSG : CONSTANTS.INCLUDE_SUPPLEMENT_CONFIRM_MSG

  const emptyCart = () => {
    setConfirmationDialog(false);
    setCartObj([]);
    localStorage.removeItem("cart");
    localStorage.removeItem("cart_date");
    localStorage.removeItem("cart_guests");
    localStorage.removeItem("cart_location");
    localStorage.removeItem("cart_cateringMode");
    localStorage.removeItem("selected_vendor");
    localStorage.removeItem('cart_supplement')
    setError({
      imageUrl: EmptCartImg,
      title: CONSTANTS.HAVE_NOT_MADE_YOUR_CHOICE,
      text: CONSTANTS.EMPTY_CART_TXT,
      additionalText: CONSTANTS.CHECK_MSG,
    });
  };

  useEffect(() => {
    setOpenLoginDialog(false);
  }, [pathname])

  useEffect(() => {
    if (cartObj?.length === 0) {
      setError({
        imageUrl: EmptCartImg,
        title: CONSTANTS.LOOKS_TXT,
        text: CONSTANTS.EMPTY_CART_TXT,
        additionalText: CONSTANTS.CHECK_MSG,
      });
    }
    else {
      setError({});
    }
  }, [cartObj]);

  return (
    <div className="ic-wrapper">
      <CartDetails
        emptyCart={emptyCart}
        emptyCartConfirmationMsg={emptyCartConfirmationMsg}
        removeSupplimentConfirmationMsg={removeSupplimentConfirmationMsg}
        openConfirmationDialog={openConfirmationDialog}
        setConfirmationDialog={setConfirmationDialog}
        error={error}
        showSuplimentDialog={showSuplimentDialog}
        setSuplimentDialog={setSuplimentDialog}
        setSupplimentsAvailable={setSupplimentsAvailable}
        setSuppliments={setSuppliments}
        showCartModal={showCartModal} setShowCartModal={setShowCartModal}
        selectedPackage={selectedPackage}
        supplimentsAdded={supplimentsAdded}
      />
    </div>
  );
}