import React from "react";

export default function SliderBox({ sliderImage, packageName, key, restaurantName, barrierBGColor, packageDescription, openModalHandler, index }) {
    return(
        <>
           <div className="slider_box" key={key} onClick={() => openModalHandler(index)}>
                <div className="slider-image" style={{ borderBottom: `5px solid ${barrierBGColor}`}}>
                    <img src={sliderImage} alt="SliderImage" className="package-image" />
                </div>
                <div className="slider-barrier" style={{ backgroundColor:barrierBGColor }}>{restaurantName}</div>
                <div className="slider-text">
                    <p className='slider-text-heading'>{packageName}</p>
                    <p className='slider-text-paragraph'>
                        {packageDescription}
                    </p>
                </div>
           </div>
        </>
    )
}