import { Row, Col } from "react-bootstrap"
import InstaLogo2 from "../../../Assets/Images/footer_logo.svg"
import { CONSTANTS } from "../../../Helpers/Constants"


export default function PaymentFooter() {
    return (
        <div className="footer payment-footer-container">
            <div>
                <div className="footer-logo">
                    <img src={InstaLogo2} alt="Logo" height='80px' width='100px' />
                </div>
                <div className="payment-footer">
                    <p>{CONSTANTS.INSTACATE_AB_TXT}</p>
                    <p>{CONSTANTS.INFO_EMAIL}</p>
                    <p>{CONSTANTS.CONTACT_NUMBER}</p>
                </div>
            </div>
        </div>
    )
}