import { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import FaqScreen from "./views/FaqScreen";
import Loader from "../../Components/Loader";
import { fetchFAQ } from "../../Services";

export default function FrequentQuestions() {
    const [questionOpened, setQuestionOpened] = useState('')
    const [faqData, setFaqData] = useState([])
    const [loading, setLoading] = useState(false)
    const [footer, setFooter] = useState(true)
    useEffect(async () => {
        setLoading(true)
        let tempData;
        const faqs = await fetchFAQ()
        tempData = faqs.data
        setFaqData(tempData)
        setLoading(false)
        setFooter(false)
    }, [])

    const classToggle = (index) => {
        const details = document.querySelectorAll("details");
        setQuestionOpened(index)
        let doc = document.getElementById(`id + ${index}`).getAttribute('open')
        if (doc !== null) setQuestionOpened('')
        details.forEach((targetDetail) => {
            targetDetail.addEventListener("click", (event) => {
                // Close all the details that are not targetDetail.
                details.forEach((detail) => {
                    if (detail !== targetDetail) {
                        detail.removeAttribute("open");
                    }
                });
            });
        });
    }
    return (
        <div className="ic-wrapper">
            <Header />
            {loading &&
                <div className="loaderParent">
                    <Loader />
                </div>}
            <FaqScreen
                footer={footer}
                questionOpened={questionOpened}
                setQuestionOpened={setQuestionOpened}
                faqData={faqData}
                classToggle={classToggle}
            />
            <Footer />
        </div>
    )
}