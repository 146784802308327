import React, { useEffect, useState, useContext } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { STRIPE_CALLBACK_URL } from '../../Services'
import { CONSTANTS } from "../../Helpers/Constants";
import poweredByStripe from "../../Assets/Images/powered-by-stripe.svg"; 
import { AppContext } from "../../Context/AppContext";

export default function CheckoutForm({ stripeIntent }) {
  const { billingInfo } = useContext(AppContext);
  const order = localStorage.getItem('order') ? JSON.parse(localStorage.getItem('order')) : null;
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(CONSTANTS.PAYMENT_SUCCESS);
          break;
        case "processing":
          setMessage(CONSTANTS.PAYMENT_IS_PROCESSING);
          break;
        case "requires_payment_method":
          setMessage(CONSTANTS.PAYMENT_WAS_NOT_SUCCESSFUL);
          break;
        default:
          setMessage(CONSTANTS.SOMETHING_WENT_WRONG_TXT);
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: STRIPE_CALLBACK_URL//'https://consumer-web-cd77.web.app/payment/' //"http://localhost:3000",
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(CONSTANTS.UNEXPECTED_ERROR);
    }

    setIsLoading(false);
  };
  return (
    <>
      <div className="payment-form">
        <form id="payment-form" onSubmit={handleSubmit} className='form'>
          <h3>{CONSTANTS.DEAR_TEXT} {billingInfo.name},</h3>
          <p className="mb30">{CONSTANTS.WE_ARE_ALMOST_DONE}</p>
          <PaymentElement id="payment-element" />
          <button disabled={isLoading || !stripe || !elements} id="submit" className='button'>
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : `${CONSTANTS.PAY_TXT} ${order?.totalPayableAmount} ${CONSTANTS.KRONE_TXT}`}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
          <div className="confirming_message">{CONSTANTS.BY_CONFIRMING}
          </div>

          <div className="poweredByStripe">
            <img src={poweredByStripe} alt="" />
          </div>
        </form>
      </div>
    </>
  );
}