import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import TermsAndConditionsScreens from "./Views/TermsAndConditionsScreen";

import '../../Assets/Styles/terms-conditions.css';

export default function TermsAndConditions() {
    return (
        <div className="ic-wrapper">
            <Header />
            <TermsAndConditionsScreens />
            <Footer />
        </div>
    )
}