import React from "react";
import Carousel from "../../../../Components/Carousel";
import MainImage from "../../../../Assets/Images/instaCateLogo.png";
import CartIcon from '../../../../Assets/Images/cart-home.svg';
import json from "../../../../Assets/JSON/landing-page.json";
import { CONSTANTS } from "../../../../Helpers/Constants";

export default function MainLayout({
  carousalForm,
  changeHandler,
  date,
  setDate,
  guestsCount,
  setGuestsCount,
  location,
  setLocation,
  cartObj,
  history
}) {
  return (
    <>
      <div className="container-main">
        <div className="front-page">
          {cartObj.length > 0 &&
            <div className="main-cart-div" onClick={() => history.push("/cart/detail")}>
              <img src={CartIcon} alt="CartIcon" className="cart-logo" />
              <span>{cartObj?.length}</span>
            </div>
          }
          <div className="main-heading">
            <img src={MainImage} alt="Logo" className={cartObj.length > 0 ? "main-logo margin-added" : "main-logo"} />
            <h2 className="icon_heading">{CONSTANTS.EATINGS_FOR_MEETINGS}</h2>
          </div>
          <Carousel
            carousalForm={carousalForm}
            locations={json.locations}
            participants={json.participant}
            changeHandler={changeHandler}
            date={date}
            setDate={setDate}
            guestsCount={guestsCount}
            setGuestsCount={setGuestsCount}
            location={location}
            setLocation={setLocation}
          />
        </div>
      </div>
    </>
  );
}
