import Form from "react-bootstrap/Form";
import calendarFilledImg from "../../Assets/Images/ic_fh_calendar.svg";
import locationFilledImg from "../../Assets/Images/ic_fh_location.svg";
import peoplesFilledImg from "../../Assets/Images/ic_fh_people.svg";
import timeIcon from "../../Assets/Images/ic_fh_time.svg";
import downloadICon from "../../Assets/Images/download-icon.svg"
import { Row, Col } from "react-bootstrap";
import { CONSTANTS } from "../../Helpers/Constants";
import "../../Assets/Styles/cart-detail.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
export default function FilterSelections({
  cart_cateringMode,
  cart_location,
  eventDate,
  cart_guests,
  eventTime,
  vendor,
  supplimentsAdded,
  setSuppliments,
  supplimentsAvailable,
  mode,
  setSuplimentDialog,
  orderDetail,
  handlePrint,
  printActive,
}) {
  return (
    <>
      <div className="vendor-main-package-options-parent">
        <div className="vendor-main-package">
          <Row>
            <div className="cart-heading">
              <h3 className="text-align-center cart-option-heading">{CONSTANTS.YOUR_ORDER}</h3>
              {printActive &&
                <div
                  onClick={handlePrint}
                  className="download-icon">
                  <FontAwesomeIcon icon={faPrint} />
                </div>}
            </div>
          </Row>
          <Row>
            <Col className={`${supplimentsAvailable ? 'cart-res-options' : 'cart-options'} `}>
              <div className="cart-subOptions">
                <img
                  src={calendarFilledImg}
                  alt=""
                  width="18px"
                  height="18px"
                ></img>
                <span> {eventDate}</span>
              </div>
              <div className="cart-subOptions">
                <img
                  src={timeIcon}
                  alt=""
                  width="18px"
                  height="18px"
                ></img>
                <span> {eventTime}</span>
              </div>

              <div className="cart-subOptions">
                <img
                  src={locationFilledImg}
                  alt=""
                  width="18px"
                  height="18px"
                ></img>
                <span> {cart_location}</span>
              </div>
              <div className="cart-subOptions">
                <img
                  src={peoplesFilledImg}
                  alt=""
                  width="18px"
                  height="18px"
                ></img>
                <span>{!orderDetail && `${CONSTANTS.UPTO_TXT}`}</span><span> {cart_guests && `${cart_guests}`}</span>
              </div>
              {(
                (supplimentsAvailable && !orderDetail) &&
                <div className="cart-supplement-option">
                  <Form>
                    <Form.Check
                      name="test"
                      type="switch"
                      id="custom-switch"
                      // onFocus={"none"}
                      checked={orderDetail?.supplementPercent ? true : supplimentsAdded}
                      label={`${CONSTANTS.INCLUDE_SUPPLEMENT} (${orderDetail ? orderDetail?.supplementPercent : vendor?.supplementMarkUp}%)`}
                      onChange={(e) => { setSuplimentDialog(true) }}
                      disabled={mode === 'readonly'}
                    />
                  </Form>
                </div>
              )}
              <div className="cart-subOptions">
                <div className="filter-prefernces-tags">
                  <span>{cart_cateringMode.toLowerCase() === 'pickup' ? CONSTANTS.PICKUP_TXT : CONSTANTS.DELIVERY_TXT}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <hr className="separator" />
    </>
  );
}
