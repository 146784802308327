import React from 'react';
import Lottie from 'lottie-react-web';
import tickLoader from '../../Assets/Animation/tick.json';

export default function TickLoader(){

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: tickLoader,
      };

    return(
        <>
            <div className="_loader">
                <Lottie
                    width = { 100 }
                    height = { 100 }
                    options = {defaultOptions}
                />
            </div>
        </>
    )
}