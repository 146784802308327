import React from 'react';

export default function Checkbox({ checked, id, name, value, onChange, label, anchorTag }) {
    return (
        <div className="billing-input-checkbox">
            <label className="container">{label} <a className="tnc_link">{anchorTag}</a>
                <input type="checkbox" id={id} name={name} value={value} onChange={onChange} checked={checked} />
                <span className="checkmark"></span>
            </label>
        </div>
    )
}