
export const CONSTANTS = {
  ADD_TO_CART_TXT: "Lägg i varukorg",
  EXKL_MOMS_TXT: "Exkl. moms",
  KRONE_TXT: "kr",
  SUB_TOTAL_TXT: "Summa",
  SUPPLEMENTS_TXT: "Tillbehör",
  MOMS_TXT: "Moms",
  TOTAL_TXT: "Totalt att betala",
  CHECK_OUT_TXT: "Betalning",
  YOUR_ORDER: "Din order",
  BILLING_INFO: "Fakturainformation",
  PAY_TXT: "Betala",
  CREATE_ANOTHER_ORDER: "Gör en ny order",
  QUANTITY_EXCEEDS_ERROR:
    "Tyvärr, du har överstigit maximalt antal Varro för paketet",
  EXCEED_QUANTITY_TXT: "Maxantal överskriden",
  ARE_YOU_SURE_TXT: "Är du säker?",
  YES_TXT: "Ja",
  NO_TXT: "Nej",
  CLOSE_TXT: "Stäng",
  REALLY_LOGOUT: "Vill du verkligen logga ut?",
  OOPS_TXT: "Ojdå",
  SOMETHING_WENT_WRONG_TXT: "Något blev fel",
  PAYMENT_INFO: "Betalningsinformation för ordernummer",
  GUESTS_ERROR: "Vänligen ange antal personer",
  TERMS_ERROR: "Vänligen godkänn villkoren",
  CONTACT_CITY_ERROR: "Vänligen ange kontaktpersonens stad",
  CONTACT_POSTCODE_ERROR: "Vänligen ange postnummer",
  CONTACT_STREET_ERROR: "Vänligen ange gatuadress",
  CONTACT_AREA_ERROR: "Vänligen ange postort",
  CONTACT_PHONE_ERROR: "Vänligen ange telefonnummer",
  CONTACT_EMAIL_INVALID_ERROR: "Vänligen ange e-postadress",
  CONTACT_EMAIL_ERROR: "Vänligen ange kontaktpersonens e-postadress",
  CONTACT_NAME_ERROR: "Vänligen ange kontaktpersonens namn",
  CITY_ERROR: "Vänligen ange stad",
  POSTCODE_ERROR: "Vänligen ange postadress",
  STREET_ERROR: "Vänligen ange gatuadress",
  ORG_NO_ERROR: "Vänligen ange organisationsnummer",
  COMPANY_ERROR: "Vänligen ange företagsnamn",
  PHONE_ERROR: "Vänligen ange ett giltligt telefonnummer",
  INVALID_EMAIL_ERROR: "Vänligen ange en giltlig e-postadress",
  EMAIL_ERROR: "Vänligen ange e-postadress",
  NAME_ERROR: "Vänligen ange namn",
  CONTACT_PHONE_INVALID_ERROR: "Vänligen ange ett telefonnummer till kontaktpersonen",
  INVALID_PHONE_ERROR: "Vänligen ange telefonnummer",
  EMPTY_CART_TXT: "Tom varukorg",
  CHECK_MSG: "Kolla in vad vi har att erbjuda!",
  LOOKS_TXT: "Du har inte gjort ditt val ännu",
  EVENT_DETAILS_MISSING_TXT: "Information saknas",
  DATE_LOCATION_MISSING_ERROR: "Vänligen ange datum och plats",
  FILLED_CART_EMPTY_MSG: `Varor från en annan leverantör finns redan i varukorgen. 
  Vill du radera dessa?`,
  SEE_FULL_MENU_TXT: "Se hela menyn",
  APPLY_FILTERS_TXT: "Filtrera",
  ITEM_UPDATED_MSG: "Varan har updaterats",
  QUANTITY_ERROR_MSG:
    "Antalet kan inte vara lägre än minimum",
  ITEM_ALREADY_ADDED_MSG: "Den här varan har redan lagts till i varukorgen",
  REMOVE_TXT: "Ta bort",
  CANCEL_TXT: "Avbryt",
  UPDATE_CART_TXT: "Uppdatera varukorgen",
  ITEM_ADDED_MSG: "Varan har lagts till i varukorgen",
  ITEM_REMOVED_MSG: "Varan har tagits bort från varukorgen",
  QUANTITY_TXT: "Antal",
  ALLERGIES_TXT: "Kommentarer",
  ALLERGY_PLACEHOLDER_TXT: "Vänligen ange eventuella allergier eller preferenser här.",
  CLEAR_CART_CONFIRM_MSG: "Vill du verkligen tömma varukorgen?`",
  INCLUDE_SUPPLEMENT: "Inklusive tillbehör",
  INCLUDE_SUPPLEMENT_CONFIRM_MSG:
    "Är du säker att du vill addera tillbehören?",
  EXCLUDE_SUPPLEMENT_CONFIRM_MSG:
    "Är du säker att du vill ta bort tillbehören?",
  EMPTY_TXT: "Tom",
  BACK_TO_MENU_TXT: "Tillbaka till menyn",
  NAME_TXT: "Namn",
  EMAIL_TXT: "E-post",
  PHONE_TXT: "Telefonnummer",
  COMPANY_NAME_TXT: "Företagsnamn",
  ORG_NO: "Organisationsnummer",
  STREET_TXT: "Gatuadress",
  POSTAL_CODE_TXT: "Postnummer",
  CITY_TXT: "Stad",
  PICKUP_INFO_TXT: "Hämtning",
  DELIVERY_INFO_TXT: "Leverans",
  USE_SAME_AS_ABOVE_TXT: "Använd samma som ovan",
  CONTACT_NAME_TXT: "Kontaktsperson",
  CONTACT_EMAIL_TXT: "Kontaktperson e-post",
  CONTACT_PHONE_TXT: "Kontaktperson telefon",
  AREA_TXT: "Ort",
  DOOR_CODE_TXT: "Portkod",
  ADDITION_INFO_TXT: "Övrig information",
  TERM_AND_CONDITIONS_TXT: <>Jag godkänner de <a href="/terms-and-conditions" target="_blank">allmänna villkoren</a></>,
  PROCEED_PAYMENT_TXT: "Gå vidare till betalning",
  DELIVERY_TXT: "Leverans",
  PICKUP_TXT: "Upphämtning",
  FILTER_TXT: "Filter",
  PREFERENCES_LABEL: "Preferenser (max 3)",
  EATINGS_FOR_MEETINGS_TXT: "Catering för alla dina möten.",
  LOGIN_BUTTON_TXT: "LOGGA IN",
  FREQUENT_TXT: "Vanliga frågor",
  TERMS_CONDITIONS_LABEL: "Allmänna villkor",
  PRVACY_POLICY: "Integritetspolicy",
  MY_ORDERS_TXT: "Mina beställningar",
  THANK_YOU_TXT: "Tack!",
  YOUR_ORDER_TXT: "Din beställning",
  HAS_BEEN_PLACED_TXT: "har lagts till",
  EMAIL_SENT_CONFIRM_TXT:
    "Vi har nu skickat din orderbekräftelse för och kvitto till ",
  SPAM_FILTERING_TXT: "Vänligen titta i din skräppost om du inte hittar mejlet.",
  TIME_PLACED_TXT: "Tid för order: ",
  BILIING_TXT: "Faktura",
  PRIVACY_POLICY_TXT: "Integritetspolicy",
  MIN_ORDER_TXT: "Minsta antal",
  DATE_UNAVAILABLE_TXT: "Datum ej tillgängligt",
  PACKAGE_UNAVIALABLE_TXT: "Inga varor funna",
  VENDOR_UNAVAILABLE_DATE_MSG:
    "Tyvärr är leverantören inte tillgänglig det valda datumet",
  LOCATION_UNAVAILABLE_TXT: "Område ej tillgängligt",
  VENDOR_DELIVERY_UNAVAILABLE_TXT:
    "Tyvärr levererar denna leverantör inte till detta område",
  PASSWORD_TXT: "Lösenord",
  FORGOT_PWD_TXT: "Glömt ditt lösenord",
  UPTO_TXT: "Upp till",
  LOGON_TITLE_TXT: "Logga in för att skapa ditt konto",
  START_YOUR_JOURNEY_TXT: "Börja din resa",
  WELCOME_INSTACATE_TXT: "Välkommen till Instacate",
  ACCOUNT_DOESNT_EXISTS_TXT: "Du har inget konto",
  SIGNUP_HELPER_TXT:
    "Genom att skapa ditt konto får du tillgång till massor av funktioner",
  ENTER_PWD_TXT: "Ange lösenord",
  CONFIRM_PWD_TXT: "Bekräfta lösenord",
  RE_ENTER_PWD_TXT: "Upprepa lösenord",
  SIGNUP_TXT: "Registrera",
  LOGIN_TXT: "Logga in",
  FORGOT_PWD_TXT: "Glömt lösenord",
  REQUEST_PWD_TXT: "Begär nytt lösenord",
  REQUEST_SENT_TXT: "Begäran skickad",
  RESET_EMAIL_SENT_MSG:
    "Vi har mejlat dig. Vänligen följ länken för attåterställa ditt lösenord.",
  OKAY_TXT: "Okej",
  VERIFY_EMAIL_MSG:
    "Vi har skickat ett mail till dig. Vänligen följ länken för att bekräfta ditt konto.",
  GO_BACK_LOGIN_TXT: "Gå tillbaka till inloggning",
  IOS_DATE_ERROR: "Datum kan inte infalla tidigare än dagens datum",
  WHEN_ARE_YOU_GOING_TO_SEE_EACH_OTHER: "När ska ni ses?",
  WHERE_ARE_YOU_GOING_TO_SEE: "Var ska ni ses?",
  HOW_MANY_GUESTS_WILL_YOU_HAVE: "Hur många gäster blir ni?",
  PREFERENCES: "Preferenser",
  SERVICE_MODE: "Hur vill då få beställningen?",
  SEARCH_TXT: "Sök",
  MY_ORDERS: "Mina Beställningar",
  CURRENT: "Nuvarande",
  PAST: " Tidigare",
  ORDER: "Beställningar",
  MY_ORDERS_NO_ORDERS_YET: "Inga beställningar är ännu gjorda",
  MY_ORDERS_DESCRIPTION_1: "Du har inte gjort någon beställning ännu. Testa!",
  MY_ORDERS_DESCRIPTION_2: "och lägg din order nu!",
  NEXT: "NÄSTA",
  SELECT: "Välj",
  SELECT_LOCATION: "Välj plats",
  ORDER_DETAIL_TIME_PLACED: 'Tid för order',
  ORDER_DETAIL_BACK_TO_ORDERS: 'Back to orders',
  NO_OF_GUEST: "Antal personer som beställningen gäller",
  DO_YOU_REALLY_WANT_TO_REMOVE_ITEM: "Vill du ta bort varan?",
  HAVE_NOT_MADE_YOUR_CHOICE: "Ser ut som att du inte gjort något val ännu…",
  EATINGS_FOR_MEETINGS: "EATINGS FOR MEETINGS",
  INSPIRATION_FOR_GOOD_MEETINGS: "Inspiration för goda möten.",
  EVENT_LOCATION: "Plats",
  GUESTS_EXPECTED: "Antal förväntade personer",
  LOGOUT: "LOGGA UT",
  FROM: "från",
  PAYMENT_SUCCESS: "Betalningen genomfördes",
  PAYMENT_IS_PROCESSING: "Din betalning genomförs.",
  PAYMENT_WAS_NOT_SUCCESSFUL: "Ojdå! Din betalning gick inte genom, vänligen prova igen.",
  UNEXPECTED_ERROR: "Det skedde ett oväntat fel.",
  FILL_OUT_THIS_FIELD: "Vänligen fyll i detta fält.",
  ENTER_VALID_EMAIL: "Vänligen ange en giltig e-postadress.",
  EMAIL_ALREADY_EXISTS: "Denna e-postadress används redan.",
  USER_NOT_VERIFIED: "Användare är inte bekräftad.",
  ACCOUNT_PWD_INCORRECT: "Ditt användarnamn eller lösenord är felaktigt.",
  PASSWORD_LENGTH_TXT: "Lösenordet måste bestå av minst 6 tecken.",
  PASSWORD_MISMATCH: "Lösenord matchar inte.",
  AGREE_TNC_TXT: "Vänligen godkänn villkoren.",
  EMAIL_NOT_REGISTERED: "Denna e-postadress tillhör inte en registrerad användare.",
  NO_RESTAURANTS_FOUND: "No restaurants founds",
  TRY_DIFFERENT_RESTAURANT: "Try a different keyword, or another filter option.",
  PACKAGES_TXT: "Paket",
  PRICE_TXT: "Pris",
  QUANTITY_SHORT: "Antal",
  CART_LINE_TOTAL: "Summa",
  DRAFT_ORDER_PRINTED_ON: "Utkast för din order utskiven den",
  DEAR_TEXT: "Bästa",
  WE_ARE_ALMOST_DONE: "Vi är nu nästan i mål med din beställning! Vår smidiga, snabba och säkra tjänst för catering till alla möten är bara ett klick bort!",
  BY_CONFIRMING: <>Genom att klicka på “Betala” bekräftar jag att jag har läst vår dataskyddsinformation och <a href='/privacy-policy' target="_blank">cookiepolicy</a>. Jag godkänner <a href="/terms-and-conditions" target="_blank">villkoren</a> för Instacate&nbsp;AB.</>,
  ORDER_NO: "order no",
  YOU_WILL_ALLOW_INSTACATE: "you allow Instacate AB to charge your card for this payment in accordance with terms.",
  INSTACATE_AB_TXT: "Instacate AB",
  OSTERGARDSVAGEN_TXT: "Ostergårdsvägen 10",
  STREET_ADDRESS: "163 54 Spånga, Stockholm län",
  INFO_EMAIL: "info@instacate.se",
  CONTACT_NUMBER: "08-222 500"
};
