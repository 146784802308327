import React from "react";
import SeeFullMenu from '../../Assets/Images/full_menu.svg';
import PeopleIcon from '../../Assets/Images/people.svg';
import PriceIcon from '../../Assets/Images/price.svg'
import Rectangle from '../../Assets/Images/Rectangle.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from "react-router";
import '../../Assets/Styles/vendor-list.css';
import { CONSTANTS } from "../../Helpers/Constants";

export default function VendorDetailCard({ vendors }) {
    const history = useHistory()
    
    const vendorDetail=(vendor)=>{
        localStorage.setItem('vendor',JSON.stringify(vendor));
        history.push('/vendor/packages')
    }
    return(
        vendors?.length &&
        vendors.map((vendor,i) => {
        return (
                <>
                <div className="main-content" key={`div-vendor-${i}`}>
                    <div className="vendor-card" key={`card-vendor-${i}`}>
                        <Row>
                            <Col sm={12} md={6} className="col-one">
                                <div className="card">
                                    <img src={vendor?.logoImageUrl} alt={'vendor?.logoImageUrl'} className="vendor-image" />
                                    <img src={Rectangle} className="overlay" />
                                    <label className="label-people"><img src={PeopleIcon} />{`${CONSTANTS.UPTO_TXT} ${vendor?.maximumOrderCapacity}`}</label>
                                    <label className="label-price">
                                    {`${vendor?.priceMin} ${CONSTANTS.KRONE_TXT} - ${vendor?.priceMax} ${CONSTANTS.KRONE_TXT}`}</label>
                                    <div className="vendor-card-barrier">{vendor.name}</div>
                                    <div className="vendor-details">
                                        <p>{vendor?.description}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={6} className="col-two">
                                <div className="strip-line"></div>
                                <div className="package">
                                    <img src={vendor?.packages[0]?.imageUrl} className="vendor-package-image" />
                                    <div className="package-info">
                                        <h4>{vendor?.packages[0]?.title}</h4>
                                        <p>{vendor?.packages[0]?.description}</p>
                                    </div>
                                    <div className="package-price">
                                        <h4>{`${vendor?.packages[0]?.pricePerUnitWithFeeAmount} ${CONSTANTS.KRONE_TXT}`}</h4>
                                        <span>{CONSTANTS.EXKL_MOMS_TXT}</span>
                                    </div>
                                </div>
                                {vendor?.packages?.length>1 && 
                                    <div className="package desktop-only">
                                        <img src={vendor?.packages[1]?.imageUrl} className="vendor-package-image" />
                                        <div className="package-info">
                                            <h4>{vendor?.packages[1]?.title}</h4>
                                            <p>{vendor?.packages[1]?.description}</p>
                                        </div>
                                        <div className="package-price">
                                            <h4>{`${vendor?.packages[1]?.pricePerUnitWithFeeAmount} ${CONSTANTS.KRONE_TXT}`}</h4>
                                            <span>{CONSTANTS.EXKL_MOMS_TXT}</span>
                                        </div>
                                    </div>
                                }
                                <button className="vendor-menu" onClick={()=>vendorDetail(vendor)}>{CONSTANTS.SEE_FULL_MENU_TXT}&nbsp;&nbsp;<img src={SeeFullMenu}/></button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="main-hr"></div>
                </>
            )
        })
    )
    
}