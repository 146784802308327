
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CONSTANTS } from '../../Helpers/Constants';
import '../../Assets/Styles/cart-error-modal.css'

export default function CartErrorModal({ error, callback }) {
  return (
    <>
      {error && (
        <Modal
        
        show={error !== {}}
        onHide={() => callback()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
        style={{ opacity: 1, }}
      >
        <Modal.Header closeButton className="cart-modal-head">
          {error?.title}
        </Modal.Header>
        <Modal.Body className="">
        {error?.image &&
        <Row className='cart-modal-body'>
            <Col xs={12} sm={ 12} md={ 12} lg={ 12}>
              <div className="">
                 <img src={error.image} alt="" />
              </div>
            </Col>
          </Row>
}
          <Row className='cart-modal-body'>
            <Col xs={12} sm={ 12} md={ 12} lg={ 12}>
              <div className="">
                <p>
                  {error?.body}
                  </p>
              </div>
            </Col>
          </Row>
          <Row >
            <Col className="col-12">
              <button
                className="modal-cancel-button submitbtn"
                onClick={() => callback()}
              >
                {CONSTANTS.CLOSE_TXT}
              </button>
            </Col>            
          </Row>
        </Modal.Body>
      </Modal>
        
      )}
      
    </>
  );
}
