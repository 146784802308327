import React, { forwardRef, useEffect } from "react";
import moment from "moment";
import LocationIcon from "../../Assets/Images/locations.svg";
import ParticipantIcon from "../../Assets/Images/participants.svg";
import arrowDown from '../../Assets/Images/arrow_down.svg';
import errorSign from '../../Assets/Images/alert.svg';
import DateTimePickersIcon from "../../Assets/Images/calender.svg";
import { useHistory } from "react-router";
import { LandingDateTimeIcon } from "../../Helpers/Handlers";
import "../../Assets/Styles/date.css";
import { isIOS } from "react-device-detect";
import { CONSTANTS } from "../../Helpers/Constants";
import '../../Assets/Styles/landing_page.css';
// import DatePicker from "../DatePicker";
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import "react-datepicker/dist/react-datepicker.css"

export default function Carousel({
  carousalForm,
  locations,
  participants,
  changeHandler,
  date, setDate, guestsCount,
  setGuestsCount, location, setLocation
}) {
  const history = useHistory();
  const [errorToShow, setErrorToShow] = React.useState(false);
  const [errorDisplay, setErrorDisplay] = React.useState('');
  const [showLibraryPicker, setShowLibraryPicker] = React.useState(false);
  const submitForm = (evt) => {
    evt.preventDefault();
    history.push("/vendors");
  };
  const todayDate = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16);
  const _date = localStorage.getItem('eventDate') ? moment(localStorage.getItem('eventDate')).format('YYYY-MM-DDTHH:mm') : "";
  const [startDate, setStartDate] = React.useState(_date ? new Date(_date) : false);
  // const pickerOpen = React.useRef(null);
  const [pickerOpened, setPickerOpened] = React.useState(false);

  useEffect(() => {
    let isFirefox = typeof InstallTrigger !== 'undefined';
    let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    let iPhone = window.navigator.userAgent.match(/iPhone/i);
    let iPad = window.navigator.userAgent.match(/iPad/i);
    let Safari = window.navigator.userAgent.match(/Safari/i);
    if (isFirefox || isSafari || Safari || iPhone || iPad) {
      setShowLibraryPicker(true);
    } else {
      setShowLibraryPicker(false)
    }

  }, [])

  const changeDatePicker = (e) => {
    let date = moment(e).format('YYYY-MM-DDTHH:mm')
    if (date > todayDate) {
      setDate(date);
      changeHandler("eventDate", date)
      setErrorToShow(false);
      setErrorDisplay('');
    }
    else {
      setDate('');
      changeHandler("eventDate", '')
      setTimeout(() => {
        setErrorToShow(true);
        setErrorDisplay(CONSTANTS.IOS_DATE_ERROR);
      }, 1000)
    }
  }

  const changeHandlerDatePicker = (e) => {
    if (e.target.value > todayDate) {
      setDate(e.target.value);
      changeHandler("eventDate", e.target.value)
      setErrorToShow(false);
      setErrorDisplay('');
    }
    else {
      setDate('');
      changeHandler("eventDate", '')
      setTimeout(() => {
        setErrorToShow(true);
        setErrorDisplay(CONSTANTS.IOS_DATE_ERROR);
      }, 1000)
    }
  }

  const openDatePicker = () => {
    let datePicker = document.querySelector('#eventDate');
    datePicker.showPicker()
  }
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      className={errorToShow ? "carousel-input carousal-date-input-error" : "carousel-input carousal-date-input"}
      onClick={onClick}
      ref={ref}
      value={value}
      placeholder="yyyy-mm-dd --:--"
    />
  ))
  const current_env = process.env.REACT_APP_DATE_INPUT_TYPING_ENABLED;
  return (
    <>
      <div
        id="myCarousel"
        className="carousel slide main-carousel"
        data-interval="false"
        data-wrap="false"
      >
        <ol className="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
          <li data-target="#myCarousel" data-slide-to="2"></li>
        </ol>
        <h4>{CONSTANTS.EATINGS_FOR_MEETINGS_TXT}</h4>
        <div className="carousel-inner">
          <div className="item active carousel-content">
            <p className="carousel-tag">{CONSTANTS.WHEN_ARE_YOU_GOING_TO_SEE_EACH_OTHER}</p>
            <div className={isIOS ? "input-group safari-view mp-input" : 'input-group mp-input'}>
              <form onSubmit={(e) => e.preventDefault()}>
                {showLibraryPicker ?
                  <div className="picker-input-group">
                    <img src={DateTimePickersIcon} alt="icon" className={`${pickerOpened ? "icon-opened" : 'icon-closed'} input-icon dp-icon-lib`} />
                    <div className="answer-input-group"

                    >
                      <DatePicker
                        onCalendarClose={() => setPickerOpened(false)}
                        onCalendarOpen={() => setPickerOpened(true)}
                        selected={startDate}
                        showTimeSelect
                        timeFormat="HH:mm"
                        onChange={(date) => { setStartDate(date); changeDatePicker(date) }}
                        dateFormat="yyyy-MM-dd HH:mm"
                        required
                        name="eventDate"
                        minDate={new Date()}
                        excludeDates={[new Date()]}
                        locale="sv-SE"
                        defaultValue={_date}
                        customInput={<CustomInput
                          />}
                          timeIntervals={15}
                          />
                    </div>
                    <a className="" href="#myCarousel" data-slide="next">
                      <button type="submit" className={`${pickerOpened ? 'picker-opened': 'picker-closed'} carousel_button_lib`}>
                        {CONSTANTS.NEXT}
                      </button>
                    </a>
                  </div>
                  :
                  <div className="answer-input-group">
                    <div onClick={openDatePicker}>
                      <img src={DateTimePickersIcon} alt="icon" className="input-icon dp-icon" />
                      <input
                        placeholder="dd/mm/yyyy --:--"
                        type="datetime-local"
                        onKeyDown={current_env ? null : (e) => e.preventDefault()}
                        id="eventDate"
                        name="eventDate"
                        defaultValue={_date}
                        className={errorToShow ? "carousel-input carousal-date-input-error" : "carousel-input carousal-date-input"}
                        onChange={(e) => changeHandlerDatePicker(e)}
                        min={todayDate}
                        required
                      />
                    </div>
                    <a href="#myCarousel" data-slide="next">
                      <button type="submit" className="carousel_button">
                        {CONSTANTS.NEXT}
                      </button>
                    </a>
                  </div>
                }
              </form>
              {errorToShow && <div className="box arrow-bottom"><img src={errorSign} alt="errorSign" />&nbsp;&nbsp; {errorDisplay}</div>}
            </div>
          </div>
          {date === null || date === '' ? (
            ""
          ) : (
            <div className="item carousel-content">
              <p className="carousel-tag">{CONSTANTS.WHERE_ARE_YOU_GOING_TO_SEE}</p>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="answer-input-group">
                  <img src={LocationIcon} alt="icon" className="input-icon" />
                  <select
                    placeholder={CONSTANTS.EVENT_LOCATION}
                    name="eventLocation"
                    id="eventLocation"
                    className="carousel-input"
                    required
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value),
                        changeHandler("eventLocation", e.target.value)
                    }}
                  >
                    <option value="">{CONSTANTS.SELECT_LOCATION}</option>
                    {locations.map((item, index) => {
                      return (
                        <option id={`${item}_${index}`} key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <a href="#myCarousel" data-slide="next">
                    <button type="submit" className="carousel_button">
                      {CONSTANTS.NEXT}
                    </button>
                  </a>
                </div>
              </form>
            </div>
          )}
          {date === null || date === '' || location === '' ? (
            ""
          ) : (
            <div className="item carousel-content">
              <p className="carousel-tag">{CONSTANTS.HOW_MANY_GUESTS_WILL_YOU_HAVE}</p>
              <div className="answer-input-group">
                <form onSubmit={(e) => { submitForm(e); }} >
                  <div className="answer-input-group">
                    <img src={ParticipantIcon} alt="icon" className="input-icon" />
                    <select
                      placeholder={CONSTANTS.GUESTS_EXPECTED}
                      name="noOfGuests"
                      id="noOfGuests"
                      className="carousel-input"
                      required={true}
                      value={guestsCount}
                      onChange={(e) => {
                        setGuestsCount(e.target.value),
                          changeHandler("noOfGuests", e.target.value)
                      }
                      }
                    >
                      <option>{CONSTANTS.SELECT}</option>
                      {participants.map((item, index) => {
                        return (
                          <option id={`${item}_${index}`} key={index} value={item.value}>
                            {item.key}
                          </option>
                        );
                      })}
                    </select>
                    <button type="submit" className="carousel_button">
                      {CONSTANTS.NEXT}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

