import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PrivacyPolicyScreen from "./Views/PrivacyPolicyScreen";

import '../../Assets/Styles/privacy-policy.css';

export default function PrivacyPolicy() {
    return(
        <div className="ic-wrapper">
            <Header />
            <PrivacyPolicyScreen />
            <Footer />
        </div>
    )
}