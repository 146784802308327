import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../Context/AppContext';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import OrderConfirmationScreen from './View/OrderConfirmationScreen';

export default function OrderConfirmation(){
    const history = useHistory();
    const { cartObj, setCartObj, setCateringMode, setDate, setGuestsCount, setLocation,setPreferences, setSuppliments } = useContext(AppContext);
    const [cart, setCart] = useState(cartObj)
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [order, setOrder] = useState(
        localStorage.getItem("order")
        ? JSON.parse(localStorage.getItem("order"))
        : false
    );
    const [result, setResult] = useState(
        localStorage.getItem("intent")
        ? JSON.parse(localStorage.getItem("intent"))
        : false
    );
    useEffect(() => {
        setCartObj([]);
        setCateringMode('delivery'), 
        setDate(''), 
        setGuestsCount(''), 
        setLocation(''),
        setPreferences([])

        localStorage.removeItem('eventDate');
        localStorage.removeItem('cateringMode');
        localStorage.removeItem('cart_supplement');
        localStorage.removeItem('order')
        localStorage.removeItem('eventLocation')
        localStorage.removeItem('cart')
        localStorage.removeItem('intent')
        localStorage.removeItem('vendor')
        localStorage.removeItem('noOfGuests')
        
        if (cartObj?.length ===0 ) history.push('/');
        setInitialLoad(false);
      }, []);
    
      useEffect(() => {
        (!cartObj || !order) && history.push('/');
      }, []);

    useEffect(()=>{
        if (!cartObj)
            history.push('/');
        },[])

    const callBack=()=>{
        setCateringMode('delivery');
        setCartObj([]);
        setDate(''), 
        setGuestsCount(''), 
        setLocation(''),
        setPreferences([])

        localStorage.removeItem('eventDate');
        localStorage.removeItem('order')
        localStorage.removeItem('eventLocation')
        localStorage.removeItem('cart')
        localStorage.removeItem('intent')
        localStorage.removeItem('vendor')
        localStorage.removeItem('noOfGuests')
        localStorage.removeItem('featuredVendorPackageId')
        
        setSuppliments(false);

       history.push('/')
    }
    return (
        <>
            <div className="ic-wrapper">
                <Header />
                <OrderConfirmationScreen 
                    history={history}
                    result={result}
                    order={order}
                    callBack={callBack}
                    cart={cart}
                />
                <Footer />
            </div>
        </>
    )
}