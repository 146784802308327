import { CONSTANTS } from "../../../Helpers/Constants"
import '../../../Assets/Styles/customer_faq.css'
import faqActive from '../../../Assets/Images/faq-inactive.png'
import faqInActive from '../../../Assets/Images/faq-active.png'

export default function FaqScreen({ footer, classToggle, faqData, questionOpened }) {
    return (
        <div className={`${footer && 'fit-footer'} faq-container`}>
            {!footer && <div className="faq-heading">
                <p>{CONSTANTS.FREQUENT_TXT}</p>
            </div>}
            {faqData && faqData.map((item, index) => {
                return (
                    <div key={index} className='faq-detail-container'>
                        {index === questionOpened ?
                            <img className="icon-image-active" src={faqActive} /> :
                            <img className="icon-image-inactive" src={faqInActive} />
                        }
                        <details id={`id + ${index}`}>
                            <summary onClick={() => { classToggle(index) }} className="faq-question">{item.question}</summary>
                            <p className="faq-answer">{item.answer}</p>
                        </details>
                        <hr className="faq-seperator" />
                    </div>
                )
            })}
        </div>

    )
}