import OrderDetail from './views/OrderDetail'
import '../../Assets/Styles/myorders.css'
import Loader from '../../Components/Loader'

export default function OrderDetailContainer({ myOrders, orderIndex, setShowDetail, orderDetail, loading }) {
    return (
        <div>
            <OrderDetail
                myOrders={myOrders}
                orderIndex={orderIndex}
                setShowDetail={setShowDetail}
                orderDetail={orderDetail}
                loading={loading}
            />
        </div>
    )
}