import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../Assets/Styles/cart-filled-dialog.css";
import { CONSTANTS } from "../../Helpers/Constants";

export default function ConfirmationDialog({
  openConfirmationDialog,
  setConfirmationDialog,
  callBack,
  confirmationMsg,
  cancelFunction,
}) {
  return (
    <Modal
      show={openConfirmationDialog}
      onHide={() => setConfirmationDialog(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      style={{ opacity: 1, }}
    >
      <Modal.Header className="filter-modal-head">
        {CONSTANTS.ARE_YOU_SURE_TXT}
      </Modal.Header>
      <Modal.Body className="">
        <Row className='mb7'>
          <Col xs={12} sm={ 12} md={ 12} lg={ 12}>
            <div className="">
              <span>
                {confirmationMsg}
                </span>
            </div>
          </Col>
        </Row>
        <Row className="margin-lr-0">
          <Col className="col-6">
            <button
              className="modal-cancel-button submitbtn"
              onClick={
                cancelFunction ? cancelFunction : 
                () => setConfirmationDialog(false)}
            >
              {CONSTANTS.NO_TXT}
            </button>
          </Col>
          <Col className="col-6">
            <button
              className="modal-button cancelBtn"
              onClick={() => callBack()}
            >
              {CONSTANTS.YES_TXT}
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
