import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Checkbox from '../Checkbox';
import TextBox from '../TextBox';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressLoader from '../ProgressLoader';
import LoginBackIcon from '../../Assets/Images/login_back_icon.svg';
import { emailValidation, startYourJourney, loginCustomer, signupCustomer, forgotPasswordCustomer } from '../../Helpers/Handlers';
import TickLoader from '../TickLoader';
import { CONSTANTS } from '../../Helpers/Constants';
import { useHistory } from 'react-router';

export default function LoginDialog({
    openLoginDialog,
    setOpenLoginDialog,
    customerLogin,
    setCustomerLogin,
    loginStep,
    setLoginStep,
    emailError,
    setEmailError,
    setCurrentLoginCustomer,
    emailErrorMessage,
    setEmailErrorMessage,
    authError,
    setAuthError,
    authErrormessage,
    setAuthErrorMessage,
    currentLocation
}) {
    const [progLoader, setPloader] = useState(false);
    const [disableField, setDisableField] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoginStep(1);
        setCustomerLogin({
            ...customerLogin,
            email: '',
            password: '',
            confirmPassword: '',
            agree_tnc: false
        })
    }, [openLoginDialog])

    useEffect(() => {
        setLoginStep(1);
    }, [openLoginDialog])

    const login = async (e) => {
        await loginCustomer(e, customerLogin, setOpenLoginDialog, setCurrentLoginCustomer, setAuthError, setAuthErrorMessage, setPloader, setDisableField);
    }

    const signup = async (e) => {
        await signupCustomer(e, customerLogin, setOpenLoginDialog, setCurrentLoginCustomer, setAuthError, setAuthErrorMessage, setPloader, setDisableField, setLoginStep);
    }

    const forgotPassword = async (e) => {
        await forgotPasswordCustomer(e, customerLogin, setLoginStep, setPloader, setDisableField, setAuthError, setAuthErrorMessage)
    }

    const backClick = () => {
        setAuthErrorMessage('');
        setLoginStep(1);
        setCustomerLogin({
            ...customerLogin,
            password: '',
            confirmPassword: '',
            agree_tnc: false
        })
    }

    const backClickForgotPassword = () => {
        setAuthErrorMessage('');
        setLoginStep(3);
        setCustomerLogin({
            ...customerLogin,
            password: '',
            confirmPassword: '',
            agree_tnc: false
        })
    }

    return (
        <>
            <Modal
                show={openLoginDialog}
                onHide={() => setOpenLoginDialog(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="sm"
                style={{ opacity: 1 }}
                backdrop={currentLocation === '/billing' ? "static" : true}
                keyboard={currentLocation === '/billing' ? false : true}
            >
                {loginStep === 1 ?
                    <>
                        <Modal.Header className="filter-modal-head">{CONSTANTS.LOGON_TITLE_TXT}</Modal.Header>
                        <Modal.Body>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                startYourJourney(customerLogin.email, setLoginStep, setEmailError, setEmailErrorMessage, setPloader, setDisableField);
                            }}>
                                <div className="main-login">
                                    <Row>
                                        <Col>
                                            <TextBox
                                                id={`${customerLogin.email}`}
                                                name={`${customerLogin.email}`}
                                                label={CONSTANTS.EMAIL_TXT}
                                                type="email"
                                                on_blur={() => emailValidation(customerLogin.email, setEmailError, setEmailErrorMessage)}
                                                value={customerLogin.email}
                                                class_name="full-width"
                                                onChange={(e) => setCustomerLogin({ ...customerLogin, email: e.target.value })}
                                                error={emailError}
                                                errorMessage={emailErrorMessage}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='col-6'>
                                            <div className="mt-5">
                                                <button
                                                    type="button"
                                                    className="filter-cancel-button"
                                                    onClick={() => { currentLocation === '/billing' ? history.push('/cart/detail') : setOpenLoginDialog(false) }}
                                                >
                                                    {CONSTANTS.CANCEL_TXT}
                                                </button>
                                            </div>
                                        </Col>
                                        <Col className='col-6'>
                                            <div className="mt-5">
                                                <button
                                                    type="submit"
                                                    className="filter-button"
                                                    disabled={customerLogin.email === "" || emailError || disableField}
                                                >
                                                    {progLoader ? <ProgressLoader /> : CONSTANTS.START_YOUR_JOURNEY_TXT}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        </Modal.Body>
                    </>
                    :
                    loginStep === 2 ?
                        // Sign UP 
                        <>
                            <Modal.Header className="filter-modal-head">
                                <span onClick={() => backClick()}><img src={LoginBackIcon} className="login_back" /></span>
                                {CONSTANTS.WELCOME_INSTACATE_TXT}
                            </Modal.Header>
                            <Modal.Body>
                                <div className="main-login">
                                    <Row>
                                        <Col>
                                            <label>
                                                {CONSTANTS.ACCOUNT_DOESNT_EXISTS_TXT} <br />
                                                {CONSTANTS.SIGNUP_HELPER_TXT}
                                            </label>
                                        </Col>
                                    </Row>
                                    <form onSubmit={(e) => { e.preventDefault(); signup(e); }}>
                                        <Row>
                                            <Col>
                                                <TextBox
                                                    id={`${customerLogin.email}`}
                                                    name={`${customerLogin.email}`}
                                                    label={CONSTANTS.EMAIL_TXT}
                                                    on_blur={() => emailValidation(customerLogin.email, setEmailError)}
                                                    value={customerLogin.email}
                                                    class_name="full-width"
                                                    onChange={(e) => setCustomerLogin({ ...customerLogin, email: e.target.value })}
                                                    error={emailError}
                                                    errorMessage={CONSTANTS.INVALID_EMAIL_ERROR}
                                                    readOnly="readOnly"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextBox
                                                    id={`${customerLogin.password}`}
                                                    name={`${customerLogin.password}`}
                                                    label={CONSTANTS.PASSWORD_TXT}
                                                    type="password"
                                                    placeholder={CONSTANTS.ENTER_PWD_TXT}
                                                    value={customerLogin.password}
                                                    class_name="full-width"
                                                    onChange={(e) => setCustomerLogin({ ...customerLogin, password: e.target.value })}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextBox
                                                    id={`${customerLogin.confirmPassword}`}
                                                    name={`${customerLogin.confirmPassword}`}
                                                    label={CONSTANTS.CONFIRM_PWD_TXT}
                                                    type="password"
                                                    placeholder={CONSTANTS.RE_ENTER_PWD_TXT}
                                                    value={customerLogin.confirmPassword}
                                                    class_name="full-width"
                                                    onChange={(e) => setCustomerLogin({ ...customerLogin, confirmPassword: e.target.value })}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mt-5">
                                                    <div className="billing-input-checkbox">
                                                        <label className="container">{CONSTANTS.TERM_AND_CONDITIONS_TXT}
                                                            <input type="checkbox" id={`${customerLogin.agree_tnc}`} name={`${customerLogin.agree_tnc}`} value={customerLogin.agree_tnc} onChange={(e) => setCustomerLogin({ ...customerLogin, agree_tnc: e.target.checked })} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="mt-5">
                                                    {authError ? <div className='error-message'>{authErrormessage}</div> : ''}
                                                    <button
                                                        type="submit"
                                                        className="filter-button"
                                                        disabled={disableField}
                                                    >
                                                        {progLoader ? <ProgressLoader /> : CONSTANTS.SIGNUP_TXT}
                                                    </button>
                                                </div>

                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </Modal.Body>
                        </>
                        :
                        loginStep === 3 ?
                            // Log In
                            <>
                                <Modal.Header className="filter-modal-head">
                                    <span onClick={() => backClick()}><img src={LoginBackIcon} className="login_back" /></span>
                                    {CONSTANTS.WELCOME_INSTACATE_TXT}
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="main-login">
                                        <form onSubmit={(e) => { e.preventDefault(); login(e); }}>
                                            <Row>
                                                <Col>
                                                    <TextBox
                                                        id={`${customerLogin.email}`}
                                                        name={`${customerLogin.email}`}
                                                        label={CONSTANTS.EMAIL_TXT}
                                                        on_blur={() => emailValidation(customerLogin.email, setEmailError)}
                                                        value={customerLogin.email}
                                                        class_name="full-width"
                                                        onChange={(e) => setCustomerLogin({ ...customerLogin, email: e.target.value })}
                                                        error={emailError}
                                                        errorMessage={CONSTANTS.INVALID_EMAIL_ERROR}
                                                        readOnly='readOnly'
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <TextBox
                                                        id={`${customerLogin.password}`}
                                                        name={`${customerLogin.password}`}
                                                        label={CONSTANTS.PASSWORD_TXT}
                                                        type="password"
                                                        placeholder={CONSTANTS.ENTER_PWD_TXT}
                                                        value={customerLogin.password}
                                                        class_name="full-width"
                                                        onChange={(e) => setCustomerLogin({ ...customerLogin, password: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="mt-5">
                                                        {authError ? <div className='error-message'>{authErrormessage}</div> : ''}
                                                        <button
                                                            type="submit"
                                                            className="filter-button"
                                                            disabled={customerLogin.email === "" || customerLogin.password === "" || disableField}
                                                        // onClick={(e) => login(e)}
                                                        >
                                                            {progLoader ? <ProgressLoader /> : CONSTANTS.LOGIN_TXT}
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="forgot-password">
                                                        <a onClick={() => { setAuthError(false); setLoginStep(4); }}>{`${CONSTANTS.FORGOT_PWD_TXT}?`}</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </Modal.Body>
                            </>
                            :
                            loginStep === 4 ?
                                // Forgot Password
                                <>
                                    <Modal.Header className="filter-modal-head">
                                        <span onClick={() => backClickForgotPassword()}><img src={LoginBackIcon} className="login_back" /></span>
                                        {CONSTANTS.FORGOT_PWD_TXT}
                                    </Modal.Header>
                                    <Modal.Body>
                                        <form onSubmit={(e) => { e.preventDefault(); forgotPassword(e); }}>
                                            <div className="main-login">
                                                <Row>
                                                    <Col>
                                                        <TextBox
                                                            id={`${customerLogin.email}`}
                                                            name={`${customerLogin.email}`}
                                                            label={CONSTANTS.EMAIL_TXT}
                                                            type="email"
                                                            on_blur={() => emailValidation(customerLogin.email, setEmailError, setEmailErrorMessage)}
                                                            value={customerLogin.email}
                                                            class_name="full-width"
                                                            onChange={(e) => {
                                                                setCustomerLogin({ ...customerLogin, email: e.target.value });
                                                                emailValidation(customerLogin.email, setEmailError, setEmailErrorMessage);
                                                            }}
                                                            error={emailError}
                                                            errorMessage={emailErrorMessage}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="mt-5">
                                                            {authError ? <div className='error-message'>{authErrormessage}</div> : ''}
                                                            <button
                                                                type="submit"
                                                                className="filter-button"
                                                                disabled={customerLogin.email === "" || emailError || disableField}
                                                            >
                                                                {progLoader ? <ProgressLoader /> : CONSTANTS.REQUEST_PWD_TXT}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </form>
                                    </Modal.Body>
                                </>
                                :
                                loginStep === 5 ?
                                    // Confirmation Dialog
                                    <>
                                        <Modal.Header className="filter-modal-head">
                                            {CONSTANTS.REQUEST_SENT_TXT}
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                setLoginStep(3);
                                            }}>
                                                <div className="main-login">
                                                    <Row>
                                                        <Col>
                                                            <div className="tick-loader">
                                                                <TickLoader />
                                                                <p>{CONSTANTS.RESET_EMAIL_SENT_MSG}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="mt-5">
                                                                <button
                                                                    type="submit"
                                                                    className="filter-button"
                                                                    disabled={customerLogin.email === "" || emailError || disableField}
                                                                >
                                                                    {CONSTANTS.LOGIN_TXT}
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </form>
                                        </Modal.Body>
                                    </>
                                    :
                                    loginStep === 6 ?
                                        // Confirmation Code
                                        <>
                                            <Modal.Header className="filter-modal-head">
                                                {CONSTANTS.WELCOME_INSTACATE_TXT}
                                            </Modal.Header>
                                            <Modal.Body>
                                                <form onSubmit={(e) => { e.preventDefault(); setLoginStep(3); setCustomerLogin({ ...customerLogin, password: '', confirmPassword: '' }); }}>
                                                    <div className="main-login">
                                                        <Row>
                                                            <Col>
                                                                <div className="tick-loader">
                                                                    <TickLoader />
                                                                    <p>{CONSTANTS.VERIFY_EMAIL_MSG}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="mt-5">
                                                                    <button
                                                                        type="submit"
                                                                        className="filter-button"
                                                                        disabled={customerLogin.email === "" || emailError}
                                                                    >
                                                                        {CONSTANTS.GO_BACK_LOGIN_TXT}
                                                                    </button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </form>
                                            </Modal.Body>
                                        </>
                                        : ''
                }
            </Modal>
        </>
    )
}