import React, { useState, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import calendarFilledImg from "../../Assets/Images/ic_fh_calendar.svg";
import locationFilledImg from "../../Assets/Images/ic_fh_location.svg";
import peoplesFilledImg from "../../Assets/Images/ic_fh_people.svg";
import timeIcon from '../../Assets/Images/ic_fh_time.svg';
import { AppContext } from '../../Context/AppContext';
import moment from "moment";
import 'moment/locale/sv'
import '../../Assets/Styles/filter_header.css';
import { CONSTANTS } from "../../Helpers/Constants";

export default function Filters() {
  const { setOpenFilterDialog, guestsCount, setGuestsCount, location,
    date } = useContext(AppContext);
  // const [date, setDate] = useState(localStorage.getItem("eventDate") ? new Date(localStorage.getItem("eventDate")) : "");
  // const [guestsCount, setGuestsCount] = useState(
  //   localStorage.getItem("noOfGuests") || ""
  // );
  // const [location, setLocation] = useState(
  //   localStorage.getItem("eventLocation") || ""
  // );
  const _date = localStorage.getItem('eventDate');
  const preferences = localStorage.getItem('cateringMode') || 'delivery';
  const eventDate = _date && moment(_date).locale("sv").format('YYYY MMMM DD')
  const eventTime = _date && moment(_date).format('HH.mm')
  let tags = JSON.parse(localStorage.getItem('preferences'));
  tags = tags?.length && tags.filter(item => item)
  return (
    <>
      <div className="filter-header">
        <Row>
          <Col sm={11} lg={11} className="filter-header-col">
            <Col>
              <div className="filter-header-col-one clickable"
                onClick={() => setOpenFilterDialog(true)}
              >
                <div className="filter-header-col-sub-one">
                  <img src={calendarFilledImg} alt="" width='18px' height='18px' style={{ marginBottom: '0.5rem' }}></img>
                  <span> {eventDate}</span>
                </div>
                <div className="filter-header-col-sub-one" >
                  <img src={timeIcon} alt="" width='18px' height='18px' style={{ marginBottom: '0.5rem' }}></img>
                  <span> {eventTime}</span>
                </div>
              </div>
            </Col>
            <Col>
              <div
                onClick={() => setOpenFilterDialog(true)}
                className="filter-header-col-two clickable">
                <div className="filter-header-col-sub-two">
                  <img src={locationFilledImg} alt="" width='18px' height='18px' style={{ marginBottom: '0.5rem' }}></img>
                  <span> {location}</span>
                </div>
              </div>
            </Col>
            <Col>
              <div
                onClick={() => setOpenFilterDialog(true)}
                className="filter-header-col-two clickable">
                <div className="filter-header-col-sub-two-one">
                  <img src={peoplesFilledImg} alt="" width='18px' height='18px' style={{ marginBottom: '0.5rem' }}></img>
                  <span>{!isNaN(guestsCount) && `${CONSTANTS.UPTO_TXT} ${guestsCount}`}</span>
                </div>
              </div>
            </Col>
          </Col>
          <Col sm={1} lg={1} className="filter-button-col text-right">
            <button className="filter-head-button" onClick={() => setOpenFilterDialog(true)}>
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="row-second">
              <div className="filter-prefernces-tags clickable"
                onClick={() => setOpenFilterDialog(true)}
              >
                <span>{preferences.toLowerCase() === 'pickup' ? CONSTANTS.PICKUP_TXT : CONSTANTS.DELIVERY_TXT}</span>
              </div>
              {tags?.length > 0 && tags.map(tag => {
                return (
                  <div className="filter-tags">
                    <span>{tag.label}</span>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
