import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Filters from "../../Components/Filters";
import React, { useState, useEffect, useContext } from "react";
import { VendersAPI } from "../../Services/ExploreVendors";
import { AppContext } from "../../Context/AppContext";
import Loader from "../../Components/Loader";
import VendorDetailCard from "../../Components/VendorDetailCard";
import FilterDialog from "../../Components/FilterDialog";
import json from "../../Assets/JSON/landing-page.json";
import { addArrayInDB, selectOptions, toIsoString } from "../../Helpers/Handlers";
import ErrorDisplay from "../../Components/VendorError";
import { useLocation } from "react-router";
import emptyVaseImg from "../../Assets/Images/empty-vase.svg";
import { CONSTANTS } from "../../Helpers/Constants";

export default function VendorsList() {
  const { carousalForm, setCarousalForm, openFilterDialog, setOpenFilterDialog, setShowCartModal, setSystemConfig, guestsCount,
    setGuestsCount,
    location,
    date,
    cateringMode, preferences, setPreferences, setCateringMode } =
    useContext(AppContext);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [error, setError] = useState({});
  const [pager, setPager] = useState({});
  const [footer, setFooter] = useState(true)
  const [serviceMode, setServiceMode] = useState(cateringMode);
  const [tags, setTags] = useState([]);

  /*This Effect will load the component initially */
  useEffect(() => {
    fetchSystemConfig();
  }, []);

  /*This Effect will observe changes in filters and fetches vendors simultaneously */
  useEffect(() => {
    fetchVendors();
  }, [cateringMode, guestsCount, date, location]);

  useEffect(() => {
    setOpenFilterDialog(false);
    setShowCartModal(false);
  }, [pathname])

  const fetchSystemConfig = async () => {
    const _system_config = await VendersAPI.getSystemConfig();
    setSystemConfig(_system_config?.data);
    const optionsTags = selectOptions(_system_config?.data?.tags);
    setTags(optionsTags);
  };

  const fetchVendors = async () => {
    if (location === "" || date === "") {
      setError({
        imageUrl: emptyVaseImg,
        title: CONSTANTS.DATE_LOCATION_MISSING_ERROR,
        text: CONSTANTS.EVENT_DETAILS_MISSING_TXT,
      });
      return;
    }
    const tagsToUpdate = await addArrayInDB(preferences);
    setLoading(true);
    await VendersAPI.getVendors({
      eventDate: toIsoString(new Date(date)),
      location: location.toLowerCase(),
      tags: tagsToUpdate,
      serviceMode: cateringMode,
      capacity: parseInt(guestsCount),
      index: 1,
      pageSize: 50,
      vendorId: "",
    })
      .then((result) => {
        setLoading(false);
        setFooter(false)
        if (result?.status === "error") {
          const error = result?.data?.errors?.[0] || null;
          setVendors("");
          setError({
            imageUrl: error?.imageLink,
            title: error?.message,
            text: error?.title,
          });
        } else {
          setVendors(result?.data?.vendors);
          setPager(result?.data?.meta);
          setError("");
        }
      })
      .catch((error) => {
        setLoading(false);
        // setFooter(true)
        // console.log("items catch: ", error);
      });
  };

  const changeHandler = (setterFn, field, value) => {
    setCarousalForm({
      ...carousalForm,
      [field]: value,
    });
    const setter = field;
    setterFn(value)
    localStorage.setItem(field, value);
  };

  return (
    <>
      <div className="ic-wrapper">
        {openFilterDialog && (
          <FilterDialog
            locations={json.locations}
            participants={json.participant}
            changeHandler={changeHandler}
            fetchVendors={fetchVendors}
            serviceMode={cateringMode}
            setServiceMode={setCateringMode}
            prefrences={preferences}
            setPreferences={setPreferences}
            tags={tags}
            pathname={pathname}
          />
        )}
        <Header />
        <Filters />
        {loading &&
          <div className="loaderParent">
            <Loader />
          </div>}
        <div>
          {error &&
            <div className="vendorError">
              <ErrorDisplay error={error}
                fixFooter={footer}
              />
            </div>
          }
          <div className="">
            {vendors?.length > 0 && <VendorDetailCard vendors={vendors}
              fixFooter={footer}
            />}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
