import FilterDateTime from '../Assets/Images/filter_datetime.svg';
import CalendarIcon from '../Assets/Images/calendar_icon.png';
import { collection, query, where, getDocs, setDoc, doc, getDoc } from "firebase/firestore";
import { db } from '../Firebase/Config';
import { loginAccount, createAccount, forget_password, sendCustomerEmailVerification } from '../Firebase/AuthFunctions';
import { CONSTANTS } from './Constants';

export const FilterDateTimeIcon = () => {
    return <img src={FilterDateTime} />
}

export const secondstoDateTime = (secs) => {
    var t = new Date(1970, 0, 1);
    t.setSeconds(secs);
    return t;
}

export const LandingDateTimeIcon = () => {
    return <img src={CalendarIcon} />
}

export const selectOptions = (value) => {
    let _value = [], updatedValue = [];
    if (Array.isArray(value)) {
        _value = value
    }
    else {
        value && _value.push(value);
    }
    _value.map((data) => {
        updatedValue.push({ 'label': data, 'value': data })
    })
    return updatedValue
}

export const addArrayInDB = async (valueArray) => {
    let updatedValueArray = [];
    Array.isArray(valueArray) && valueArray?.length && valueArray.map((item) => {
        updatedValueArray.push(item.value);
    })
    return updatedValueArray;
}

export const isEmpty = async (value) => {
    if (value === '' || value === [] || value === undefined || value.length === 0 || value === null) {
        return true;
    }
    else return false;
}

export const getUserData = async (userID) => {
    const q = doc(db, "customer_users", userID);
    const querySnapshot = await getDoc(q);
    const tempData = querySnapshot.data();
    return tempData
}

export function AsCurrency(number) {
    return new Number(number).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
}

export function flushLocalState() {
    localStorage.removeItem('cart_location');
    localStorage.removeItem('cart_supplement');
    localStorage.removeItem('selected_vendor')
    localStorage.removeItem('cart_date');
    localStorage.removeItem('cart_cateringMode');
    localStorage.removeItem('cart_guests')
}

export const emailValidation = (value, setEmailError, setEmailErrorMessage) => {
    const check = value
    let regex = /^([a-zA-Z\d\.\-_]+)@([a-zA-Z\d\.\-_]+)\.([a-zA-Z]){2,7}$/
    if (regex.test(check) === false) {
        setEmailError(true);
        setEmailErrorMessage(CONSTANTS.ENTER_VALID_EMAIL)
        return false
    } else {
        setEmailError(false)
    }
}

export const validateFirebaseUser = async (input) => {
    const validateAdminUsers = query(collection(db, "admin_users"), where("emailAddress", "==", input));
    let validateAdminData = []
    const querySnapshot = await getDocs(validateAdminUsers);
    querySnapshot.forEach((doc) => {
        validateAdminData.push({
            id: doc.id,
            ...doc.data()
        })
    });
    const validateVendorUsers = query(collection(db, "vendor_users"), where("emailAddress", "==", input));
    let validateVendorData = [];
    const _querySnapshot = await getDocs(validateVendorUsers);
    _querySnapshot.forEach((doc) => {
        validateVendorData.push({
            id: doc.id,
            ...doc.data()
        })
    });
    if (validateAdminData.length > 0) return validateAdminData;
    else if (validateVendorData.length > 0) return validateVendorData;
}

export const emailQuery = async (input) => {
    const q = query(collection(db, "customer_users"), where("emailAddress", "==", input));
    let tempData = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        tempData.push({
            id: doc.id,
            ...doc.data()
        })
    });
    return tempData
}

export const startYourJourney = async (email, setLoginStep, setEmailError, setEmailErrorMessage, setPloader, setDisableField) => {
    setPloader(true);
    setDisableField(true);
    const verifyEmailAsFirebaseUser = await validateFirebaseUser(email);
    const userExist = await isEmpty(verifyEmailAsFirebaseUser);
    if (userExist === false) {
        setEmailError(true);
        setPloader(false);
        setDisableField(false);
        setEmailErrorMessage(CONSTANTS.EMAIL_ALREADY_EXIST)
    }
    else if (userExist === true) {
        setEmailError(false);
        setPloader(false);
        setDisableField(false);
        const checkEmail = await emailQuery(email);
        if (checkEmail.length === 0) {
            setLoginStep(2)
        }
        else if (checkEmail.length === 1) {
            setLoginStep(3)
        }
    }
}

export const loginCustomer = async (e, customerData, setOpenLoginDialog, setCurrentLoginCustomer, setAuthError, setAuthErrorMessage, setPloader, setDisableField) => {
    e.preventDefault();
    try {
        setAuthError(false);
        setDisableField(true);
        setPloader(true);
        setAuthErrorMessage('');
        const userCredential = await loginAccount(customerData.email, customerData.password);
        const userDetails = await getUserData(userCredential?.user.uid);
        const status = userCredential.user.emailVerified;
        const verificationStatus = userDetails.status;
        if (status === true) {
            if (verificationStatus === 'enabled') {
                const user = {
                    userId: userCredential.user.uid,
                    idToken: userCredential._tokenResponse.idToken,
                    refreshToken: userCredential._tokenResponse.refreshToken
                }
                window.location.reload();
                setPloader(false);
                setDisableField(false)
                localStorage.setItem('user', JSON.stringify(user));
                setCurrentLoginCustomer(user);
                setOpenLoginDialog(false);
            }
            else if (verificationStatus === 'disabled') {
                setPloader(false);
                setDisableField(false);
                setAuthError(true);
                setAuthErrorMessage(CONSTANTS.ACCOUNT_PWD_INCORRECT);
            }
        }
        else if (status === false) {
            setPloader(false);
            setDisableField(false);
            setAuthError(true);
            setAuthErrorMessage(CONSTANTS.USER_NOT_VERIFIED);
        }
    }
    catch (err) {
        const errorCode = err.code;
        setPloader(false);
        setDisableField(false)
        if (errorCode === 'auth/wrong-password') {
            setAuthError(true);
            setAuthErrorMessage(CONSTANTS.ACCOUNT_PWD_INCORRECT);
        }
        else {
            setAuthError(true);
            setAuthErrorMessage(errorCode);
        }
    }
}

export default function validateSignupForm(customerData) {
    if (customerData.password.length < 6) {
        return { error: true, message: CONSTANTS.PASSWORD_LENGTH_TXT }
    }
    else if (customerData.password !== customerData.confirmPassword) {
        return { error: true, message: CONSTANTS.PASSWORD_MISMATCH }
    }
    else if (customerData.agree_tnc === false) {
        return { error: true, message: CONSTANTS.AGREE_TNC_TXT }
    }
    return { error: false, message: "" }
}

export const signupCustomer = async (e, customerLogin, setOpenLoginDialog, setCurrentLoginCustomer, setAuthError, setAuthErrorMessage, setPloader, setDisableField, setLoginStep) => {
    e.preventDefault();
    setPloader(true);
    setDisableField(true);
    const { error, message } = validateSignupForm(customerLogin);
    if (error && message) {
        setPloader(false);
        setDisableField(false);
        setAuthError(true);
        setAuthErrorMessage(message);
    }
    else {
        setAuthError(false);
        setAuthErrorMessage('');
        try {
            const userCredential = await createAccount(customerLogin.email, customerLogin.password);
            await verificationEmail();
            const user = userCredential.user;
            const data = {
                address: "",
                city: "",
                company: "",
                contactNumber: "",
                createdOn: new Date(),
                emailAddress: customerLogin.email,
                isActive: true,
                modifiedById: user.uid,
                modifiedOn: new Date(),
                name: "",
                organizationNumber: "",
                postalCode: "",
                status: "enabled"
            }
            await setDoc(doc(db, "customer_users", user.uid), { ...data });
            setPloader(false);
            setDisableField(false);
            setLoginStep(6);
        }
        catch (err) {
            let message = err.message.split("Firebase: ");
            setPloader(false);
            setDisableField(false);
            setAuthError(true);
            setAuthErrorMessage(message[1]);
        }
    }
}

export const toIsoString = (date) => {
    if (date) {
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(tzo / 60) +
            ':' + pad(tzo % 60);
    }
    else return date;
}

export const forgotPasswordCustomer = async (e, customerLogin, setLoginStep, setPloader, setDisableField, setAuthError, setAuthErrorMessage) => {
    e.preventDefault();
    setPloader(true);
    setDisableField(true);
    const checkEmail = await emailQuery(customerLogin.email);
    if (checkEmail.length === 1) {
        setAuthError(false);
        setAuthErrorMessage('');
        await forget_password(customerLogin.email);
        setPloader(false);
        setDisableField(false);
        setLoginStep(5);
    }
    else if (checkEmail.length === 0) {
        setPloader(false);
        setDisableField(false);
        setAuthError(true);
        setAuthErrorMessage(CONSTANTS.EMAIL_NOT_REGISTERED);
    }
}

export const verificationEmail = async () => {
    await sendCustomerEmailVerification();
}