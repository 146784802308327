import React from 'react';
import Lottie from 'lottie-react-web';
import progressLoader from '../../Assets/Animation/p_loader.json';

export default function ProgressLoader(){

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: progressLoader,
      };

    return(
        <>
            <div className="_loader">
                <Lottie
                    width = { 150 }
                    height = { 150 }
                    options = {defaultOptions}
                />
            </div>
        </>
    )
}