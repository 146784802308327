import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import 'moment/locale/sv'
import ConfirmationTick from '../../../Assets/Images/confirmation_tick.svg';
import '../../../Assets/Styles/order-confirmation.css';
import CartDetailsComponent from "../../../Components/CartDetails";
import { CONSTANTS } from "../../../Helpers/Constants";

export default function OrderDetail({
    history,
    result,
    order,
    callBack,
    myOrders,
    orderIndex,
    setShowDetail,
    orderDetail,
    loading
}) {
    const orderDetails = myOrders[orderIndex]
    const orderDate = moment(new Date(orderDetails.createdOn._seconds * 1000)).locale("sv").format("YYYY MMMM DD HH.mm");
    return (
        <>
            <Row className={loading && "fit-footer"}>
                <Col>
                    <div className="first-confirmation-part">
                        <div className="first-part">
                            <div>
                                <h2>{CONSTANTS.ORDER} # {orderDetails?.orderId}</h2>
                                <p className={`${orderDetails?.status === "accepted" ? 'status-accepted' : 'status-other'}`}>{orderDetails?.status.toLocaleUpperCase()}</p>
                                <p>{CONSTANTS.ORDER_DETAIL_TIME_PLACED}: {orderDate}</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {!loading && <div className="second-confirmation-part">
                        <h5><b>{CONSTANTS.BILIING_TXT}</b></h5>
                        <div className="second-confirmation-block">
                            <h6>{orderDetail && orderDetail?.customer_order_payments[0]?.billingCustomerName}</h6>
                            <div className="user-details-text">
                                <p>{orderDetail && orderDetail?.customer_order_payments[0]?.billingEmailAddress}</p>
                                <p>{orderDetail && orderDetail?.customer_order_payments[0]?.billingContactNumber}</p>
                            </div>
                            <div className="user-details-text">
                                <p>{orderDetail && orderDetail?.customerCompany}</p>
                                <p>{orderDetail && orderDetail?.customerOrganizationNumber}</p>
                            </div>
                            <div className="user-details-text">
                                <p>{orderDetail && orderDetail?.customer_order_payments[0]?.billingStreetAddress}</p>
                            </div>
                        </div>
                    </div>}
                </Col>
            </Row>
            <Row>
                <Col>
                    {!loading && <div className="second-confirmation-part">
                        <h5 className='capitalize'><b>{orderDetail?.serviceMode === 'pickup' ? CONSTANTS.PICKUP_TXT : CONSTANTS.DELIVERY_TXT}</b></h5>
                        <div className="second-confirmation-block">
                            <h6>{orderDetail?.customerContactPerson}</h6>
                            <div className="user-details-text">
                                <p>{orderDetail?.customerEmailAddress}</p>
                                <p>{orderDetail?.customerContactNumber}</p>
                            </div>
                            <div className="user-details-text">
                                <p>{orderDetail?.serviceStreetAddress}</p>
                            </div>
                        </div>
                    </div>}
                </Col>
            </Row>
            {!loading && <div className="order-confirmation-cart">
                <CartDetailsComponent mode="readonly"
                    orderDetail={orderDetail}
                />
            </div>}
            <Row>
                <Col className="ic-mt ic-mb-2 offset-2 offset-sm-3 offset-md-4" xs={8} sm={6} md={4}>
                    <div>
                        <button
                            onClick={() => setShowDetail(false)}
                            className="back-to-orders-btn"
                        >
                            {CONSTANTS.ORDER_DETAIL_BACK_TO_ORDERS}
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    )
}