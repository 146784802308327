import React, { useState, useEffect } from 'react';
import moment from 'moment';

export const AppContext = React.createContext();
const Provider = AppContext.Provider;

const AppProvider = ({ children }) => {
    const [logOutDialouge, setLogOutDialouge] = useState(false)
    const [removeItemConfirmation, setRemoveItemConfirmation] = useState(false);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [openLoginDialog, setOpenLoginDialog] = useState(false);
    const [carousalForm, setCarousalForm] = useState(carousalData);
    const [billingInfo, setBillingInfo] = useState(billingInfoData);
    const [deliveryInfo, setDeliveryInfo] = useState(deliveryInformation);
    const [customerLogin, setCustomerLogin] = useState(customerLoginData);
    const [currentLoginCustomer, setCurrentLoginCustomer] = useState({});
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [authError, setAuthError] = useState(false);
    const [authErrormessage, setAuthErrorMessage] = useState('');
    const [error, setError] = useState({});
    const [selectedPackage, setSelectedPackage] = useState({});
    const [loginStep, setLoginStep] = useState(1);
    const [systemConfig, setSystemConfig] = useState({});
    const [showCartModal, setShowCartModal] = useState(false);
    const [cartObj, setCartObj] = useState(localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [])
    const [guestsCount, setGuestsCount] = useState(localStorage.getItem('noOfGuests') || '')
    const [location, setLocation] = useState(
        localStorage.getItem("eventLocation") || ""
    );
    const [cateringMode, setCateringMode] = useState(localStorage.getItem('cateringMode') || 'delivery')
    const [date, setDate] = useState(localStorage.getItem("eventDate") ? moment(new Date(localStorage.getItem("eventDate"))).format('YYYY-MM-DDTHH:mm') : "");
    const [preferences, setPreferences] = useState(localStorage.getItem('preferences') ?
        JSON.parse(localStorage.getItem('preferences')) : [])
    const [showSuplimentDialog, setSuplimentDialog] = useState(false);
    const [supplimentsAdded, setSuppliments] = useState(localStorage.getItem('cart_supplement') ? JSON.parse(localStorage.getItem('cart_supplement')) : false);
    

    const data = {
        carousalForm,
        setCarousalForm,
        openFilterDialog,
        setOpenFilterDialog,
        systemConfig,
        setSystemConfig,
        showCartModal,
        setShowCartModal,
        cartObj,
        setCartObj,
        guestsCount,
        setGuestsCount,
        location,
        setLocation,
        date,
        setDate,
        cateringMode,
        setCateringMode,
        preferences,
        setPreferences,
        billingInfo,
        setBillingInfo,
        deliveryInfo,
        setDeliveryInfo,
        openLoginDialog,
        setOpenLoginDialog,
        customerLogin,
        setCustomerLogin,
        loginStep,
        setLoginStep,
        currentLoginCustomer,
        setCurrentLoginCustomer,
        emailError,
        setEmailError,
        emailErrorMessage,
        setEmailErrorMessage,
        authError,
        setAuthError,
        authErrormessage,
        setAuthErrorMessage,
        error,
        setError,
        selectedPackage,
        setSelectedPackage,
        logOutDialouge,
        setLogOutDialouge,
        setRemoveItemConfirmation,
        removeItemConfirmation,
        showSuplimentDialog, 
        setSuplimentDialog,
        supplimentsAdded, 
        setSuppliments
    };

    useEffect(() => {
        setCurrentLoginCustomer(JSON.parse(localStorage.getItem('user')));
    }, [])

    return (
        <Provider value={data}>{children}</Provider>
    )
};

export default AppProvider;

const carousalData = {
    eventDate: localStorage.getItem('eventDate') || "",
    eventLocation: localStorage.getItem('eventLocation') || "",
    noOfGuests: localStorage.getItem('noOfGuests') || "",
    filter_preferences: localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : '',
};

const billingInfoData = {
    name: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    organizationNumber: '',
    streetAddress: '',
    postalCode: '',
    city: ''
}

const deliveryInformation = {
    useSameAsAbove: false,
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    streetAddress: '',
    area: '',
    city: '',
    postalCode: '',
    doorCode: '',
    numberOfGuests: '',
    additionalInformation: '',
    TermsAndConditions: false
}

const customerLoginData = {
    email: '',
    password: '',
    confirmPassword: '',
    agree_tnc: false
}