import React from "react";
import Slider from "../../../../Components/Slider";
import json from '../../../../Assets/JSON/landing-page.json';
import { CONSTANTS } from "../../../../Helpers/Constants";

export default function RestaurantSlider({ openModalHandler }) {
    return (
        <>
            <div className="_container">
                <br /><h4>{CONSTANTS.INSPIRATION_FOR_GOOD_MEETINGS}</h4>
                <Slider 
                    packageDetails={json?.vendor?.packages}
                    openModalHandler={openModalHandler}
                    vendors={json?.feature_vendors}
                />
            </div>
        </>
    )
}