import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import { OrdersAPI } from "../../Services/OrderProcessing";
import MyOrdersScreen from "./views/MyOrdersScreen";
import "../../Assets/Styles/myorders.css";
import Loader from "../../Components/Loader";
import OrderDetailContainer from "../MyOrderDetail";

export default function MyOrders() {
    const auth = getAuth()
    const { currentLoginCustomer } = useContext(AppContext)
    const [showDetail, setShowDetail] = useState(false)
    const [myOrders, setMyOrders] = useState('')
    const [myCompleteOrders, setMyCompleteOrders] = useState([])
    const [activeTab, setActiveTab] = useState('current')
    const [loading, setLoading] = useState(false)
    const [footer, setFooter] = useState(true)
    const [orderIndex, setOrderIndex] = useState('')
    const [orderDetail, setOrderDetail] = useState('')

    useEffect(async () => {
        setLoading(true)
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                let idToken = await getFreshToken(auth.currentUser)
                let orders = await OrdersAPI.getMyOrders(idToken)
                setMyCompleteOrders(orders.data)
                showOrders(activeTab, orders.data)
                setLoading(false)
                setFooter(false)
            } else {
                console.log('User is not logged ing')
                setLoading(false)
            }

        })
    }, [])

    const showOrders = (currentTab, orders = myCompleteOrders) => {
        if (currentTab === 'current') {
            setMyOrders(orders.current)
            setActiveTab(currentTab)
        } else {
            setActiveTab(currentTab)
            setMyOrders(orders.past)
        }
    }

    const getFreshToken = async (user) => {
        let idToken;
        idToken = await user
            .getIdToken(true)
        return idToken
    }

    const getOrderDetail = async (orderId, index) => {
        setLoading(true)
        setShowDetail(true)
        setOrderIndex(index)
        let idToken = await getFreshToken(auth.currentUser)
        const order = await OrdersAPI.getOrderDetails(idToken, orderId)
        setOrderDetail(order.data)
        setLoading(false)
    }

    return (
        <div className="ic-wrapper">
            <Header />
            {loading &&
                <div className="loaderParent">
                    <Loader />
                </div>}
            {!showDetail ? <MyOrdersScreen
                fixFooter={footer}
                myOrders={myOrders}
                activeTab={activeTab}
                getOrderDetail={getOrderDetail}
                setActiveTab={setActiveTab}
                showOrders={showOrders}
            /> :
                <OrderDetailContainer
                    loading={loading}
                    myOrders={myOrders}
                    orderIndex={orderIndex}
                    setShowDetail={setShowDetail}
                    orderDetail={orderDetail}
                />
            }
            <Footer />
        </div>
    )
}