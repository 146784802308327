import React, { useContext } from "react";
import { useLocation, useHistory } from 'react-router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import InstaLogo2 from '../../Assets/Images/footer_logo.svg';
import FacebookLogo from '../../Assets/Images/facebook.svg';
import InstagramLogo from '../../Assets/Images/instagram.svg';
import LinkedInLogo from '../../Assets/Images/linkedin.svg';
import { AppContext } from "../../Context/AppContext";
import LoginDialog from "../LoginDialog";
import ConfirmationDialog from "../ConfirmationDialog";
import { CONSTANTS } from "../../Helpers/Constants";

export default function Footer() {
    const location = useLocation();
    const history = useHistory();
    const currentLocation = location.pathname;
    const {
        openLoginDialog,
        setOpenLoginDialog,
        customerLogin,
        setCustomerLogin,
        loginStep,
        setLoginStep,
        currentLoginCustomer,
        emailError,
        setEmailError,
        emailErrorMessage,
        setEmailErrorMessage,
        authError,
        setAuthError,
        authErrormessage,
        setAuthErrorMessage,
        logOutDialouge,
        setLogOutDialouge
    } = useContext(AppContext);

    const loginOnFooter = () => {
        setOpenLoginDialog(true);
    }

    const logoutOnFooter = () => {
        localStorage.removeItem('user');
        window.location.reload();
    }
    return (
        <>
            {<ConfirmationDialog
                openConfirmationDialog={logOutDialouge}
                setConfirmationDialog={setLogOutDialouge}
                callBack={logoutOnFooter}
                confirmationMsg={CONSTANTS.REALLY_LOGOUT}
            ></ConfirmationDialog>}
            {openLoginDialog &&
                <LoginDialog
                    openLoginDialog={openLoginDialog}
                    setOpenLoginDialog={setOpenLoginDialog}
                    customerLogin={customerLogin}
                    setCustomerLogin={setCustomerLogin}
                    loginStep={loginStep}
                    setLoginStep={setLoginStep}
                    emailError={emailError}
                    setEmailError={setEmailError}
                    emailErrorMessage={emailErrorMessage}
                    setEmailErrorMessage={setEmailErrorMessage}
                    authError={authError}
                    setAuthError={setAuthError}
                    authErrormessage={authErrormessage}
                    setAuthErrorMessage={setAuthErrorMessage}
                    currentLocation={currentLocation}
                />
            }
            <div className="footer">
                <Row className="footer-row">
                    <Col className="footer-col-1">
                        <div className="footer-logo">
                            <img src={InstaLogo2} alt="Logo" height='100px' width='150px' />
                        </div>
                        {currentLoginCustomer === null
                            ?
                            <div className="main-footer">
                                <p onClick={() => window.open('/frequently-asked-questions')}>{CONSTANTS.FREQUENT_TXT}</p>
                                <p onClick={() => window.open('/terms-and-conditions')}>{CONSTANTS.TERMS_CONDITIONS_LABEL}</p>
                                <p onClick={() => window.open('/privacy-policy')}>{CONSTANTS.PRVACY_POLICY}</p>
                                <p><p onClick={() => history.push('/')}>{'instacate.se'}</p>
                                <span className="footer-number-separator"> | </span>
                                <a className="footer-number" href="tel:08-222 500">08-222 500</a></p>
                            </div>
                            :
                            <div className="main-login-footer">
                                <p onClick={() => window.open('/my-orders')}>{CONSTANTS.MY_ORDERS_TXT}</p>
                                <p onClick={() => window.open('/frequently-asked-questions')}>{CONSTANTS.FREQUENT_TXT}</p>
                                <p onClick={() => window.open('/terms-and-conditions')}>{CONSTANTS.TERMS_CONDITIONS_LABEL}</p>
                                <p onClick={() => window.open('/privacy-policy')}>{CONSTANTS.PRVACY_POLICY}</p>
                                <p><p onClick={() => history.push('/')}>{'instacate.se'}</p>
                                <span className="footer-number-separator"> | </span>
                                <a className="footer-number" href={`tel:${CONSTANTS.CONTACT_NUMBER}`}>{CONSTANTS.CONTACT_NUMBER}</a></p>
                            </div>
                        }
                    </Col>
                    <Col className="footer-col-2">
                        <div className="footer-icons">
                            <a href="/" className="footer-icon"><img src={FacebookLogo} alt="FacebookLogo" height='20px' width="20px" /></a>
                            <a href="https://www.instagram.com/instacate_se/" target="_blank" className="footer-icon"><img src={InstagramLogo} alt="InstagramLogo" height='20px' width="20px" /></a>
                            <a href="https://www.linkedin.com/company/instacate/" target="_blank" className="footer-icon"><img src={LinkedInLogo} alt="LinkedInLogo" height='20px' width="20px" /></a>
                        </div>
                        <div className="footer-buttons">
                            {currentLoginCustomer === null
                                ? <button className="footer-first-button" onClick={() => loginOnFooter()}>{CONSTANTS.LOGIN_BUTTON_TXT}</button>
                                : <button className="footer-first-button" onClick={() => setLogOutDialouge(true)}>{CONSTANTS.LOGOUT}</button>
                            }
                            <button className="footer-second-button"><a href="https://forms.gle/9AMofrde3Prp94Vw8" target="_blank">{'SALJ MED OSS'}</a></button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}