import { useHistory } from "react-router";
import '../../Assets/Styles/error.css'
import { CONSTANTS } from "../../Helpers/Constants";
export default function ErrorDisplay({ error, showRedirect = false, fixFooter }) {
  const history = useHistory();
  return (
    <div className={`${fixFooter && 'fit-footer-filter'} error-div`}>
      <div>
        <div>
          <img src={error?.imageUrl} alt="" />
        </div>
        <h1>{error?.text}</h1>
        <h4>{error?.title}</h4>
        {showRedirect && (
          <div >
            <button
              className="confirmBtn redirect-btn"
              onClick={() => history.push("/vendors")}
            >
              {CONSTANTS.BACK_TO_MENU_TXT}
            </button>
          </div>


        )}
        {error?.additionalText && (
          <h4 className="additional-text-color">{error?.additionalText}</h4>
        )}
      </div>
    </div>
  );
}
