import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../Context/AppContext';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import moment from 'moment';
import arrowDown from '../../Assets/Images/arrow_down.svg';
import FilterTagsIcon from '../../Assets/Images/tags-filter-modal.svg';
import FilterDatePicker from '../../Assets/Images/filter_datetime.svg';
import FilterPeople from '../../Assets/Images/filter_people.svg';
import FilterLocation from '../../Assets/Images/filter_location.svg';
import Delivery from '../../Assets/Images/filter_delivery.svg';
import DeliveryGrayIcon from '../../Assets/Images/Delivery_gray.svg';
import Pickup from '../../Assets/Images/filter_pickup.svg';
import PickupWhite from '../../Assets/Images/Pickup_white.svg';
import AlertIcon from "../../Assets/Images/alert-icon.svg";
import json from "../../Assets/JSON/landing-page.json";

import '../../Assets/Styles/filter.css';
import { CONSTANTS } from '../../Helpers/Constants';
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";

export default function FilterDialog({ locations, participants, changeHandler, fetchVendors, serviceMode, setServiceMode, prefrences, setPreferences, tags, pathname, vendorIndex }) {
    const { carousalForm, openFilterDialog, setOpenFilterDialog, guestsCount, setGuestsCount,
        location, setLocation,
        date, setDate,
        cateringMode,
        setCateringMode, setErrorVendorPackage } = useContext(AppContext);
    const [guests, setGuests] = useState(guestsCount || '');
    const [locationSelected, setLocationSelected] = useState(location || '');
    const [eventDate, setEventDate] = useState(date && new Date(date) || '');
    const [orderMode, setOrderMode] = useState(cateringMode);
    const todayDate = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16);
    const _date = localStorage.getItem('eventDate') ? moment(localStorage.getItem('eventDate')).format('YYYY-MM-DDTHH:mm') : "";
    const [FilteredTags, setTags] = useState(prefrences || [])
    const [showLibraryPicker, setShowLibraryPicker] = React.useState(false);
    const history = useHistory();
    const [startDate, setStartDate] = React.useState(_date ? new Date(_date) : false);

    useEffect(() => {
        let isFirefox = typeof InstallTrigger !== 'undefined';
        let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
        let iPhone = window.navigator.userAgent.match(/iPhone/i);
        let iPad = window.navigator.userAgent.match(/iPad/i);
        let Safari = window.navigator.userAgent.match(/Safari/i);
        if (isFirefox || isSafari || Safari || iPhone || iPad) {
            setShowLibraryPicker(true);
        } else {
            setShowLibraryPicker(false)
        }

    }, [])

    const submitModal = async () => {
        localStorage.setItem('preferences', JSON.stringify(FilteredTags))
        localStorage.setItem('eventLocation', locationSelected)
        localStorage.setItem('noOfGuests', guests)
        localStorage.setItem('eventDate', eventDate)
        localStorage.setItem('cateringMode', orderMode)
        setLocation(locationSelected);
        setGuestsCount(guests);
        setDate(eventDate);
        setCateringMode(orderMode);
        setPreferences(FilteredTags);
        setOpenFilterDialog(false);
        if (pathname === '/') {
            localStorage.removeItem('vendor');
            localStorage.setItem('featuredVendorPackageId', JSON.stringify(json.feature_vendors[vendorIndex]?.vendorId));
            history.push('/vendor/packages');
        }
    }
    const current_env = process.env.REACT_APP_DATE_INPUT_TYPING_ENABLED;
    return (
        <Modal
            show={openFilterDialog}
            onHide={() => setOpenFilterDialog(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="md"
            style={{ opacity: 1 }}
        >
            <Modal.Body className="filter-modal-body">
                <Row>
                    <Col>
                        <label>{CONSTANTS.WHEN_ARE_YOU_GOING_TO_SEE_EACH_OTHER}</label>
                        <div className="filter-input dp-class">
                            {showLibraryPicker ?
                                <>
                                    <img src={FilterDatePicker} alt="FilterPeople" className='filter-image' />
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => { setStartDate(date); setEventDate(date) }}
                                        showTimeSelect
                                        placeholderText="yyyy/mm/dd --:-- --"
                                        timeFormat="HH:mm"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        required
                                        minDate={new Date()}
                                        name="eventDate"
                                        defaultValue={_date}
                                        excludeDates={[new Date()]}
                                        locale="sv-SE"
                                        timeIntervals={15}
                                    // className="filter-text"
                                    />
                                </>
                                :
                                <>
                                    <img src={FilterDatePicker} alt="FilterPeople" className='filter-image dp-icon' />
                                    <input
                                        placeholder="dd/mm/yyyy --:--"
                                        type="datetime-local"
                                        onKeyDown={current_env ? null : (e) => e.preventDefault()}
                                        id="eventDate"
                                        name="eventDate"
                                        defaultValue={_date}
                                        className=" filter-text"
                                        onChange={(e) => setEventDate(e.target.value)}
                                        min={todayDate}
                                        required
                                    />
                                </>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>{CONSTANTS.WHERE_ARE_YOU_GOING_TO_SEE}</label>
                        <div className="filter-input">
                            <img src={FilterLocation} alt="FilterLocation" className='filter-image' />
                            <select
                                placeholder={CONSTANTS.EVENT_LOCATION}
                                name="eventLocation"
                                id="eventLocation"
                                className="filter-text"
                                required
                                value={locationSelected}
                                onChange={(e) => setLocationSelected(e.target.value)}
                            >
                                <option>{CONSTANTS.SELECT_LOCATION}</option>
                                {locations.map((item, index) => {
                                    return (
                                        <option id={`${item}_${index}`} key={index} value={item}>{item}</option>
                                    )
                                })}
                            </select>
                            <img src={arrowDown} className="arrow" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>{CONSTANTS.HOW_MANY_GUESTS_WILL_YOU_HAVE}</label>
                        <div className='filter-input'>
                            <img src={FilterPeople} alt="FilterPeople" className='filter-image' />
                            <select
                                placeholder={CONSTANTS.GUESTS_EXPECTED}
                                name="noOfGuests"
                                id="noOfGuests"
                                className="filter-text"
                                required={true}
                                value={guests}
                                onChange={(e) => setGuests(e.target.value)}
                            >
                                <option>{CONSTANTS.SELECT}</option>
                                {participants.map((item, index) => {
                                    return (
                                        <option id={`${item}_${index}`} key={index} value={item.value}>
                                            {item.key}</option>
                                    )
                                })}
                            </select>
                            <img src={arrowDown} className="arrow" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>{CONSTANTS.PREFERENCES}</label>
                        <div className='filter-input'>
                            <img src={FilterTagsIcon} alt="" className='filter-image' />
                            <Select
                                options={FilteredTags?.length < 3 ? tags : []}
                                placeholder={CONSTANTS.PREFERENCES_LABEL}
                                isMulti
                                value={FilteredTags}
                                onChange={(e) => setTags(e)}
                            >
                            </Select>
                        </div>
                    </Col>
                </Row><br />
                <Row>
                    <label>{CONSTANTS.SERVICE_MODE}</label>
                    <Col className='col-6'>
                        <div className="radio-toolbar">
                            <input
                                type="radio"
                                id="radioDelivery"
                                name="radioService"
                                value='delivery'
                                onChange={(e) => setOrderMode('delivery')}
                                checked={orderMode === 'delivery' ? true : false}
                            />
                            <label for="radioDelivery"><img src={orderMode === 'delivery' ? Delivery : DeliveryGrayIcon} />&nbsp;&nbsp;{CONSTANTS.DELIVERY_TXT}</label>
                        </div>
                    </Col>
                    <Col className='col-6'>
                        <div className="radio-toolbar">
                            <input
                                type="radio"
                                id="radioPickup"
                                name="radioService"
                                value="pickup"
                                onChange={(e) => setOrderMode('pickup')}
                                checked={orderMode === 'pickup' ? true : false}
                            />
                            <label for="radioPickup"><img src={orderMode === 'pickup' ? PickupWhite : Pickup} />&nbsp;&nbsp;{CONSTANTS.PICKUP_TXT}</label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-6'>
                        <button
                            className="modal-cancel-button"
                            onClick={() => setOpenFilterDialog(false)}>
                            {CONSTANTS.CANCEL_TXT}
                        </button>
                    </Col>
                    <Col className='col-6'>
                        <button
                            className="modal-button"
                            disabled={locationSelected === '' || eventDate === ''}
                            onClick={() => submitModal()}>
                            {pathname === '/' ? CONSTANTS.SEARCH_TXT : CONSTANTS.APPLY_FILTERS_TXT}
                        </button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}