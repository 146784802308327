import moment from "moment"
import 'moment/locale/sv'
import { CONSTANTS } from "../../../Helpers/Constants"
import emptyCart from '../../../Assets/Images/empty-orders.svg'
import { OrdersAPI } from "../../../Services/OrderProcessing"
import { auth } from "../../../Firebase/Config"

export default function MyOrdersScreen({ getOrderDetail,showOrders,fixFooter, myOrders, activeTab }) {
    return (
        <div className={`${fixFooter && 'fit-footer'} myorders-container`}>
            <div className="my-orders-subcontainer">
                <h1>{CONSTANTS.MY_ORDERS}</h1>
                <div className="ordertabs">
                    <div className={`${activeTab === 'current' ? "active-tab" : "can-click"}`} onClick={() => showOrders('current')}>
                        {CONSTANTS.CURRENT}
                    </div>
                    <div className={`${activeTab === 'past' ? "active-tab" : "can-click"}`} onClick={() => showOrders('past')}>
                        {CONSTANTS.PAST}
                    </div>
                </div>

                {myOrders && myOrders.length > 0 ? myOrders.map((item, index) => {
                    const deliveryTime = moment(new Date(item.serviceDate._seconds * 1000)).locale("sv").format("YYYY MMMM DD HH.mm")
                    return (
                        <div className="order-detail-container" key={item.orderId} onClick={() => getOrderDetail(item.orderId, index)}>
                            <div className="my-order-detail">
                                <div className="order-num-date">
                                    <div className="order-num">
                                        {CONSTANTS.ORDER} # {item.orderId}
                                    </div>

                                    <div className="order-date">
                                        {deliveryTime}
                                    </div>
                                </div>
                                <div className="vendor-price">
                                    <div className="vendorName">
                                        {item.vendorName}
                                        <p className={`${item.status === "accepted" ? 'status-accepted' : 'status-other'}`}>{item.status.toLocaleUpperCase()}</p>
                                    </div>

                                    <div className="order-price">
                                        {item.totalPayableAmount} {CONSTANTS.KRONE_TXT}
                                    </div>
                                </div>
                            </div>

                            <hr className="my-order-separator" />
                        </div>
                    )
                }
                ) : myOrders === '' ?
                    <div className="noOrders"></div>
                    : myOrders?.length === 0 ?
                        <div className="emptyOrders">
                            <div>
                                <img src={emptyCart} />
                                <p className='emptyOrdersHeading'>{CONSTANTS.MY_ORDERS_NO_ORDERS_YET}</p>
                                <p className="emptyOrderDesc">{CONSTANTS.MY_ORDERS_DESCRIPTION_1}<br />{CONSTANTS.MY_ORDERS_DESCRIPTION_2}</p>
                            </div>
                        </div>
                        :
                        <></>
                }

            </div>
        </div>
    )
}