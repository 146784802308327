import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from "moment";
import 'moment/locale/sv'
import ConfirmationTick from '../../../Assets/Images/confirmation_tick.svg';
import '../../../Assets/Styles/order-confirmation.css';
import CartDetailsComponent from "../../../Components/CartDetails";
import { CONSTANTS } from "../../../Helpers/Constants";

export default function OrderConfirmationScreen({
    history,
    result,
    order,
    callBack,
    cart
}) {
    const customer = order?.customer_order_payments?.[0] || null;
    return (
        <>
            <Row>
                <Col>
                    <div className="first-confirmation-part">
                        <div className="first-part">
                            <div>
                                <img src={ConfirmationTick} alt="ConfirmationTick" className="confirmation_tick" />
                            </div>
                            <div>
                                <h2>{CONSTANTS.THANK_YOU_TXT}</h2>
                                <p>{`${CONSTANTS.YOUR_ORDER_TXT} ${result?.data?.order?.orderId} ${CONSTANTS.HAS_BEEN_PLACED_TXT}`}</p>
                                </div>
                                </div>
                                <p>{`${CONSTANTS.EMAIL_SENT_CONFIRM_TXT} ${customer?.billingEmailAddress}`} </p>
                                <p>{CONSTANTS.SPAM_FILTERING_TXT}</p>
                        <h5>{`${CONSTANTS.TIME_PLACED_TXT} ${order.createdOn && moment(Date(order.createdOn)).locale("sv").format('YYYY MMMM DD HH.mm')}`} </h5>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="second-confirmation-part">
                        <h5>{CONSTANTS.BILIING_TXT}</h5>
                        <div className="second-confirmation-block">
                            <h6>{customer?.billingCustomerName}</h6>
                            <div className="user-details-text">
                                <p>{customer?.billingEmailAddress}</p>
                                <p>{customer?.billingContactNumber}</p>
                            </div>
                            <div className="user-details-text">
                                <p>{order?.customerCompany}</p>
                                <p>{order?.customerOrganizationNumber}</p>
                            </div>
                            <div className="user-details-text">
                                <p>{customer?.billingStreetAddress}</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="second-confirmation-part">
                        <h5 className='capitalize'>{order?.serviceMode === 'pickup'? CONSTANTS.PICKUP_TXT : CONSTANTS.DELIVERY_TXT}</h5>
                        <div className="second-confirmation-block">
                            <h6>{order?.customerContactPerson}</h6>
                            <div className="user-details-text">
                                <p>{order?.customerEmailAddress}</p>
                                <p>{order?.customerContactNumber}</p>
                            </div>
                            <div className="user-details-text">
                                <p>{order?.serviceStreetAddress}</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="order-confirmation-cart">
                <CartDetailsComponent mode="readonly" cartProp={cart}/>
            </div>

            <Row>
                <Col className="ic-mt ic-mb-2 offset-2 offset-sm-3 offset-md-4" xs={8} sm={6} md={4}>
                    <div>
                        <button
                            onClick={() => callBack()}
                            className="filter-button"
                        >
                            {CONSTANTS.CREATE_ANOTHER_ORDER}
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    )
}