import React from "react";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import SliderBox from "../SliderBox";
import SliderImage1 from '../../Assets/Images/feature_vendor_1.jpg';
import SliderImage2 from '../../Assets/Images/feature_vendor_2.jpg';
import SliderImage3 from '../../Assets/Images/feature_vendor_3.jpg';
import { CONSTANTS } from "../../Helpers/Constants";

function Slider({ openModalHandler, packages, vendors }) {
    const carousel = React.createRef();
    const options = {
        onInitialized: function(){
            this.to(1, 1)
            },
        touchDrag: true,
        mouseDrag: true,
        margin:10,
        dots: false,
        stagePadding: 75,
        responsive:{
            0: {
                items: 1,
            },
            375: {
                items: 1,
            },
            390: {
                items: 1,
            },
            415:{
                items: 1,
            },
            500: {
                items: 1,
            },
            1000: {
                items: 3
            }
        }
    }

    return(
        <>
        <OwlCarousel className='owl-theme ic-owl' ref={carousel} {...options}>
        {vendors.map((vendor, index) => {
                    return (
                        <SliderBox
                            sliderImage={
                                index === 0 ? SliderImage1 :
                                    index === 1 ? SliderImage2 :
                                        SliderImage3}
                            restaurantName={`${vendor.vendor_name}`}
                            barrierBGColor={index === 1 ? '#ED8C7C' : '#135478'}
                            packageDescription={vendor.description}
                            openModalHandler={openModalHandler}
                            index={index}
                        />
                    )
                })}
        </OwlCarousel>
        </>
    )
}

export default React.memo(Slider);