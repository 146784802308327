import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../Context/AppContext";
import { VendersAPI } from "../../Services/ExploreVendors";
import PackagesList from "./packagesList";
import { addArrayInDB, selectOptions, secondstoDateTime, toIsoString } from "../../Helpers/Handlers";
import emptyVaseImg from "../../Assets/Images/empty-vase.svg";
import LocationsImg from '../../Assets/Images/locations.svg'
import ErrorImgShow from '../../Assets/Images/1002.png';
import locationsUnavailableImg from '../../Assets/Images/Location_unavailable.svg'
import dateUnavailableImg from '../../Assets/Images/Date_unavailable.svg'
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import { useLocation } from "react-router";

import "../../Assets/Styles/vendor-package-list.css";
import { CONSTANTS } from "../../Helpers/Constants";

export default function VendorPackages() {
  const {
    carousalForm,
    setCarousalForm,
    openFilterDialog,
    showCartModal,
    setSystemConfig,
    setShowCartModal,
    cartObj,
    setCartObj,
    location,
    date,
    guestsCount,
    cateringMode,
    setCateringMode,
    preferences,
    setPreferences,
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const [initialLoad, setInitialLoad] = useState(true);
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);
  const [vendor, setVendor] = useState(
    localStorage.getItem("vendor")
      ? JSON.parse(localStorage.getItem("vendor"))
      : ""
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const featuredVendorPackageId = localStorage.getItem("featuredVendorPackageId")
    ? JSON.parse(localStorage.getItem("featuredVendorPackageId"))
    : ""
  const [eventDate, setDate] = useState(
    localStorage.getItem("eventDate")
      ? new Date(localStorage.getItem("eventDate"))
      : ""
  );

  const selectedPreferences = selectOptions(
    JSON.parse(localStorage.getItem("preferences"))
  );
  const [tags, setTags] = useState([]);
  useEffect(() => {
    fetchSystemConfig();
    setInitialLoad(false);
  }, []);

  useEffect(() => {
    setShowCartModal(false);
  }, [pathname])
  
  const fetchSystemConfig = async () => {
    const _system_config = await VendersAPI.getSystemConfig();
    setSystemConfig(_system_config?.data);
    localStorage.setItem("configs", JSON.stringify(_system_config?.data));
    const optionsTags = selectOptions(_system_config?.data?.tags);
    setTags(optionsTags);
  };
  const cartEmptyMsg = CONSTANTS.FILLED_CART_EMPTY_MSG;
  
  // const [prefrences, setPreferences] = useState(selectedPreferences || []);
  const fetchVendors = async () => {
    
    setLoading(true);
    
    const _serviceMode = cateringMode || "delivery";
    const tagsToUpdate = await addArrayInDB(preferences);
    if (vendor) {
      if (!validateSelections()) {
        setTimeout(() => { setLoading(false) }, 1000)
        return false
      }
      await VendersAPI.getVendors({
        eventDate: toIsoString(new Date(date)),
        location: location.toLowerCase(),
        tags: tagsToUpdate,
        serviceMode: _serviceMode,
        capacity: parseInt(guestsCount),
        index: 1,
        pageSize: 50,
        vendorId: vendor?.vendorId,
      })
        .then((result) => {
          setLoading(false);
          if (result?.status === "error") {
            const error = result?.data?.errors?.[0] || null;
            setError({
              imageUrl: error?.imageLink,
              title: error?.message,
              text: error?.title,
            });
          } else {
            const _vendor = result?.data?.vendors[0];
            if(_vendor?.packages.length === 0){
              setError({
                imageUrl: ErrorImgShow,
                title: CONSTANTS.NO_RESTAURANTS_FOUND,
                text: CONSTANTS.TRY_DIFFERENT_RESTAURANT,
              });
            }
            else {
              setVendor(result?.data?.vendors[0])
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          // console.log("items catch: ", error);
        });
    }
    else {
      if (!featuredVendorPackageId) {
        setLoading(false);
        setError({
          imageUrl: emptyVaseImg,
          title: CONSTANTS.DATE_LOCATION_MISSING_ERROR,
          text: CONSTANTS.EVENT_DETAILS_MISSING_TXT,
        });
        return false
      } 
      await VendersAPI.getVendors({
        eventDate: toIsoString(new Date(date)),
        location: location.toLowerCase(),
        tags: tagsToUpdate,
        serviceMode: _serviceMode,
        capacity: guestsCount,
        index: 1,
        pageSize: 50,
        vendorId: featuredVendorPackageId,
      })
        .then((result) => {
          if (result?.status === "error") {
            setLoading(false);
            const error = result?.data?.errors?.[0] || null;
            setError({
              imageUrl: error?.imageLink,
              title: error?.message,
              text: error?.title,
            });
          } else {
            setError("");
            const _vendor = result?.data?.vendors[0];
            localStorage.setItem('vendor', JSON.stringify(_vendor))
            setVendor(_vendor);
            if(_vendor?.package === []){
              setError({
                imageUrl: ErrorImgShow,
                title: CONSTANTS.NO_RESTAURANTS_FOUND,
                text: CONSTANTS.TRY_DIFFERENT_RESTAURANT,
              });
            }
            // window.location.reload();
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          // console.log("items catch: ", error);
        });
    }
  };
  
  const validateSelections = () => {
    const vendorLocations = vendor?.locations;
    const unAvailableDates = vendor?.nonBookableDates.map(date => {
      return secondstoDateTime(date._seconds)
    }); 
    if (location === "" || date === "") {
      setError({
        imageUrl: emptyVaseImg,
        title: CONSTANTS.DATE_LOCATION_MISSING_ERROR,
        text: CONSTANTS.EVENT_DETAILS_MISSING_TXT,
      });
      return false
    }    
    else if (vendorLocations.indexOf(location.toLowerCase()) < 0) {
      setError({
        imageUrl: locationsUnavailableImg,
        title: CONSTANTS.VENDOR_DELIVERY_UNAVAILABLE_TXT,
        text: CONSTANTS.LOCATION_UNAVAILABLE_TXT,
      });
      return false;
    } else if (cateringMode === "delivery" && !vendor?.isDeliveryEnabled) {
      setError({
        imageUrl: locationsUnavailableImg,
        title: CONSTANTS.VENDOR_DELIVERY_UNAVAILABLE_TXT,
        text: CONSTANTS.LOCATION_UNAVAILABLE_TXT,
      });
      return false;
    }
    else if (!checkDate(unAvailableDates, date)) {
      setError({
        imageUrl: dateUnavailableImg,
        title: CONSTANTS.VENDOR_UNAVAILABLE_DATE_MSG,
        text: CONSTANTS.DATE_UNAVAILABLE_TXT,
      });
      return false;
    }
    else {setError('');return true};
  };

  const checkDate = (collection_dates, selected_date) => {
    let flag = true;
    collection_dates.forEach(date =>{
      if(new Date(date).toDateString() === new Date(selected_date).toDateString() ){
        flag= false;
        return;
      }
    })
    return flag;
  }

  const changeHandler = (field, value) => {
    setCarousalForm({
      ...carousalForm,
      [field]: value,
    });
    localStorage.setItem(field, value);
  };

  useEffect(() => {
    const validateDate = () => {
      if (location === "" || date === "") {
        setError({
          imageUrl: emptyVaseImg,
          title: CONSTANTS.DATE_LOCATION_MISSING_ERROR,
          text: CONSTANTS.EVENT_DETAILS_MISSING_TXT,
        });
      }
      // }
    };
    return validateDate();
  }, []);

  /*This Effect will observe changes in filters and fetches vendors simultaneously */
  useEffect(() => {
    // !initialLoad && fetchVendors();
    fetchVendors();
  }, [cateringMode, guestsCount, date, location]);

  const addToCartEventHandler = (item) => {
    setSelectedPackage(item);

    if (localStorage.getItem("cart")) {
      const objIndex = JSON.parse(localStorage.getItem("cart")).findIndex(
        (elem) => elem.vendorId !== item.vendorId
      );
      if (objIndex > -1) {
        setConfirmationDialog(true);
        return;
      }
    }
    setShowCartModal(true);
  };

  const emptyCart = () => {
    setConfirmationDialog(false);
    setCartObj([]);
    localStorage.removeItem("cart");
    localStorage.removeItem("cart_supplement");
    localStorage.removeItem("cart_date");
    localStorage.removeItem("cart_guests");
    localStorage.removeItem("cart_location");
    localStorage.removeItem("cart_cateringMode");

    localStorage.removeItem("selected_vendor");
    setShowCartModal(true);
  };

  return (
    <>
      <div className="ic-wrapper">
        <PackagesList
          vendor={vendor}
          error={error}
          carousalForm={carousalForm}
          setCarousalForm={setCarousalForm}
          openFilterDialog={openFilterDialog}
          showCartModal={showCartModal}
          setShowCartModal={setShowCartModal}
          cartObj={cartObj}
          setCartObj={setCartObj}
          eventDate={date}
          guestsCount={guestsCount}
          location={location}
          serviceMode={cateringMode}
          changeHandler={changeHandler}
          loading={loading}
          setServiceMode={setCateringMode}
          addToCartEventHandler={addToCartEventHandler}
          selectedPackage={selectedPackage}
          prefrences={preferences}
          setPreferences={setPreferences}
          tags={tags}
          pathname={pathname}
        />
        <ConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setConfirmationDialog={setConfirmationDialog}
          callBack={emptyCart}
          confirmationMsg={cartEmptyMsg}
          cancelFunction={() => setConfirmationDialog(false)}
        ></ConfirmationDialog>
      </div>
    </>
  );
}
