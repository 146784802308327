
import React, { useContext, useEffect, useRef, useState } from "react";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from "../../Context/AppContext";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import packageItemsIcon from "../../Assets/Images/bullet_points.svg";
import AlertIcon from "../../Assets/Images/alert-icon.svg";
import ModalCloseIcon from '../../Assets/Images/modal-close-icon.svg';

import "../../Assets/Styles/cart-modal.css";
import { CONSTANTS } from "../../Helpers/Constants";

export default function CartModal({ packageObj, vendor, setRemoveItemConfirmation }) {

  const [allergies, setAllergies] = useState('');
  const [quantity, setQuantity] = useState();
  const [error, setError] = useState('');
  const {
    showCartModal,
    setShowCartModal,
    cartObj,
    setCartObj,
    guestsCount,
    location,
    cateringMode,
    date,
  } = useContext(AppContext);

  const inputRef = useRef(null)
  const [mode, setMode] = useState(cartObj?.find((elem) => elem.packageId === packageObj.packageId)
    ? "edit"
    : "add");
  const [addedItem, setAddedItem] = useState(null)
  useEffect(() => {
    if (mode === 'edit') {

      const cartItem = cartObj?.length > 0 && cartObj?.filter((elem) => elem.packageId === packageObj.packageId)[0]
      setAddedItem(cartItem);
      setQuantity(parseInt(cartItem?.quantity))
      setAllergies(cartItem?.allergies)

    }
    else setQuantity(0)
  }, [])

  useEffect(() => {
    if (mode !== 'edit') inputRef.current.focus()
  }, [])

  const cancelBtnLabel = mode === "edit" ? CONSTANTS.REMOVE_TXT : CONSTANTS.CANCEL_TXT;
  const submitBtnLabel = mode === "edit" ? CONSTANTS.UPDATE_CART_TXT : CONSTANTS.ADD_TO_CART_TXT;
  const itemAddedErrorMsg = CONSTANTS.ITEM_ALREADY_ADDED_MSG;
  const validateQuantity = (value) => {
    if (value && isNaN(value)) { //error

    }
    else if (packageObj?.pricePerUnit && value < packageObj?.pricePerUnit) {
      //console.log('err 2 ')//error for less quantity
    }
    else setQuantity(value)
  }
  const saveCart = () => {
    if (quantity === '' || quantity < packageObj.minimumOrderCapacity) {
      setError(CONSTANTS.QUANTITY_ERROR_MSG)
      return false;
    }
    setError('')
    const _cartObj = cartObj;
    if (mode === 'edit') { // edit cart
      const oldPackage = { ...addedItem, 'quantity': quantity, 'allergies': allergies }
      const objIndex = _cartObj?.findIndex(elem => elem.packageId === packageObj.packageId)

      const updatedCart = [
        ..._cartObj.slice(0, objIndex),
        oldPackage,
        ..._cartObj.slice(objIndex + 1),
      ];
      toast.success(CONSTANTS.ITEM_UPDATED_MSG, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      localStorage.setItem('selected_vendor', JSON.stringify(vendor))
      localStorage.setItem('cart', JSON.stringify(updatedCart))
      setCartObj(updatedCart)
      setShowCartModal(false)
    }
    else {  //fresh Cart
      //check if item already exists on cart
      if (localStorage.getItem('cart')) {
        const objIndex = JSON.parse(localStorage.getItem('cart')).findIndex(elem => elem.packageId === packageObj.packageId)
        if (objIndex > -1) {
          setError(itemAddedErrorMsg);
          return;
        }
      }

      const newPackage = { ...packageObj, 'quantity': quantity, 'allergies': allergies }
      let cart = [...cartObj, newPackage];
      localStorage.setItem('cart', JSON.stringify(cart))
      localStorage.getItem('cart_supplement') ? null : localStorage.setItem('cart_supplement', false)
      localStorage.setItem('cart_date', date)
      localStorage.setItem('cart_guests', guestsCount)
      localStorage.setItem('cart_location', location)
      localStorage.setItem('cart_cateringMode', cateringMode)
      localStorage.setItem('selected_vendor', JSON.stringify(vendor))
      setCartObj(cart)
      toast.success(CONSTANTS.ITEM_ADDED_MSG, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      setShowCartModal(false)
    }
  }

  const cancelBtn = () => {
    if (mode === 'edit') {
      setShowCartModal(false)
      setRemoveItemConfirmation(true)
    }
    else setShowCartModal(false);
  }
  return (
    <Modal
      show={showCartModal}
      onHide={() => setShowCartModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      style={{ opacity: 1 }}
    >
      {error !== '' && (
        <Modal.Header className='cart-error-div'>
          <Col>
            <img src={AlertIcon} alt="" />
            <span className='span-modal-error'>{error}</span>
          </Col>
        </Modal.Header>
      )}
      <Modal.Body>
        <Row className='mb15'>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="cm-main">
              <div className="svp-package" key={`div-package-cart`}>
                <img src={packageObj?.imageUrl} className="svp-package-image" />
                <div className="svp-package-info">
                  <h4>{packageObj?.title}</h4>
                  <p>{packageObj?.description}</p>
                </div>
              </div>
              <div className="svp-package-item">
                {packageObj.packageItems &&
                  packageObj.packageItems.map((element, i) => {
                    return (
                      <div className="svp-item" key={`div-packageItem-${i}`}>
                        <Row>
                          <Col xs={1} sm={1} md={1} lg={1}>
                            <img
                              src={packageItemsIcon}
                              key={`img-packageItem-${i}`}
                              alt=""
                            />
                          </Col>
                          <Col xs={11} sm={11} md={11} lg={11}>
                            <label>{element.title}</label>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </div>

            </div>
          </Col>
          {mode === "edit" &&
            <div className="cross-icon">
              <img src={ModalCloseIcon} alt="" className='cursor-pointer'
                onClick={() => setShowCartModal(false)}></img>
            </div>
          }
        </Row>

        <Row className="cart-row">
          <Col>
            <h3 className="width50">{`${CONSTANTS.MIN_ORDER_TXT}: ${packageObj?.minimumOrderCapacity}`}</h3>
          </Col>
          <Col>
            <div className="cm-pricing-div">
              <h4>{`${packageObj?.pricePerUnitWithFeeAmount} ${CONSTANTS.KRONE_TXT}`}</h4>
              <span className='cm-exkl-div'>{CONSTANTS.EXKL_MOMS_TXT}</span>
            </div>
          </Col>
        </Row>
        <Row className="cart-row">
          <div className='col'><h3 className="cart-quantity">{CONSTANTS.QUANTITY_TXT}</h3></div>
          <Col className='textAlignEnd'>
            <input
              ref={inputRef}
              autoFocus
              className='width20'
              type="number"
              pattern="\d*"
              name='quantity'
              defaultValue={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min={packageObj?.minimumOrderCapacity}
            />
          </Col>
        </Row>
        <Row className="cart-row">
          <div><h3 className="cart-allergy">{CONSTANTS.ALLERGIES_TXT}</h3></div>
        </Row>
        <Row className="cart-row">
          <div className='width100'>
            <textarea
              name='allergies'
              defaultValue={allergies}
              onChange={(e) => setAllergies(e.target.value)}
              className="width100 cm-allergies-box"
              rows={5}
              placeholder={CONSTANTS.ALLERGY_PLACEHOLDER_TXT}
            ></textarea>
          </div>
        </Row>
        <Row className="cart-row">
          <Col className='col-6'>
            <button
              className="modal-cancel-button"
              onClick={() => cancelBtn()
              }
            >
              {cancelBtnLabel}
            </button>
          </Col>
          <Col className='col-6'>
            <button className="modal-button" onClick={saveCart}>
              {submitBtnLabel}
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>

  );
}
