import { createUserWithEmailAndPassword, signInWithEmailAndPassword,signOut, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { auth } from "./Config";

export const createAccount = (email, password) => createUserWithEmailAndPassword(auth, email, password)


export const loginAccount = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
}

export const logOutUser = () => {
    return signOut(auth)
}

export const forget_password = (email) => {
    return sendPasswordResetEmail(auth, email, { url: 'https://consumer-web-cd77.web.app/' })
}

export const sendCustomerEmailVerification = () => {
    return sendEmailVerification(auth.currentUser)
}