import { Route, Redirect } from "react-router-dom"
import { AppContext } from "../Context/AppContext"
import { useContext } from "react"

export const PaymentProtected = ({ component: Component, ...rest }) => {
    const { currentLoginCustomer } = useContext(AppContext)
    return (
        <Route {...rest} render={
            props => {
                if (currentLoginCustomer === null) {
                    return (
                        <Redirect to="/billing" />
                    )
                } else {
                    return <Component {...rest}{...props} />
                }
            }
        }
        />
    )
}

export const OrdersProtected = ({ component: Component, ...rest }) => {
    const { currentLoginCustomer } = useContext(AppContext)
    return (
        <Route {...rest} render={
            props => {
                if (currentLoginCustomer === null) {
                    return (
                        <Redirect to="/" />
                    )
                } else {
                    return <Component {...rest}{...props} />
                }
            }
        }
        />
    )
}