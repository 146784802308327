import Form from "react-bootstrap/Form";
import moment from "moment";
import calendarFilledImg from "../../../Assets/Images/ic_fh_calendar.svg";
import locationFilledImg from "../../../Assets/Images/ic_fh_location.svg";
import peoplesFilledImg from "../../../Assets/Images/ic_fh_people.svg";
import timeIcon from "../../../Assets/Images/ic_fh_time.svg";

import { Row, Col } from "react-bootstrap";
import { CONSTANTS } from "../../../Helpers/Constants";

export default function FilterSelections({
  cart_cateringMode,
  cart_location,
  eventDate,
  cart_guests,
  eventTime,
  vendor,
  supplimentsAdded,
  setSuppliments,
  supplimentsAvailable,
  mode,
  setSuplimentDialog,
  orderDetail,
}) {

  return (
    <>
      <div
        style={{
          width: '85%',
          margin: 'auto',
          fontFamily: 'Raleway',
        }}
      >
        <div
          style={{
            width: '95%',
            margin: 'auto',
          }}
        >
          <Row>
            <Col className={`${supplimentsAvailable ? 'cart-res-options' : 'cart-options'} `}>
              <div className="cart-subOptions">
                <img
                  src={calendarFilledImg}
                  alt=""
                  width="16px"
                  height="16px"
                ></img>
                <span
                  style={{
                    fontSize: "14px",
                  }}
                > {eventDate}</span>
              </div>
              <div className="cart-subOptions">
                <img
                  src={timeIcon}
                  alt=""
                  width="16px"
                  height="16px"
                ></img>
                <span
                  style={{
                    fontSize: "14px",
                  }}
                > {eventTime}</span>
              </div>

              <div className="cart-subOptions">
                <img
                  src={locationFilledImg}
                  alt=""
                  width="16px"
                  height="16px"
                ></img>
                <span
                  style={{
                    fontSize: "14px",
                  }}
                > {cart_location}</span>
              </div>
              <div className="cart-subOptions">
                <img
                  src={peoplesFilledImg}
                  alt=""
                  width="16px"
                  height="16px"
                ></img>
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >{!orderDetail && `${CONSTANTS.UPTO_TXT}`}</span><span> {cart_guests && `${cart_guests}`}</span>
              </div>

              <div className="cart-subOptions">
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >{cart_cateringMode.toLowerCase() === 'pickup' ? CONSTANTS.PICKUP_TXT : CONSTANTS.DELIVERY_TXT}</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <hr className="separator" />
    </>
  );
}
