import React, { useEffect, useContext, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import ErrorDisplay from "../../Components/VendorError";
import { Row, Col } from "react-bootstrap";
import "../../Assets/Styles/cart-detail.css";
import CartErrorModal from '../../Components/CartErrorModal'
import CartModal from "../../Components/CartModal";
import { CONSTANTS } from "../../Helpers/Constants";
import CartDetailsComponent from "../../Components/CartDetails";
import { AppContext } from "../../Context/AppContext";

export default function CartDetails({
  emptyCart,
  emptyCartConfirmationMsg,
  removeSupplimentConfirmationMsg,
  openConfirmationDialog,
  setConfirmationDialog,
  error,
  showSuplimentDialog,
  setSuplimentDialog,
  setSupplimentsAvailable,
  setSuppliments,
  showCartModal,
  selectedPackage,
  supplimentsAdded
}) {
  const {
    cartObj,
    setRemoveItemConfirmation,
    removeItemConfirmation,
    setCartObj,
    setShowCartModal
  } = useContext(AppContext);
  const [cartError, setError] = useState(null);

  const history = useHistory();
  const vendor = localStorage.getItem("selected_vendor")
    ? JSON.parse(localStorage.getItem("selected_vendor"))
    : "";

  //This effect examines if Vendor has allowed himself to accept supplements
  useEffect(() => {
    vendor?.isSupplementEnabled &&
      setSupplimentsAvailable(vendor?.isSupplementEnabled);
  }, []);

  const checkout = () => {
    //check if total quantity exceeds vendor's max accepted guests quantity
    const total_quantity = cartObj?.reduce((total, cartItem) => {
      return total + parseInt(cartItem.quantity);
    }, 0);
    if (parseInt(vendor?.maximumOrderCapacity) < parseInt(total_quantity)) {
      setError({ title: CONSTANTS.EXCEED_QUANTITY_TXT, body: CONSTANTS.QUANTITY_EXCEEDS_ERROR });
      return;
    } else {
      setError(null);
      history.push("/billing");
    }
  };

  const removeItem = () => {
    if (cartObj.length === 1) {
      localStorage.removeItem('cart')
      localStorage.removeItem('cart_date')
      localStorage.removeItem('cart_supplement')
      localStorage.removeItem('cart_guests')
      localStorage.removeItem('cart_location')
      localStorage.removeItem('cart_cateringMode')
      localStorage.removeItem('selected_vendor')
      setCartObj([])
      setRemoveItemConfirmation(false)
    }
    else {
      const objIndex = cartObj?.findIndex(elem => elem.packageId === selectedPackage.packageId)

      const updatedCart = [
        ...cartObj.slice(0, objIndex),
        ...cartObj.slice(objIndex + 1),
      ];

      localStorage.setItem('cart', JSON.stringify(updatedCart))
      localStorage.setItem('selected_vendor', JSON.stringify(vendor))
      setCartObj(updatedCart)
      setRemoveItemConfirmation(false)
    }
    toast.success(CONSTANTS.ITEM_REMOVED_MSG, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    setShowCartModal(false)
  }
  return (
    <>
      {cartError && <CartErrorModal error={cartError} callback={() => setError(null)} />}
      <div>
        {showCartModal && (
          <CartModal setRemoveItemConfirmation={setRemoveItemConfirmation} packageObj={selectedPackage} vendor={vendor}></CartModal>
        )}

        {removeItemConfirmation &&
          <ConfirmationDialog
            openConfirmationDialog={removeItemConfirmation}
            setConfirmationDialog={setRemoveItemConfirmation}
            callBack={removeItem}
            confirmationMsg={CONSTANTS.DO_YOU_REALLY_WANT_TO_REMOVE_ITEM}
            cancelFunction={() => {  setRemoveItemConfirmation(false); setShowCartModal(true);}}
          ></ConfirmationDialog>
        }

        {openConfirmationDialog && (
          <ConfirmationDialog
            openConfirmationDialog={openConfirmationDialog}
            setConfirmationDialog={setConfirmationDialog}
            callBack={emptyCart}
            confirmationMsg={emptyCartConfirmationMsg}
          ></ConfirmationDialog>
        )}
        <ConfirmationDialog
          openConfirmationDialog={showSuplimentDialog}
          setConfirmationDialog={setSuplimentDialog}
          callBack={() => { console.log('supplimentsAdded ', supplimentsAdded), localStorage.setItem('cart_supplement', !supplimentsAdded), setSuplimentDialog(false); setSuppliments(!supplimentsAdded) }}
          confirmationMsg={removeSupplimentConfirmationMsg}
          failureCallback={() => { setSuplimentDialog(false); setSuppliments(supplimentsAdded) }}
        ></ConfirmationDialog>

        <Header />
        {Object.keys(error)?.length > 0 ? (
          <div className="emptyCartContainer">
            <ErrorDisplay error={error} showRedirect={true} />
          </div>
        ) : (
          <>
            <CartDetailsComponent mode={"editable"} printActive={true}/>
            <div className="vendor-main-package cart-detail-btn-container">
              <Row>
                <Col
                  className="offset-md-3 offset-1 col-5"
                  xs={5}
                  sm={5}
                  md={3}
                  lg={3}
                >
                  <button
                    className="cart-detail-btn empty-cart-btn"
                    onClick={() => setConfirmationDialog(true)}
                  >
                    {CONSTANTS.EMPTY_CART_TXT}
                  </button>
                </Col>
                <Col className="col-5" xs={5} sm={5} md={3} lg={3}>
                  <button
                    className="cart-detail-btn check-out-cart-btn"
                    onClick={() => checkout()}
                  >
                    {CONSTANTS.CHECK_OUT_TXT}
                  </button>
                </Col>
              </Row>
            </div>
          </>
        )}
        <Footer />
      </div>
    </>
  );
}
