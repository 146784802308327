import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../Context/AppContext';
import { AsCurrency } from '../../../Helpers/Handlers';
import FilterSelections from './FilterSelections';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import 'moment/locale/sv'
import bullets from '../../../Assets/Images/bullet_points.svg';
import InstaLogo from '../../../Assets/Images/insta-logo-header.svg';
import PeopleIcon from "../../../Assets/Images/filter_people.svg";
import itemWithSupplementImg from '../../../Assets/Images/item-with-supplement.svg';
import EmptCartImg from "../../../Assets/Images/empty_cart.svg";
import emptyVaseImg from "../../../Assets/Images/empty-vase.svg";
import Rectangle from "../../../Assets/Images/Rectangle.svg";
import ErrorDisplay from '../../../Components/VendorError/index';
import { CONSTANTS } from '../../../Helpers/Constants';

export default function CartDetailsComponentNew({
    mode, // [readonly, editable] 
    cartProp,
    orderDetail,
    printCart
}) {
    const { cartObj, setCartObj, showCartModal, setShowCartModal, setError, error, setSelectedPackage, showSuplimentDialog, setSuplimentDialog, supplimentsAdded, setSuppliments } = useContext(AppContext);
    const cart = cartProp ?? cartObj
    let cartItemsMapping = orderDetail ? orderDetail.customer_order_packages : cart
    //Below property examines if vendor has allowed supplements for these packages
    const [supplimentsAvailable, setSupplimentsAvailable] = useState(false);
    const [showError, setShowError] = useState(false);
    const vendor = localStorage.getItem("selected_vendor")
        ? JSON.parse(localStorage.getItem("selected_vendor"))
        : "";
    const configs = localStorage.getItem("configs")
        ? JSON.parse(localStorage.getItem("configs"))
        : "";
    const cart_location = localStorage.getItem("cart_location") || "";
    const cart_cateringMode = localStorage.getItem("cart_cateringMode") || "";
    const cart_date = localStorage.getItem("cart_date") || "";
    const cart_guests = localStorage.getItem("cart_guests") || "";
    const eventDate = cart_date && moment(cart_date).locale("sv").format("YYYY MMMM DD");
    const eventTime = cart_date && moment(cart_date).format("HH.mm");
    const currentDate = moment(new Date()).locale("se").format("YYYY MMMM DD HH.mm");
    const sub_total = cart.reduce((total, cartItem) => {
        const totalPrice = cartItem.pricePerUnitWithFeeAmount * parseInt(cartItem.quantity);
        return total + totalPrice;
    }, 0);
    const suppliment_charges = !supplimentsAdded ? 0 :
        Math.round(sub_total * (vendor.supplementMarkUp / 100) * 100) / 100;
    const moms_charges =
        Math.round(
            (supplimentsAdded ? (parseFloat(suppliment_charges) + parseFloat(sub_total)) : parseFloat(sub_total)) *
            (parseInt(configs.moms) / 100) *
            100
        ) / 100;
    const grandTotal =
        Math.round((sub_total + suppliment_charges + moms_charges) *
            100
        ) / 100;

    const editCartEventHandler = (item) => {
        setSelectedPackage(item);
        setShowCartModal(true);
    };

    useEffect(() => {
        if (cart?.length === 0) {
            setError({
                imageUrl: EmptCartImg,
                title: CONSTANTS.LOOKS_TXT,
                text: CONSTANTS.EMPTY_CART_TXT,
                additionalText: CONSTANTS.CHECK_MSG,
            });
        }
    }, [cart]);

    //This effect examines if Vendor has allowed himself to accept supplements
    useEffect(() => {
        vendor?.isSupplementEnabled &&
            setSupplimentsAvailable(vendor?.isSupplementEnabled);
    }, []);

    useEffect(() => {
        if (orderDetail) {
            setShowError(false)
            setError({})
        }
        if (!vendor && orderDetail === undefined) {
            setError({
                imageUrl: emptyVaseImg,
                title: CONSTANTS.DATE_LOCATION_MISSING_ERROR,
                text: CONSTANTS.EVENT_DETAILS_MISSING_TXT,
            });
            setShowError(true)
        }
    }, [showError]);

    const group = (items, n) => items.reduce((acc, curr, i) => {
        const idx = Math.floor(i / n);
        acc[idx] = [...(acc[idx] || []), curr];
        return acc;
    }, []);

    return (
        <>
            <table
                style={{
                    pageBreakAfter: "always",
                    width: "100%",
                }}
            >
                <thead
                    style={{
                        display: "table-header-group",
                    }}
                >
                    <tr>
                        <th>
                            <div>
                                <div style={{
                                    height: '80px',
                                    width: '100%',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#fff',
                                    zIndex: '1000000',
                                }} >
                                    <img style={{
                                        padding: '8px 0',
                                        height: '80%',
                                    }}
                                        src={InstaLogo} alt="" />
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tfoot
                    style={{
                        display: "table-footer-group",
                    }}
                >

                    <div
                        style={{
                            height: "150px",
                            pageBreakBefore: "always",
                            marginTop: "15px",
                        }}
                    >&nbsp;</div>

                </tfoot>
                <div>
                    {(!vendor && orderDetail === undefined) ? <ErrorDisplay error={error} /> : <>
                        <div
                            style={{
                                width: '100%',
                                marginTop: '0',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                overflow: 'hidden',
                                zIndex: '10000',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    height: '20rem',
                                    display: 'block',
                                    overflow: 'hidden',
                                }}
                            >
                                <img src={orderDetail ? orderDetail.vendor.logoImageUrl : vendor?.logoImageUrl} alt="" className="img-blur" aria-hidden="true" />
                                <img
                                    src={orderDetail ? orderDetail.vendor.logoImageUrl : vendor?.logoImageUrl}
                                    alt={"logoImage"}
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        zIndex: "0",
                                        bottom: "0px",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                    }}
                                />
                                <img src={Rectangle}
                                    style={{
                                        marginTop: '10rem',
                                        position: 'absolute',
                                        width: '100%',
                                        zIndex: '999',
                                        bottom: '0px',
                                        opacity: '0.5',
                                    }}
                                />
                            </div>
                            <div>
                                <div>
                                    <div style={{
                                        position: 'relative',
                                        width: '55rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        fontFamily: 'Raleway',
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        textTransform: 'uppercase',
                                        zIndex: '999',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        marginTop: '1rem',
                                    }}>{orderDetail ? orderDetail.vendor.name : vendor.name}</div>
                                    <div>
                                        <p
                                            style={{
                                                textAlign: 'left',
                                                overFlow: 'hidden',
                                                padding: '8px 3rem 8px 3rem',
                                                color: "#000000",
                                                fontFamily: 'Raleway',
                                                lineHeight: '1.3',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                            }}
                                        >{orderDetail ? orderDetail?.vendor?.description : vendor?.description}</p>
                                        <h3
                                            style={{
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                padding: "0px 0 20px 0",
                                                fontFamily: "Raleway",
                                            }}
                                        >{CONSTANTS.DRAFT_ORDER_PRINTED_ON} {currentDate}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FilterSelections
                            cart_cateringMode={orderDetail ? orderDetail.serviceMode : cart_cateringMode}
                            cart_location={orderDetail ? orderDetail?.vendor?.vendorArea?.toUpperCase() : cart_location}
                            eventDate={orderDetail ? moment(new Date(orderDetail.serviceDate._seconds * 1000)).locale("sv").format("YYYY MMMM DD") : eventDate}
                            cart_guests={orderDetail ? orderDetail.totalPeople : cart_guests}
                            eventTime={orderDetail ? moment(new Date(orderDetail.serviceDate._seconds * 1000)).format("HH.mm") : eventTime}
                            vendor={orderDetail ? orderDetail.vendorName : vendor}
                            supplimentsAdded={supplimentsAdded}
                            setSuppliments={setSuppliments}
                            supplimentsAvailable={supplimentsAvailable}
                            mode={mode}
                            setSuplimentDialog={setSuplimentDialog}
                            orderDetail={orderDetail}
                        />
                        <div
                            style={{
                                width: '75%',
                                margin: 'auto',
                                fontFamily: 'Raleway',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        marginTop: '15px',
                                        display: 'flex',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '60%',
                                            fontSize: "14px",
                                            fontWeight: "600",

                                        }}
                                    >
                                        {CONSTANTS.PACKAGES_TXT}
                                    </div>
                                    <div
                                        style={{
                                            width: '40%',
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "33%",
                                                position: 'relative',
                                                textAlign: 'center',
                                                fontWeight: "600",

                                            }}
                                        >
                                            {CONSTANTS.PRICE_TXT}
                                        </div>

                                        <div
                                            style={{
                                                width: "33%",
                                                position: 'relative',
                                                textAlign: 'center',
                                                fontWeight: "600",

                                            }}
                                        >
                                            {CONSTANTS.QUANTITY_SHORT}
                                        </div>
                                        <div
                                            style={{
                                                width: "34%",
                                                position: 'relative',
                                                textAlign: 'center',
                                                fontWeight: "600",
                                            }}
                                        >
                                            {CONSTANTS.CART_LINE_TOTAL}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        {cartItemsMapping?.map((cart_item) => {
                                            return (
                                                <div
                                                    style={{
                                                        marginTop: '20px',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position: 'relative',
                                                            pageBreakInside: "avoid",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                pageBreakInside: 'avoid',
                                                                position: 'relative',
                                                                marginTop: "12px"
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    pageBreakInside: "avoid",
                                                                    height: '60px',
                                                                    display: 'flex',
                                                                    justifyContent: "space-between",
                                                                    position: 'relative',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        width: '60%',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            minWidth: '50px',
                                                                            width: '50px',
                                                                            height: '50px',
                                                                            marginRight: '1rem',
                                                                            pageBreakInside: "avoid",
                                                                            position: 'relative',
                                                                        }}
                                                                    >
                                                                        <img
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '0px',
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit: 'cover',
                                                                            }}
                                                                            src={cart_item.imageUrl} />
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            pageBreakInside: 'avoid',
                                                                        }}
                                                                        className={`item-description ${mode !== 'readonly' && 'editableMode'}`}
                                                                    >
                                                                        {orderDetail ? cart_item.title : cart_item.title}{" "}
                                                                        {orderDetail ? cart_item.allergyComments?.length > 0 : cart_item.allergies?.length > 0 && (
                                                                            <img
                                                                                src={itemWithSupplementImg}
                                                                                alt=""
                                                                                width="13px"
                                                                                height="13px"
                                                                                style={{ marginBottom: "0.5rem" }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    pageBreakInside: 'avoid',
                                                                    width: '40%',
                                                                    height: '100px',
                                                                    display: 'flex',
                                                                    alignItems: 'flex-start',
                                                                    position: 'relative',
                                                                }}>
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            pageBreakInside: "avoid",
                                                                            display: 'flex',
                                                                            alignItems: 'flex-start',
                                                                            position: 'relative',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                height: "100%",
                                                                                width: '33%',
                                                                                position: 'relative',
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            {orderDetail ? cart_item.pricePerUnitWithFeeAmount : cart_item.pricePerUnitWithFeeAmount} {CONSTANTS.KRONE_TXT}
                                                                            <p
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    fontSize: '7px',
                                                                                    color: '#787885',
                                                                                    left: '20px',
                                                                                }}
                                                                            >
                                                                                {CONSTANTS.EXKL_MOMS_TXT}
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                width: '33%',
                                                                                height: "100%",
                                                                                position: 'relative',
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >{orderDetail ? cart_item.orderedQuantity : cart_item.quantity}</div>
                                                                        <div
                                                                            style={{
                                                                                width: '34%',
                                                                                position: 'relative',
                                                                                height: "100%",
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >{orderDetail ? cart_item.orderedQuantity * cart_item.pricePerUnitWithFeeAmount : cart_item.quantity * cart_item.pricePerUnitWithFeeAmount} {CONSTANTS.KRONE_TXT}
                                                                            <p
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    fontSize: '7px',
                                                                                    color: '#787885',
                                                                                    right: '20px',
                                                                                }}
                                                                            >{CONSTANTS.EXKL_MOMS_TXT}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    marginBottom: '5px',
                                                                    pageBreakInside: 'avoid',
                                                                }}
                                                            > {group(cart_item.packageItems.slice(0, 2), 2).map(children => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            pageBreakInside: 'avoid',
                                                                            height: "fit-content",
                                                                        }}
                                                                    >
                                                                        {children.map((x, i) => {
                                                                            return (
                                                                                <div
                                                                                    style={{
                                                                                        width: '60%',
                                                                                        margin: '5px 0px 0px 0px',
                                                                                        pageBreakInside: 'avoid',
                                                                                    }}
                                                                                >
                                                                                    <img src={bullets} />
                                                                                    <span key={i}
                                                                                        style={{
                                                                                            fontSize: '12px',
                                                                                            lineHeight: '1.3',
                                                                                        }}
                                                                                    > {x.title} </span>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>)
                                                            })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            // marginTop: '5px',
                                                        }}
                                                    >

                                                        {group(cart_item.packageItems.slice(2), 3).map((children, index) => {
                                                            return (
                                                                <div
                                                                    style={{
                                                                        width: '60%',
                                                                        pageBreakInside: 'avoid',
                                                                        margin: index === 0 ? '0px' : "5px 0px",
                                                                    }}
                                                                >
                                                                    {children.map((x, i) => {
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    margin: i === 0 ? '0px 0px' : "5px 0px",
                                                                                    pageBreakInside: 'avoid',
                                                                                }}
                                                                            >
                                                                                <img src={bullets} />
                                                                                <span key={i}
                                                                                    style={{
                                                                                        fontSize: '12px',
                                                                                        lineHeight: '1.3',
                                                                                    }}
                                                                                > {x.title} </span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>)
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="separator" />
                        <div
                            style={{
                                breakInside: 'avoid',
                                width: '70%',
                                margin: '20px auto',
                                fontFamily: 'Raleway',
                            }}
                        >
                            <div className="vendor-main-package">
                                <Row>
                                    <Col>
                                        <p
                                            style={{
                                                fontFamily: 'Raleway',
                                                fontWeight: 700,
                                            }}
                                        > {CONSTANTS.SUB_TOTAL_TXT}</p>
                                    </Col>
                                    <Col></Col>
                                    <Col className="cart-detail-value">{orderDetail ? AsCurrency(orderDetail.packageTotalAmountWithFee) : AsCurrency(sub_total)} {CONSTANTS.KRONE_TXT}</Col>
                                </Row>
                                {supplimentsAdded && (
                                    <Row>
                                        <Col>
                                            <p
                                                style={{
                                                    fontFamily: 'Raleway',
                                                    fontWeight: 700,
                                                }}
                                            >{CONSTANTS.SUPPLEMENTS_TXT}</p>
                                        </Col>
                                        <Col></Col>
                                        <Col className="cart-detail-value">{orderDetail ? AsCurrency(orderDetail.supplementAmountWithFee) : AsCurrency(suppliment_charges)} {CONSTANTS.KRONE_TXT}</Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col>
                                        <p
                                            style={{
                                                fontFamily: 'Raleway',
                                                fontWeight: 700,
                                            }}
                                        >{CONSTANTS.MOMS_TXT} ({configs.moms}%)</p>
                                    </Col>
                                    <Col></Col>
                                    <Col className="cart-detail-value">{orderDetail ? AsCurrency(orderDetail.momsAmount) : AsCurrency(moms_charges)} {CONSTANTS.KRONE_TXT}</Col>
                                </Row>
                            </div>
                        </div>
                        <hr className="separator" />
                        <div
                            style={{
                                breakBefore: 'avoid',
                                width: '70%',
                                margin: 'auto',
                                fontFamily: 'Raleway',
                            }}
                        >
                            <div className="vendor-main-package">
                                <Row>
                                    <Col>
                                        <p
                                            style={{
                                                fontFamily: 'Raleway',
                                                fontWeight: 700,
                                            }}
                                            className="cart-detail-headings">{CONSTANTS.TOTAL_TXT}</p>
                                    </Col>
                                    <Col></Col>
                                    <Col className="cart-detail-value">{orderDetail ? AsCurrency(orderDetail.totalPayableAmount) : AsCurrency(grandTotal)} {CONSTANTS.KRONE_TXT}</Col>
                                </Row>
                            </div>
                        </div>
                        <hr className="separator" />
                    </>}
                </div>
            </table>
        </>
    )
}