import { findAllByDisplayValue } from '@testing-library/dom';
import React from 'react';

export default function TextBox({ id, name, placeholder, type, label, value, onChange, class_name, on_blur, error, errorMessage, readOnly, disabled=false}) {
    return(
        <div className="billing-info-input">
            <label className='w-100'>{label}</label>
            <input 
                id={id}
                name={name}
                placeholder={placeholder}
                type={type}
                className={`billing-input-text ${class_name} ${ error && !disabled ? 'error-field':''}`}
                onBlur={on_blur}
                value={value}
                onChange={onChange}
                readOnly={readOnly}
                disabled={disabled}                
            />
            {error && !disabled? <div className='error-message'>{errorMessage}</div> : ''}
        </div>
    )
}