import React from 'react';

import footerImg from '../../../Assets/Images/footer.svg';

import "../../../Assets/Styles/printCart.css"
import CartToPrint from './CartToPrintNew';


export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <>
      <div
        className='printDraftOrder'
        style={{ position: 'relative', height: '100vh' }} ref={ref}>
        <CartToPrint />
        <div
          style={{
            height: "150px",
            pageBreakBefore: "always",
            display: "table-footer-group",
            position: "fixed",
            bottom: "8px",
          }}
        >
          <img src={footerImg} />
        </div>
      </div>
    </>
  );
});
