import React from "react";
import { CONSTANTS } from "../../../Helpers/Constants";

export default function TermsAndConditionsScreens() {
    return (
        <div className="terms-and-conditions">
            <div className="tac-content">
                <h4>1. Allmänt om våra tjänster</h4>
                <p>Instacate&nbsp;AB ("Instacate") tillhandahåller en digital plattform/marknadsplats för kunder som vill beställa mat till möten. Instacate erbjuder dig som kund erbjudanden från utvalda leverantörer av mat och/eller dryck ("cateringleverantör"). I samband med kundens beställning sker betalning via Instacate och beställningen förmedlas vidare till cateringleverantören.</p>

                <h4>2. Personuppgifter</h4>
                <p>Instacate behandlar personuppgifter med syfte att kunna tillhandahålla, administrera samt leverera produkter och tjänster som beställts. Detta sker enligt dataskyddsförordningen (GDPR) och vi skyddar information från obehöriga. Genom att nyttja Instacates tjänster och/eller inneha konto på Instacate ges samtycke till att vi samlar in och sammanställer relevant statistik för exempelvis marknadsföring. Personuppgifterna omfattas av vår integritetspolicy.</p>

                <h4>3. Konton</h4>
                <p>Att skapa ett konto på Instacate är kostnadsfritt och gäller löpande. Med skapat konto fylls kontaktuppgifter i automatiskt vid en ny beställning och tidigare beställningar sparas i orderhistoriken. Genom att skapa konto accepteras villkoren i detta avtal. Vid registrering anger kunden e-post som användarnamn samt skapar ett lösenord. Kunden ansvarar för att lösenord förvaras på ett säkert sätt. Kontohavare ska omgående kontakta Instacate via <a href="mailto:info@instacate.se">info@instacate.se</a>  om det finns anledning att misstänka att lösenordet och/eller användarnamnet har avslöjats eller på annat sätt missbrukats. Instacate lämnar aldrig ut lösenord vid förfrågan och saknar möjlighet att skapa nya lösenord. Instacate använder endast kontouppgifterna med syfte att tillse god service och för att sända information om Instacate samt dess erbjudanden. Kontouppgifterna omfattas av Instacates integritetspolicy.</p>

                <h4>4. Beställning och betalning</h4>
                <p>Genom att lägga en beställning via Instacate accepteras villkoren i detta avtal. Kunden intygar att vederbörande är minst 18 år samt har rätt att genomföra beställningar för det företags räkning som vederbörande beställer åt/till. Vid genomförd beställning sker betalning till Instacate och kunden är då bunden till ordern. Instacate kan komma att genomföra kreditkontroll av kunden. Vid frågor om beställningen efter lagd order vänder sig kunden till <a href="mailto:info@instacate.se.">info@instacate.se.</a></p>
                <p>Instacates leverantör för betalning via betal- och/eller kreditkort är Stripe (publ). Betalning sker via Stripes säkra betallösning med SSL-certifikat som uppfyller kraven för PCI DSS.</p>
                <p>Om kunds order avböjs eller avbryts av skäl som inte kunden rår över återbetalar Instacate ordersumman till kunden, om inte annat överenskommits mellan Instacate och kunden. Instacate kommunicerar snarast möjligt direkt med kunden om en lagd order inte skulle accepteras eller genomföras enligt kundens ursprungliga beställning.</p>
                <p>Cateringleverantörer kan komma att använda tredje part för leverans och andra tjänster relaterade till kunds beställning via Instacate.</p>

                <h4>5. Ansvar</h4>
                <p>Cateringleverantören ansvarar för maten och leveransen fram till kund. Utöver vad som anges i villkoren lämnar Instacate inga garantier beträffande innehåll, information, råvaror, ingredienser eller tjänster som tillhandahålls av Instacates cateringleverantörer.</p>
                <p>Instacate är inte skadeståndsansvarig för några indirekta skador som kan uppkomma som genom nyttjande av Instacates tjänster. Kunden är skyldig att ersätta Instacate för all direkt och indirekt skada som kunden kan ha åsamkat Instacate som ett resultat av brott mot villkoren.</p>
                <p>Kunden ansvarar för att säkerställa att beställningen kan levereras enligt de uppgifter om exempelvis leveransadress och kundens kontaktperson som anges i ordern. Kunden ansvarar för att emotta och kontrollera beställningen. Vid mottagande av leveransen övergår ansvaret till kunden.</p>

                <h4>6. Ändringar</h4>
                <p>Eventuella ändringar och/eller tillägg till beställningar bör göras så snart som möjligt till <a href="mailto:info@instacate.se.">info@instacate.se.</a> Instacate försöker alltid ombesörja önskemål men kan inte lämna garantier om att ändringar och/eller tillägg är möjliga att tillgodose.</p>

                <h4>7. Ångerrätt och reklamation</h4>
                <p>Kunden kan ångra en order fram till och med fem arbetsdagar innan leverans. Vid avbokad beställning återbetalar Instacate ordersumman till kunden med eventuellt avdrag för omkostnader, om inte annat överenskommits mellan Instacate och kunden.</p>
                <p>Instacate strävar efter maximal kundnöjdhet i alla lägen. Instacates cateringleverantörer ansvarar för beställningarnas innehåll samt leverans fram till kund. Reklamationer ska ske inom två arbetsdagar efter leverans och riktas till <a href="mailto:info@instacate.se">info@instacate.se</a> med information om ordernummer samt beskrivning av händelseförloppet. Vi bistår alltid så gott vi kan för att ge dig en så bra upplevelse som möjligt.</p>

                <h4>8. Övrigt</h4>
                <p>Instacate förbehåller sig rätten att justera villkoren i detta avtal.</p>
                <p>Instacate äger rätt att utan begränsning överlåta sina rättigheter och skyldigheter enligt dessa villkor till tredje part. Kunden äger inte rätt att utan samtycke från Instacate överlåta sina rättigheter och skyldigheter enligt dessa villkor.</p>
                <p>Innehållet under domänen instacate.se, dess webbsidor samt kanaler för sociala medier är upphovsrättsligt skyddat. Reproduktion, överföring, distribution eller lagring av del av eller hela innehållet i någon form utan skriftligt medgivande från Instacate är förbjudet. Pressmeddelanden samt andra dokument som av Instacate är klassificerade som offentliga får användas förutsatt att källan för informationen nämns.</p>
                <p>Eventuell tvist ska i första hand lösas genom överenskommelse mellan parterna. Om tvisten inte kan lösas mellan parterna ska tvisten avgöras i enlighet med svensk rätt av allmän domstol i Sverige med Stockholms tingsrätt som första instans.</p>
            </div>
        </div>
    )
}