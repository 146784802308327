import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../Context/AppContext';
import { AsCurrency } from "../../Helpers/Handlers";
import FilterSelections from './filterSelections';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import 'moment/locale/sv'
import { useReactToPrint } from 'react-to-print';
import SeeFullMenu from "../../Assets/Images/full_menu.svg";
import PeopleIcon from "../../Assets/Images/people.svg";
import calendarFilledImg from "../../Assets/Images/ic_fh_calendar.svg";
import itemWithSupplementImg from '../../Assets/Images/item-with-supplement.svg';
import EmptCartImg from "../../Assets/Images/empty_cart.svg";
import PriceIcon from "../../Assets/Images/price.svg";
import emptyVaseImg from "../../Assets/Images/empty-vase.svg";
import Rectangle from "../../Assets/Images/Rectangle.svg";
import { CONSTANTS } from "../../Helpers/Constants";
import editIcon from "../../Assets/Images/edit_icon.svg"
import ErrorDisplay from '../VendorError'
import { ComponentToPrint } from '../../Container/DraftOrder/Views/ComponentToPrint';

export default function CartDetailsComponent({
    mode, // [readonly, editable] 
    cartProp,
    orderDetail,
    printActive,
}) {
    const { cartObj, setCartObj, showCartModal, setShowCartModal, setError, error, setSelectedPackage, showSuplimentDialog, setSuplimentDialog, supplimentsAdded, setSuppliments } = useContext(AppContext);
    const cart = cartProp ?? cartObj
    let cartItemsMapping = orderDetail ? orderDetail.customer_order_packages : cart
    //Below property examines if vendor has allowed supplements for these packages
    const [supplimentsAvailable, setSupplimentsAvailable] = useState(false);
    const [showError, setShowError] = useState(false);
    const vendor = localStorage.getItem("selected_vendor")
        ? JSON.parse(localStorage.getItem("selected_vendor"))
        : "";
    const configs = localStorage.getItem("configs")
        ? JSON.parse(localStorage.getItem("configs"))
        : "";
    const cart_location = localStorage.getItem("cart_location") || "";
    const cart_cateringMode = localStorage.getItem("cart_cateringMode") || "";
    const cart_date = localStorage.getItem("cart_date") || "";
    const cart_guests = localStorage.getItem("cart_guests") || "";
    const eventDate = cart_date && moment(cart_date).locale('sv').format("YYYY MMMM DD");
    const eventTime = cart_date && moment(cart_date).format("HH.mm");
    const sub_total = cart.reduce((total, cartItem) => {
        const totalPrice = cartItem.pricePerUnitWithFeeAmount * parseInt(cartItem.quantity);
        return total + totalPrice;
    }, 0);
    const suppliment_charges = !supplimentsAdded ? 0 :
        Math.round(sub_total * (vendor.supplementMarkUp / 100) * 100) / 100;
    const moms_charges =
        Math.round(
            (supplimentsAdded ? (parseFloat(suppliment_charges) + parseFloat(sub_total)) : parseFloat(sub_total)) *
            (parseInt(configs.moms) / 100) *
            100
        ) / 100;
    const grandTotal =
        Math.round((sub_total + suppliment_charges + moms_charges) *
            100
        ) / 100;

    const editCartEventHandler = (item) => {
        setSelectedPackage(item);
        setShowCartModal(true);
    };

    useEffect(() => {
        if (cart?.length === 0) {
            setError({
                imageUrl: EmptCartImg,
                title: CONSTANTS.LOOKS_TXT,
                text: CONSTANTS.EMPTY_CART_TXT,
                additionalText: CONSTANTS.CHECK_MSG,
            });
        }
    }, [cart]);

    //This effect examines if Vendor has allowed himself to accept supplements
    useEffect(() => {
        vendor?.isSupplementEnabled &&
            setSupplimentsAvailable(vendor?.isSupplementEnabled);
    }, []);

    useEffect(() => {
        if (orderDetail) {
            setShowError(false)
            setError({})
        }
        if (!vendor && orderDetail === undefined) {
            setError({
                imageUrl: emptyVaseImg,
                title: CONSTANTS.DATE_LOCATION_MISSING_ERROR,
                text: CONSTANTS.EVENT_DETAILS_MISSING_TXT,
            });
            setShowError(true)
        }
    }, [showError]);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "draft_order",

    });



    return (
        <>
            <ComponentToPrint ref={componentRef} documentTitle= "draft_order"/>
            {(!vendor && orderDetail === undefined) ? <ErrorDisplay error={error} /> : <>
                <Row>
                    <Col>
                        <div className="vendor-main-package">
                            <div className="img-container">
                                <img src={orderDetail ? orderDetail.vendor.logoImageUrl : vendor?.logoImageUrl} alt="" className="img-blur" aria-hidden="true" />
                                <img src={orderDetail ? orderDetail.vendor.logoImageUrl : vendor?.logoImageUrl} alt="Placeholder image" className="img-true" />
                                <img src={Rectangle} className="svp-overlay" />
                            </div>
                            <div className="vendor-mp-overlay">
                                <img
                                    src={orderDetail ? orderDetail.vendor.logoImageUrl : vendor?.logoImageUrl}
                                    alt={"logoImageUrl"}
                                    className="vendor-mp-image"
                                />
                                <img src={Rectangle} className="svp-overlay" />
                            </div>
                            {
                                mode !== 'readonly' &&
                                <div className="mp-description">
                                    <div className="mp-label-people">
                                        <img src={PeopleIcon} />
                                        &nbsp;
                                        {`${CONSTANTS.UPTO_TXT} ${vendor?.maximumOrderCapacity}`}
                                    </div>
                                    <div
                                        className="mp-label-price"
                                    >
                                        {`${vendor?.priceMin} ${CONSTANTS.KRONE_TXT} - ${vendor?.priceMax} ${CONSTANTS.KRONE_TXT}`}
                                    </div>
                                </div>
                            }
                            <div className="vendor-description-cart-details">
                                <div className="vendor-mp-description">
                                    <div className="vendor-mp-card-barrier">{orderDetail ? orderDetail.vendor.name : vendor.name}</div>
                                    <div className="vendor-mp-details">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {console.log(orderDetail, eventDate)}
                <FilterSelections
                    cart_cateringMode={orderDetail ? orderDetail.serviceMode : cart_cateringMode}
                    cart_location={orderDetail ? orderDetail?.serviceArea.toUpperCase() : cart_location}
                    eventDate={orderDetail ? moment(new Date(orderDetail.serviceDate._seconds * 1000)).locale("sv").format("YYYY MMMM DD") : eventDate}
                    cart_guests={orderDetail ? orderDetail.totalPeople : cart_guests}
                    eventTime={orderDetail ? moment(new Date(orderDetail.serviceDate._seconds * 1000)).format("HH.mm") : eventTime}
                    vendor={orderDetail ? orderDetail.vendorName : vendor}
                    supplimentsAdded={supplimentsAdded}
                    setSuppliments={setSuppliments}
                    supplimentsAvailable={supplimentsAvailable}
                    mode={mode}
                    setSuplimentDialog={setSuplimentDialog}
                    orderDetail={orderDetail}
                    handlePrint={handlePrint}
                    printActive={printActive}
                />
                <div className="vendor-main-package-parent">
                    <div className="vendor-main-package">
                        <div className="vendor-package-headings">
                            <div className="item-description"><b>{CONSTANTS.PACKAGES_TXT}</b></div>
                            <div className="cart-amount-side">
                                <div className="item-amount"><b>{CONSTANTS.PRICE_TXT}</b></div>
                                <div className="item-quantity"><b>{CONSTANTS.QUANTITY_SHORT}</b></div>
                                <div className="item-quantity item-total"><b>{CONSTANTS.CART_LINE_TOTAL}</b></div>
                            </div>
                        </div>
                        {cartItemsMapping?.map((cart_item) => {
                            return (
                                <div onClick={() => { mode !== 'readonly' && editCartEventHandler(cart_item) }} className={`${mode !== 'readonly' && 'cursorActive'} cart-list`}>
                                    <div className={`item-description ${mode !== 'readonly' && 'editableMode'}`}>
                                        {orderDetail ? cart_item.title : cart_item.title}{" "}
                                        {orderDetail ? cart_item.allergyComments?.length > 0 : cart_item.allergies?.length > 0 && (
                                            <img
                                                src={itemWithSupplementImg}
                                                alt=""
                                                width="13px"
                                                height="13px"
                                                style={{ marginBottom: "0.5rem" }}
                                            />
                                        )}
                                    </div>
                                    <div className="cart-amount-side">
                                        <div className="item-amount">
                                            {orderDetail ? cart_item.pricePerUnitWithFeeAmount : cart_item.pricePerUnitWithFeeAmount} {CONSTANTS.KRONE_TXT}
                                            <p>{CONSTANTS.EXKL_MOMS_TXT}</p>
                                        </div>
                                        <div className="item-quantity">{orderDetail ? cart_item.orderedQuantity : cart_item.quantity}</div>
                                        <div className="item-quantity item-total">{orderDetail ? cart_item.orderedQuantity * cart_item.pricePerUnitWithFeeAmount : cart_item.quantity * cart_item.pricePerUnitWithFeeAmount} {CONSTANTS.KRONE_TXT}
                                            <p>{CONSTANTS.EXKL_MOMS_TXT}</p>
                                        </div>
                                    </div>

                                </div>

                            );
                        })}
                    </div>
                </div>
                <hr className="separator" />
                <div className="vendor-main-package-parent">
                    <div className="vendor-main-package">
                        <Row>
                            <Col>
                                <h3 className="cart-detail-headings"> {CONSTANTS.SUB_TOTAL_TXT}</h3>
                            </Col>
                            <Col></Col>
                            <Col className="cart-detail-value">{orderDetail ? AsCurrency(orderDetail.packageTotalAmountWithFee) : AsCurrency(sub_total)} {CONSTANTS.KRONE_TXT}</Col>
                        </Row>
                        {(orderDetail?.supplementAmountWithFee > 0 || supplimentsAdded) && (
                            <Row>
                                <Col>
                                    <h3 className="cart-detail-headings">{CONSTANTS.SUPPLEMENTS_TXT}</h3>
                                </Col>
                                <Col></Col>
                                <Col className="cart-detail-value">{orderDetail ? AsCurrency(orderDetail.supplementAmountWithFee) : AsCurrency(suppliment_charges)} {CONSTANTS.KRONE_TXT}</Col>
                            </Row>
                        )}
                        <Row>
                            <Col>
                                <h3 className="cart-detail-headings">{CONSTANTS.MOMS_TXT} ({configs.moms}%)</h3>
                            </Col>
                            <Col></Col>
                            <Col className="cart-detail-value">{orderDetail ? AsCurrency(orderDetail.momsAmount) : AsCurrency(moms_charges)} {CONSTANTS.KRONE_TXT}</Col>
                        </Row>
                    </div>
                </div>
                <hr className="separator" />
                <div className="vendor-main-package-parent">
                    <div className="vendor-main-package">
                        <Row>
                            <Col>
                                <h3 className="cart-detail-headings">{CONSTANTS.TOTAL_TXT}</h3>
                            </Col>
                            <Col></Col>
                            <Col className="cart-detail-value">{orderDetail ? AsCurrency(orderDetail.totalPayableAmount) : AsCurrency(grandTotal)} {CONSTANTS.KRONE_TXT}</Col>
                        </Row>
                    </div>
                </div>
                <hr className="separator" />
            </>}
        </>
    )
}