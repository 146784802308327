import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import AppProvider from './Context/AppContext';
import LandingPage from './Container/LandingPage';
import VendorsList from './Container/VendorsList';
import VendorPackages from './Container/VendorPackages/index';
import ScrollToTop from './Container/ScrollToTop';
import cartDetail from './Container/CartDetail';
import BillingPage from './Container/BillingPage';
import CollectPayment from './Container/PaymentScreen';
import OrderConfirmation from './Container/OrderConfirmation';
import TermsAndConditions from './Container/TermsAndConditions';
import PrivacyPolicy from './Container/PrivacyPolicy';
import FrequentQuestions from './Container/FrequentlyAskedQuestions';
import { OrdersProtected, PaymentProtected } from './Router';
import MyOrders from './Container/MyOrder';



export default function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            
            <AppProvider>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/vendors" component={VendorsList} />
              <Route exact path='/vendor/packages' component={VendorPackages} />
              <Route exact path="/cart/detail" component={cartDetail} />
              <Route exact path="/billing" component={BillingPage} />
              <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/frequently-asked-questions" component={FrequentQuestions} />
              <PaymentProtected exact path="/payment" component={CollectPayment}/>
              <OrdersProtected exact path="/my-orders" component={MyOrders} />
              <OrdersProtected exact path="/order-confirmation" component={OrderConfirmation} />
            </AppProvider>
          </Switch>
          <ToastContainer />
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}